import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCvBI1tw4UV7QlTHepBSLXtNNxrFnjVQr8',
  authDomain: 'perquity.firebaseapp.com',
  projectId: 'perquity',
  storageBucket: 'perquity.appspot.com',
  messagingSenderId: '992970610329',
  appId: '1:992970610329:web:6fd446a5db1a8ee9b28a08',
  measurementId: 'G-5WF6M35RB1'
}

// Get a Firestore instance
export const db = firebase.initializeApp(firebaseConfig).firestore()
export const auth = firebase.auth()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { TimeStamp, GeoPoint } = firebase.firestore;
// export { TimeStamp, GeoPoint };
export const Timestamp = firebase.firestore.Timestamp
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const arrayAdd = firebase.firestore.FieldValue
export const arrayRemove = firebase.firestore.FieldValue
