<template>
  <v-app id="app">
    <div v-if="this.loading">{{ this.loadingMessage }}</div>
    <trade-advice />
    <!-- <v-navigation-drawer
      class="ds-left-navigation"
      v-model="drawer"
      v-if="userAuth.uid"
      :mini-variant.sync="mini"
      permanent
      app
      clipped
    >
      <v-divider></v-divider>
      <v-btn
        class="ds-button"
        v-for="navItem in navItems"
        :key="navItem.title"
        x-large
        left
        block
        elevation="4"
        @click="navItem.onClick"
      >
        <v-icon class="ds-icon">{{ navItem.icon }}</v-icon>
        <p>{{ navItem.title }}</p>
      </v-btn>
    </v-navigation-drawer> -->
    <v-app-bar class="nav-bar" app clipped-left flat v-if="!auth.currentUser">
      <router-link v-if="!auth.currentUser" to="/login" exact
        ><v-toolbar-title class="ds-logo"
          >Perquity 
          <!-- <span v-if="appData.lastScrapedAt">({{ formatDateTime(lastScrapeDate) }})</span> -->
          </v-toolbar-title
        ></router-link
      >
      <router-link v-else to="/" exact
        ><v-toolbar-title class="ds-logo"
          >Perquity 
          <!-- <span v-if="appData.lastScrapedAt">({{ formatDateTime(lastScrapeDate) }})</span> -->
          </v-toolbar-title
        ></router-link
      >
      <div v-if="this.loading">{{ this.loadingMessage }}</div>
      <v-spacer></v-spacer>
      <div v-if="this.userAuth.uid == null">
        <v-btn
          v-for="link in links"
          :key="link.title"
          text
          rounded
          :to="link.route"
          >{{ link.title }}
        </v-btn>
      </div>
      <template v-if="this.userAuth.uid">
        <v-spacer></v-spacer>
        <v-avatar color="primary" size="48"
          ><v-img src="./assets/logo.png"></v-img>
        </v-avatar>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(menuItem, i) in menuItems"
              :key="i"
              @click="menuItem.onClick"
            >
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <trade-advice />
      </template>
    </v-app-bar>
    <v-main app v-if="this.userAuth.uid == null">
      <v-container fluid>
        <v-fade-transition mode="out-in">
          
          <router-view />
        
        
        
        </v-fade-transition>
      </v-container>
    </v-main>
  <template  v-if="userProfile && userProfile.role != 'Admin'">
  <div class="main-cover" v-if="userAuth.uid">
	 <div class="sidebar-wrap" v-if="userAuth.uid">

		<div class="side-details">
			<router-link to="/" exact class="logo" style="text-decoration:none;">
        <div class="logo"> Perquity </div>
        </router-link>
			<ul>
				<li v-for="navItem in navItems"
        :key="navItem.title" @click="navItem.onClick"> 
					<span> <img :src="navItem.icon"> </span>
					<span> {{ navItem.title }}</span>
				</li>
			</ul>
		</div>
	</div>
	<div class="main-content">
		<div class="top-header-section">
      <!-- <div v-if="this.loading">{{ this.loadingMessage }}</div> -->
			<div class="th-left">
        <!-- <trade-advice /> -->
				<!-- <div class="thead"> Portfolio Overview for {{currentUser.email.split('@')[0].charAt(0).toUpperCase() + currentUser.email.split('@')[0].slice(1)}}  </div> -->
				<!-- <div class="tsubhead"> (Value in INR)  </div> -->
				
			</div>
       
			<div class="th-right" v-if="userAuth.uid"> 
				<!-- <div class="tright-time-date"> *As on 28th April, 2021; 15:30:56 </div> -->
				<div class="profile-menu">
					<div class="dropdown-container">
						<div class="dropdown-toggle click-dropdown">
						 <img src="images/profile.png">
						 
             <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <img src="images/arrow.svg" style="width:15px">
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(menuItem, i) in menuItems"
              :key="i"
              @click="menuItem.onClick"
            >
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
						</div>
						<div class="dropdown-menu">
							<ul>
								<li><a href="#">DropDown Menu Item 1</a></li>
								<li><a href="#">DropDown Menu Item 2</a></li>
								<li><a href="#">DropDown Menu Item 3</a></li>
								<li><a href="#">DropDown Menu Item 4</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

    <router-view />
		
	</div>
  </div>
  </template>
  <template v-else>
    <router-view />
  </template>  

    <!-- <v-footer class="footer">
      
      <h3>Powered by Two {{ scrapeOutdated }}</h3>
    </v-footer> -->
     <footer>
      <div class="footer-wrap"> Powered by Two Digital </div>
    </footer>
    <template v-if="activeDialog">
      <v-dialog v-model="activeDialog" width="1000" persistent v-if="activeDialog == 'ShowWelcome'">
        <WelcomeDialog v-if="activeDialog == 'ShowWelcome'" />
      </v-dialog>
      <v-dialog v-model="activeDialog" width="500" persistent v-else>
        <add-transaction v-if="activeDialog == 'addTransaction'" />
        <add-share v-if="activeDialog == 'addShare'"></add-share>
        <add-profit-ratio v-if="activeDialog == 'addProfitRatio'" />
        <add-announcement v-if="activeDialog == 'AddAnnouncement' || activeDialog == 'AddFuture'" />
      </v-dialog>
    </template>
    <v-snackbar v-model="showSnackbar" :timeout="2000">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>


  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import router from './router'
import AddProfitRatio from './components/AddProfitRatio'
import AddShare from './components/AddShare.vue'
import AddAnnouncement from './components/AddAnnouncement.vue'
import TradeAdvice from './components/TradeAdvice.vue'
import AddTransaction from './components/AddTransaction.vue'
import WelcomeDialog from './components/WelcomeDialog.vue'
import { auth } from '@/db'
// import IconBase from './components/IconBase.vue'
// import plusIcon from './components/icons/PlusIcon.vue'
// import faqs from './components/icons/faqs.vue'

export default {
  components: {
    AddProfitRatio,
    AddShare,
    TradeAdvice,
    AddTransaction,
    WelcomeDialog,
    AddAnnouncement
    // IconBase
    // PlusIcon,
    // faqs
  },
  data() {
    return {
      valid: false,
      drawer: true,
      showSnackbar: false,
      snackbarText: '',
      auth:auth,
      currentUser:auth.currentUser,
      //currentRoute:this.$route.name,
      navItems: [
        {
          title: 'Introduction',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            //router.push({ name: 'Home' })
            this.updateActiveDialog('ShowWelcome')
          }
          // img: './assets/logo.png'
        },
        {
          title: 'Portfolio',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            router.push({ name: 'Home' })
            //this.updateActiveDialog('addShare')
          }
          // img: './assets/logo.png'
        },
        {
          title: 'Add New Scrip',
          // iconC: PlusIcon,
          icon: 'images/sb2.png',
          onClick: () => {
            console.log("Helooooooooo===>");
            //router.push({ name: 'Home' })
            this.updateActiveDialog('addShare')
            console.log("=========>", this.activeDialog);
          }
          // img: './assets/logo.png'
        },
        {
          title: 'Set Profit Ratio',
          icon: 'images/sb3.png',
          onClick: () => {
            //router.push({ name: 'Home' })
            this.updateActiveDialog('addProfitRatio')
          }
          // img: './assets/Plus.svg'
        },
        {
          title: 'Buy / Sell Advice',
          icon: 'images/sb4.png',
          onClick: () => {
            router.push({ name: 'TradeAdvice' })
          }
          // img: './assets/Plus.svg'
        },
        {
          title: 'FAQs',
          icon: 'images/sb5.png',
          onClick: () => {
            router.push({ name: 'FAQs' })
          }
        },
        {
          title: 'Manuel',
          icon: 'images/sb5.png',
          onClick: () => {
            router.push({ name: 'FAQs' })
          }
        }
      ],
      links: [
        {
          title: 'SIGN UP',
          icon: 'mdi-account-group-outline',
          route: { name: 'Register' }
        },
        {
          title: 'SIGN IN',
          icon: 'mdi-account-group-outline',
          route: { name: 'Login' }
        }
      ],
      menuItems: [{ title: 'Logout', onClick: this.logOut }]
      // mini: true
      // user: {
      //   profile: {
      //     name: "loading"
      //   }
      // }
    }
  },
  computed: {
    ...mapState([
      'userAuth',
      'activeDialog',
      'loading',
      'loadingMessage',
      'snackbar',
      'snackbarMessage',
      'appData',
      'userProfile',
    ]),
    lastScrapeDate() {
      if(this.appData.lastScrapedAt.seconds){
        return this.appData.lastScrapedAt.seconds
      }
      else { 
        return 'false'
      }
    },
    ...mapGetters(['scrapeOutdated']),
    mini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
        default:
          return false
      }
    }
  },
  watch: {
    scrapeOutdated(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`)

      // Do whatever makes sense now
      if (newValue === true) {
        console.log(`scrapeOutdated changed value`)
        this.getMarketData()
      }
      if (newValue === false) {
        console.log(`scrapeOutdated changed value but not Outdated`)
      }
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_SNACKBAR_STATUS') {
        console.log('mutation.type', mutation.type)
        this.snackbarText = state.snackbarMessage
        this.timeout = 2000
        this.showSnackbar = true
      }
    })
  },
  beforeCreate() {
    if (!this.$store.state.userAuth) {
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    logOut() {
      localStorage.clear();
      this.$store.dispatch('logOut')
    },
    formatDateTime(seconds) {
      const date = new Date(seconds * 1000)
      let month =
        String(date.getMonth()).length < 2
          ? '0' + (date.getMonth() + 1)
          : date.getMonth()
      let day =
        String(date.getDate()).length < 2
          ? '0' + date.getDate()
          : date.getDate()
      console.log(`${date.getFullYear()}-${month}-${day}`)
      return `${date.getFullYear()}-${month}-${day}`
    },
    ...mapActions(['updateActiveDialog', 'getMarketData'])
  }
}
</script>
<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.nav-bar,
.footer {
  background-color: $colors-brand-neutral_light-7 !important;
  color: $colors-brand-ui_5;
  // @include body1;
}
.ds-button {
  border-radius: 0.5rem;
  background-color: $colors-brand-ui_5 !important;
  color: $colors-brand-ui_6;
  @include body_7;
  margin-top: 1.375rem;
  padding: 1.25rem !important;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08) !important;
}
nav.ds-left-navigation {
  margin-top: 3.125rem;
}
.ds-left-navigation::v-deep .v-navigation-drawer__content {
  overflow-y: visible !important;
  margin: auto !important;
}
.ds-left-navigation::v-deep .v-navigation-drawer__border {
  background-color: white !important;
}
.ds-left-navigation::v-deep .v-divider {
  border: 0;
}
.ds-logo {
  @include heading_1_bold;
  font-size: 1.875rem !important;
  color: $colors-brand-ui_3;
  overflow: visible !important;
}
.nav-bar::v-deep a:-webkit-any-link {
  text-decoration: none !important;
}
.ds-icon::v-deep .v-icon__component {
  height: 3rem;
  width: 3rem;
}
.side-details ul li {
    cursor: pointer;
}
</style>
