<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.7754 10.9968L36.3285 0.281152C36.0566 0.0280274 35.6254 0.0280274 35.3535 0.281152L23.9066 10.9968C23.691 11.1937 23.6254 11.5124 23.7285 11.7843C23.8316 12.0562 24.1035 12.2343 24.3941 12.2343H28.8941V14.1562C28.8941 14.5499 29.2129 14.8687 29.6066 14.8687C30.0004 14.8687 30.3191 14.5499 30.3191 14.1562V11.5124C30.3191 11.1187 30.0004 10.7999 29.6066 10.7999H26.1941L35.841 1.78115L45.4785 10.8093H42.066C41.6722 10.8093 41.3535 11.128 41.3535 11.5218V35.4374C41.3535 35.8312 41.6722 36.1499 42.066 36.1499C42.4597 36.1499 42.7785 35.8312 42.7785 35.4374V12.2343H47.2785C47.5691 12.2343 47.8316 12.0562 47.9441 11.7843C48.0566 11.5124 47.991 11.203 47.7754 10.9968Z"
      fill="#765AC8"
    />
    <path
      d="M42.0658 39.3188C41.6721 39.3188 41.3533 39.6376 41.3533 40.0313V46.4813H30.3283V45.872C30.3283 45.4782 30.0096 45.1595 29.6158 45.1595C29.2221 45.1595 28.9033 45.4782 28.9033 45.872V47.1938C28.9033 47.5876 29.2221 47.9063 29.6158 47.9063H42.0658C42.4596 47.9063 42.7783 47.5876 42.7783 47.1938V40.0313C42.7783 39.6376 42.4596 39.3188 42.0658 39.3188Z"
      fill="#765AC8"
    />
    <path
      d="M35.8969 29.9623C35.8969 20.0623 27.8438 12.0186 17.9531 12.0186C17.5594 12.0186 17.2406 12.3373 17.2406 12.7311C17.2406 13.1248 17.5594 13.4436 17.9531 13.4436C27.0656 13.4436 34.4719 20.8498 34.4719 29.9623C34.4719 39.0748 27.0656 46.481 17.9531 46.481C8.84063 46.481 1.43438 39.0748 1.43438 29.9623C1.43438 22.1061 7.02187 15.2904 14.7094 13.7623C15.0937 13.6873 15.3469 13.3123 15.2719 12.9186C15.1969 12.5342 14.8219 12.2811 14.4281 12.3561C6.06562 14.0248 0 21.4311 0 29.9623C0 39.8623 8.05312 47.9061 17.9437 47.9061C27.8344 47.9061 35.8969 39.8623 35.8969 29.9623Z"
      fill="#765AC8"
    />
    <path
      d="M7.6499 40.2654C10.4905 43.1061 14.2218 44.5217 17.953 44.5217C21.6843 44.5217 25.4155 43.1061 28.2562 40.2654C33.9374 34.5842 33.9374 25.3404 28.2562 19.6686C22.5749 13.9967 13.3312 13.9873 7.65928 19.6686C1.96865 25.3404 1.96865 34.5842 7.6499 40.2654ZM17.9437 16.8373C21.3093 16.8373 24.6749 18.1217 27.2343 20.6811C32.353 25.7998 32.353 34.1342 27.2343 39.2623C22.1155 44.3904 13.7812 44.3811 8.65303 39.2623C3.53428 34.1436 3.53428 25.8092 8.65303 20.6811C11.2218 18.1123 14.578 16.8373 17.9437 16.8373Z"
      fill="#765AC8"
    />
    <path
      d="M21.722 38.3344C21.5157 38.3344 21.3189 38.2594 21.1689 38.1094C20.672 37.6219 20.3814 36.7688 20.2501 36.3V36.2812C20.0439 35.6812 19.7907 34.6687 19.4251 33.0844C18.9376 30.8344 18.047 29.9625 16.1157 29.8969H14.4845H14.4657H13.4063C13.0688 29.8969 12.797 29.625 12.797 29.2875C12.797 28.95 13.0688 28.6781 13.4063 28.6781H16.2845C18.0095 28.6781 19.3314 27.9188 19.8001 26.6438L19.9407 26.2594H12.6188C12.272 26.2594 11.9907 25.9781 11.9907 25.6312C11.9907 25.2844 12.272 25.0031 12.6188 25.0031H19.9876L19.9032 24.6562C19.5845 23.325 18.4407 22.5281 16.6876 22.3969H16.6782H12.6282C12.272 22.3969 12.0001 22.1437 12.0001 21.7969C12.0001 21.4594 12.2813 21.1875 12.6282 21.1875H16.0313H16.0876H16.1626H23.297C23.6532 21.1875 23.9251 21.4594 23.9251 21.7969C23.9251 22.1344 23.6532 22.3969 23.297 22.3969H20.6251L20.972 22.8469C21.3564 23.3531 21.6095 24.0281 21.6939 24.75L21.722 25.0031H23.297C23.6439 25.0031 23.9251 25.2844 23.9251 25.6312C23.9251 25.9781 23.6439 26.2594 23.297 26.2594H21.6189L21.5626 26.4656C21.2157 27.6656 20.3251 28.5938 19.0595 29.0813L18.4126 29.3344L19.0501 29.6062C20.0439 30.0375 20.7095 31.0125 21.0751 32.5969C21.5532 34.65 21.947 36.2719 22.2939 36.9375L22.3126 36.9937L22.3501 37.0312C22.6126 37.3406 22.6032 37.8 22.322 38.0906C22.1439 38.25 21.947 38.3344 21.722 38.3344Z"
      fill="#765AC8"
    />
    <path
      d="M42.0845 38.522C42.4728 38.522 42.7876 38.2072 42.7876 37.8188C42.7876 37.4305 42.4728 37.1157 42.0845 37.1157C41.6961 37.1157 41.3813 37.4305 41.3813 37.8188C41.3813 38.2072 41.6961 38.522 42.0845 38.522Z"
      fill="#765AC8"
    />
  </svg>
</template>

<script>
export default {
  name: 'profitratio'
}
</script>

<style lang="scss" scoped></style>
