<template>
  <svg
    width="48"
    height="44"
    viewBox="0 0 48 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8923 14.015C18.8064 14.015 18.8083 12.2675 17.8923 12.2675H10.2358C9.82702 12.2675 9.36202 12.5028 9.36202 12.9669V27.5853C9.36202 28.5022 11.283 28.5013 11.283 27.5853V21.0632H14.6298C15.5336 21.0632 15.5298 19.446 14.6298 19.446H11.283V14.015H17.8923Z"
      fill="#765AC8"
    />
    <path
      d="M17.3064 28.0391C17.7349 28.3025 18.5861 28.4347 18.7764 27.8066L19.7205 24.6303H25.4064L26.3542 27.8178C26.627 28.6775 28.3708 28.1928 28.2077 27.3294L23.7377 12.8104C23.4564 11.885 21.708 11.8822 21.4296 12.8047L16.9774 27.2657C16.8733 27.576 17.0317 27.8694 17.3064 28.0391C17.0627 27.8853 17.7349 28.3025 17.3064 28.0391ZM20.2005 23.0132L22.5714 15.0313L24.9283 23.0122L20.2005 23.0132Z"
      fill="#765AC8"
    />
    <path
      d="M34.1746 30.4879C34.2765 30.5795 34.4027 30.6398 34.538 30.6615C34.6733 30.6832 34.812 30.6655 34.9375 30.6104C35.0629 30.5552 35.1698 30.4651 35.2454 30.3508C35.3209 30.2365 35.3619 30.1028 35.3634 29.9657V28.3439C36.5821 28.2501 37.5656 27.817 38.2874 27.052C39.0093 26.287 39.3881 25.117 39.3881 23.6039V17.0573C39.3881 15.412 38.9503 14.1895 38.0887 13.4217C36.3074 11.8382 32.9831 11.8279 31.2056 13.4217C30.3515 14.1885 29.9174 15.411 29.9174 17.0564V23.6067C29.9174 25.1348 30.2878 26.2992 31.0181 27.0661C31.7484 27.8329 32.7299 28.2539 33.9637 28.3467V29.9657C33.9591 30.0628 33.9756 30.1598 34.012 30.2499C34.0484 30.3401 34.1039 30.4212 34.1746 30.4879ZM35.1562 24.6267C34.709 24.2517 33.9684 24.5329 33.9684 25.1404V26.5945C32.5621 26.3507 31.844 25.3561 31.844 23.6376V17.0676C31.844 15.0135 32.7609 14.0151 34.6471 14.0151C36.4996 14.0151 37.4709 15.3014 37.4709 17.0676V23.6376C37.4709 25.3701 36.7631 26.3564 35.3681 26.5729V25.1404C35.3713 25.036 35.3501 24.9323 35.3064 24.8374C35.2626 24.7426 35.1974 24.6592 35.1159 24.5939C35.1431 24.6154 35.1703 24.6389 35.1965 24.6623C35.0831 24.5629 35.0915 24.5686 35.1562 24.6267C35.2921 24.7467 34.9284 24.4382 35.1562 24.6267Z"
      fill="#765AC8"
    />
    <path
      d="M20.6868 43.5024C20.4896 43.5012 20.3007 43.4223 20.1612 43.2828C20.0217 43.1432 19.9428 42.9544 19.9415 42.7571V39.458C14.5762 38.7258 9.65622 36.489 6.02997 33.1252C2.18247 29.5487 0.0637207 24.9493 0.0637207 20.1699C0.0637207 14.9199 2.56028 9.98866 7.09497 6.29491C11.5837 2.63866 17.5434 0.618347 23.8762 0.618347C30.209 0.618347 36.1678 2.63397 40.6575 6.29491C45.1903 9.99241 47.6887 14.9199 47.6887 20.1699C47.6887 25.3112 45.2822 30.1655 40.9125 33.8377C40.7608 33.9617 40.5664 34.021 40.3714 34.0027C40.1763 33.9845 39.9963 33.8902 39.8703 33.7402C39.7442 33.5903 39.6823 33.3967 39.6979 33.2015C39.7134 33.0062 39.8052 32.8249 39.9534 32.6968C43.9847 29.3124 46.1981 24.863 46.1981 20.1699C46.1962 10.209 36.1875 2.10522 23.8753 2.10522C11.5631 2.10522 1.55435 10.209 1.55435 20.1699C1.55435 24.5302 3.50341 28.7415 7.04341 32.0293C10.5834 35.3171 15.4622 37.4612 20.7703 38.0602C20.9523 38.0805 21.1204 38.1672 21.2425 38.3036C21.3646 38.4401 21.4321 38.6168 21.4322 38.7999V40.9562L23.9437 38.4446C24.0797 38.3085 24.2632 38.2305 24.4556 38.2271C28.3106 38.1474 32.0878 37.2559 35.3784 35.649C35.5525 35.5853 35.7439 35.5883 35.9159 35.6574C36.0879 35.7266 36.2281 35.8569 36.3097 36.0234C36.3912 36.1898 36.4082 36.3806 36.3574 36.5588C36.3067 36.737 36.1917 36.8902 36.0347 36.9887C32.6362 38.6471 28.754 39.5855 24.7847 39.7074L21.2109 43.2802C21.0726 43.4208 20.8841 43.5007 20.6868 43.5024Z"
      fill="#765AC8"
    />
    <path
      d="M38.2641 36.0238C38.7047 36.0238 39.0619 35.6667 39.0619 35.226C39.0619 34.7854 38.7047 34.4282 38.2641 34.4282C37.8234 34.4282 37.4662 34.7854 37.4662 35.226C37.4662 35.6667 37.8234 36.0238 38.2641 36.0238Z"
      fill="#765AC8"
    />
  </svg>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'faqs'
})
</script>
