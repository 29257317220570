import { vuexfireMutations, firestoreAction } from 'vuexfire'

import Vue from 'vue'
import Vuex from 'vuex'

import 'es6-promise/auto'

import router from '../router'
import * as fb from '@/db'
import TradilioAPIService from '@/services/TradilioAPIService.js'
import { encrypt } from '../services/IncryptionService' 
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Vuex)
Vue.use(VueToast);
export default new Vuex.Store({
  /** ##############################################################################
   *  STATE 
   ############################################################################## */
  state: {
    // Authentication & User Profile
    userAuth: {}, // Receives current user from fb.authentication in loginUser() (null if not signed in)
    userProfile: {}, // bindUser() binds user profile doc from firestore
    allUsers: [],
    // App Data
    appData: {},

    // User Shares from user doc sub collection
    userShares: {},
    // TradeAdvice
    tradeAdvice: {
      all_shares_composit_purchase_value: 0,
      all_shares_count: 0,
      all_shares_market_value: 0,
      avg_profit_loss_percentage: 0,
      category_values: {},
      greed_percentage: 0,
      shares: [],
      total_profit_loss: 0
    },
    tradeAdviceGenerated: false,

    // Active dialog
    activeDialog: '',

    // Loading status & message
    loading: false, // show/hide loading indicator
    loadingMessage: 'Loading',

    // Snackbar status & message
    snackbar: true, // show/hide snack
    snackbarMessage: '', // message for snackbar

    // Update tradeAdvice — value updates whenever tradeAdvice needs to refresh
    updateTradeAdvice: false // refreshes whenever tradeAdvice should run
  },

  /** ##############################################################################
   *  MUTATIONS
   ############################################################################## */
  mutations: {
    SET_USER_AUTH(state, val) {
      state.userAuth = val
    },
    SET_TRADE_ADVICE(state, val) {
      state.tradeAdvice = val
    },
    SET_TRADE_ADVICE_GENERATED(state, val) {
      state.tradeAdviceGenerated = val
    },
    SET_ACTIVE_DIALOG(state, val) {
      state.activeDialog = val
    },
    SET_LOADING_STATUS(state, val) {
      state.loading = val
    },
    SET_LOADING_MESSAGE(state, val) {
      state.loadingMessage = val
    },
    SET_SNACKBAR_STATUS(state, val) {
      state.snackbar = val
    },
    SET_SNACKBAR_MESSAGE(state, val) {
      state.snackbarMessage = val
    },
    SET_SELECTED_SHARE(state, val) {
      state.selectedShare = val
    },
    SET_UPDATE_TRADEADVICE(state, val) {
      state.updateTradeAdvice = val
    },
    TOGGLE_TRANSACTION_FORM(state, val) {
      state.transactionFormIsVisible = val
    },
    TOGGLE_SHARE_FORM(state, val) {
      state.shareFormIsVisible = val
    },
    TOGGLE_PROFITRATIO_FORM(state, val) {
      state.profitRatioFormIsVisible = val
    },
    ...vuexfireMutations
  },

  /** ##############################################################################
   *  ACTIONS
   ############################################################################## */
  actions: {
    /**
     * ############# ALL FIRESTORE BIND ACTIONS ###################
     */

    /**
     * Bind user data to the state
     * — Initiated during loginUser
     */
    bindUser: firestoreAction(({ bindFirestoreRef }, id) => {
      return bindFirestoreRef(
        'userProfile',
        fb.db.collection('users').doc(id),
        {
          maxRefDepth: 1
        }
      )
    }),
    /**
     * Bind all user data to the state
     * — Initiated during loginUser
     */
    bindAllUser: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'allUsers',
        fb.db.collection('users'),
        {
          maxRefDepth: 1
        }
      )
    }),


    bindRoute: firestoreAction(({ bindFirestoreRef }) => {
      console.log("CheckCurrentRouteeeeeeeeeeeeeeeeee", window.location.pathname);
      return bindFirestoreRef(
        'CurrenRoute',
        window.location.pathname
      )
    }),



    /**
     * Bind user data to the state
     * — Initiated during loginUser
     */
     bindUserSingle: firestoreAction(({ bindFirestoreRef }, id) => {
      return bindFirestoreRef(
        'userProfileSingle',
        fb.db.collection('users').doc(id),
        {
          maxRefDepth: 1
        }
      )
    }),
    


    /**
     * Bind user share data to the state.userShares
     * — Initiated during loginUser
     */
    bindUserShares: firestoreAction(({ bindFirestoreRef }, userId) => {
      return bindFirestoreRef(
        'userShares',
        fb.db.collection('users').doc(userId).collection('shares'),
        {
          maxRefDepth: 1
        }
      )
    }),

    /**
     * Bind user share data to the state.userShares
     * — Initiated during loginUser
     */
    bindUserSharesSingle: firestoreAction(({ bindFirestoreRef }, userId) => {
      return bindFirestoreRef(
        'userSharesSingle',
        fb.db.collection('users').doc(userId).collection('shares'),
        {
          maxRefDepth: 1
        }
      )
    }),

    /**
     * Bind Share Market Data to the state.marketData
     * TODO: No longer required
     */
    bindMarketData: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'marketData',
        fb.db
          .collection('shareSourceData')
          .orderBy('timestamp', 'desc')
          .limit(1)
      )
    }),

    /**
     * Bind all appData to the state
     */
    bindAppData: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'appData',
        fb.db.collection('appData').doc('scraper'),
        {
          maxRefDepth: 0
        }
      )
    }),

    /**
     * #################################################
     * ############# SCRAPER ACTIONS ###################
     * #################################################
     */

    /**
     * Initiate Scrape
     * — Creates a new Document with timestamp for each scrape
     * and returns the docRef
     * — Document holds a sub collection 'scrapedShares'
     */
    initiateScrape: firestoreAction(() => {
      return fb.db.collection('marketData').add({
        startedAt: fb.serverTimestamp,
        status: 'initiated'
      })
    }),
    /**
     * Finalize Scrape
     * — Updates the scrape document with status based on success
     * — Adds the strippedSharesArray with all shares ticker, name and docref
     * for use in dropdown and while fetching data for trade advice.
     */
    finalizeScrape: firestoreAction((context, payload) => {
      console.log('payload in finalize: ', payload)
      if (payload.success === true) {
        return fb.db
          .doc(payload.ScrapeDocRef)
          .set(
            {
              status: 'success',
              finalizedAt: fb.serverTimestamp
              // strippedSharesArray: payload.strippedSharesArray
            },
            { merge: true }
          )
          .then(() => {
            return fb.db.doc('appData/scraper').set({
              lastScrapeRef: fb.db.doc(payload.ScrapeDocRef),
              lastScrapedAt: fb.serverTimestamp,
              strippedSharesArray: payload.strippedSharesArray
            })
          })
      } else {
        return fb.db.doc(payload.ScrapeDocRef).set(
          {
            status: 'failed',
            finalizedAt: fb.serverTimestamp
          },
          { merge: true }
        )
      }
    }),

    /**
     * AddShareDataToScrape
     * Receives an object with 50 shares from api page
     * creates a batch write of 50 docs and stores them in
     * the scrapedPages sub collection in firestore
     */
    AddShareDataToScrape: firestoreAction(async (context, payload) => {
      console.log('payload.ScrapeDocRef', payload.ScrapeDocRef)
      // let batches = []
      // Add one document for each share to the batch write
      const promises = []
      for (let i = 0; i < payload.pageShares.length; i += 100) {
        let batchShares = payload.pageShares.slice(i, i + 100)
        console.log(batchShares)
        // Get a new write batch
        var batch = fb.db.batch()

        batchShares.forEach((share) => {
          share.createdAt = fb.serverTimestamp
          console.log(share)
          var shareRef = fb.db
            .doc(String(payload.ScrapeDocRef.path))
            .collection('scrapedPages')
            .doc(String(share.ticker))
          batch.set(shareRef, share)
          // Commit the batch
        })
        promises.push(batch.commit())
      }
      return Promise.all(promises)
        .then(() => {
          console.log('batch commit success')
          return true
        })
        .catch(() => {
          console.error('batch commit failed')
          return false
        })
      // console.log(batches)
    }),

    /**
     * Get Market Data from Tradillio API Service
     */
    async getMarketData({ dispatch, commit }) {
      // Initiate the scraping
      commit('SET_LOADING_STATUS', true)
      commit('SET_LOADING_MESSAGE', 'Fetching NSE data through API...')
      commit('SET_SNACKBAR_STATUS', true)
      commit('SET_SNACKBAR_MESSAGE', 'Fetching NSE data through API...')
      // Declare scrapeDocRef outside try/catch
      let ScrapeDocRef = {}
      try {
        // Create new Document in FireStore for this scrape and return the docId
        ScrapeDocRef = await dispatch('initiateScrape').catch((error) => {
          console.error('Error adding document: ', error)
        })
        console.log('Document written with ID: ', ScrapeDocRef.id)

        // Get total API pages
        let total_pages = 0
        await TradilioAPIService.getPage(0).then((response) => {
          total_pages = response.data.total_pages
          console.log('total API pages: ', total_pages)
        })

        // Create API request for all API pages and
        // store in array promises
        const promises = []
        for (let i = 1; i < total_pages - 0; i++) {
          promises.push(TradilioAPIService.getPage(i))
        }
        // Wait for all promises to resolve
        // and collect all shares in allScrapedShares
        let allScrapedShares = await Promise.all(promises)
          .then((response) => {
            // loop through all shares in each response
            // and add to scrapedShares array
            let scrapedShares = []
            response.forEach((page) => {
              scrapedShares.push(...page.data.data)
            })
            // return all scrapedShares
            return scrapedShares
          })
          .catch((e) => {
            // handle errors here
            console.error(e)
          })
        console.log(allScrapedShares)

        // Create simplified array of shares for appData
        let strippedSharesArray = []
        allScrapedShares.forEach((e) => {
          strippedSharesArray.push({
            ticker: e.ticker,
            company_name: e.company_name
          })
        })

        // Add scrapedShares as individual docs to sub collection
        // - batchWriteStatus get returned true if write is success
        const batchWriteStatus = await dispatch('AddShareDataToScrape', {
          pageShares: allScrapedShares,
          ScrapeDocRef: ScrapeDocRef
        })

        console.log('batchWriteIP', batchWriteStatus)
        // If batch write completed successfully Finalize the scrape
        if (batchWriteStatus) {
          await dispatch('finalizeScrape', {
            success: true,
            ScrapeDocRef: String(ScrapeDocRef.path),
            strippedSharesArray: strippedSharesArray
          })
            .then(() => {
              console.log('Scrape Finalized and Successful')
              commit('SET_SNACKBAR_STATUS', true)
              commit(
                'SET_SNACKBAR_MESSAGE',
                'Market Data loaded successfully from NSE'
              )
            })
            .catch((error) => {
              commit('SET_SNACKBAR_STATUS', true)
              commit(
                'SET_SNACKBAR_MESSAGE',
                'There was an issue finalizing the scrape'
              )

              console.log('There was an issue finalizing the scrape')
              console.log(error)
            })
        } else {
          // If batchWriteStatus failed, fail the scrape.
          await dispatch('finalizeScrape', {
            success: false,
            ScrapeDocRef: String(ScrapeDocRef.path)
          })
            .then(() => {
              console.log('Scrape Finalized but Failed')
            })
            .catch((error) => {
              console.log('There was an issue finalizing the scrape')
              console.log(error)
            })
        }
      } catch (error) {
        console.error(error)
        await dispatch('finalizeScrape', {
          success: false,
          ScrapeDocRef: String(ScrapeDocRef.path)
        })
          .then(() => {
            console.log('Scrape Finalized but Failed')
          })
          .catch((error) => {
            console.log('There was an issue finalizing the scrape')
            console.log(error)
          })
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },

    /**
     * #################################################
     * ############### OTHER ACTIONS ###################
     * #################################################
     */

    /**
     * Update User Share Data Reference to latest scraped market data
     */
    updateUserShareDataRef: firestoreAction(
      async ({ state, commit }, payload) => {
        // console.log('payload', payload)
        let docRef = fb.db
          .collection('users')
          .doc(String(state.userAuth.uid))
          .collection('shares')
          .doc(String(payload))

        // console.log('docRef', docRef)

        return docRef
          .update({
            marketDataRef: fb.db
              .doc(state.appData.lastScrapeRef)
              .collection('scrapedPages')
              .doc(payload),
            marketDataUpdatedAt: fb.serverTimestamp
          })
          .then(() => {
            commit('SET_UPDATE_TRADEADVICE', 'addShare')
          })
      }
    ),

    updateUserShareDataByUserIdRef: firestoreAction(
      async ({ state, commit }, payload) => {
        // console.log('payload', payload)
        let docRef = fb.db
          .collection('users')
          .doc(String(payload.userId))
          .collection('shares')
          .doc(String(payload.shareTicker))

        // console.log('docRef', docRef)

        return docRef
          .update({
            marketDataRef: fb.db
              .doc(state.appData.lastScrapeRef)
              .collection('scrapedPages')
              .doc(payload.shareTicker),
            marketDataUpdatedAt: fb.serverTimestamp
          })
          .then(() => {
            commit('SET_UPDATE_TRADEADVICE', 'addShare')
          })
      }
    ),

    updateUserShareDataRefAfterDelete: firestoreAction(
      async ({ state, commit }, payload) => {
        // console.log('payload', payload)
        let docRef = fb.db
          .collection('users')
          .doc(String(state.userAuth.uid))
          .collection('shares')
          .doc(String(payload));

        // console.log('docRef', docRef)

        return docRef
          .delete({
            marketDataRef: fb.db
              .doc(state.appData.lastScrapeRef)
              .collection('scrapedPages')
              .doc(String(payload)),
            marketDataUpdatedAt: fb.serverTimestamp
          })
          .then(() => {
            commit('SET_UPDATE_TRADEADVICE', 'deleteShare');
          })
      }
    ),

    /**
     * updateTradeAdvice
     * @param {*} payload — user object
     */
    updateTradeAdvice({ commit }, payload) {
      // console.log('updateTradeAdvice from store, payload:')
      // console.log(payload)
      commit('SET_TRADE_ADVICE', payload)
      commit('SET_TRADE_ADVICE_GENERATED', true)
    },

    updateActiveDialog({ commit }, payload) {
      commit('SET_ACTIVE_DIALOG', payload)
    },
    // load the share id into the selectedShare state
    selectedShareRow({ commit }, shareId) {
      commit('SET_SELECTED_SHARE', shareId)
    },

    // Set greed percentage
    async updateGreedPercentage({ state, commit }, percentage) {
      try {
        await fb.db.doc(`users/${state.userAuth.uid}`).update({
          greedPercentage: percentage
        })
        commit('SET_UPDATE_TRADEADVICE', 'updateGreedPercentage')
      } catch (error) {
        console.error('updateGreedPercentage', error)
      }
    },

    /**
     * #################################################
     * ##### MANAGE SHARES AND TRANSACTIONS ############
     * #################################################
     */

    // add the new share to the user on firebase
    async addShare({ state, dispatch, commit }, shareData) {
      try {
        // Add share to user share collection
        await fb.db
          .collection('users')
          .doc(state.userAuth.uid)
          .collection('shares')
          .doc(shareData.ticker)
          .set(shareData)

        console.log('New Share Added', shareData)
        // Update share with latest ScrapeDocRef
        dispatch('updateUserShareDataRef', shareData.ticker)
        commit('SET_SNACKBAR_STATUS', true)
        commit(
          'SET_SNACKBAR_MESSAGE',
          `Successfully added`
        )
      } catch (error) {
        console.error('addShare', error)
      } finally {
        // console.log('New Share Added', shareData)
      }
    },

    // add the new share to the user on firebase
    async updateShare({ state, dispatch, commit }, shareData) {
      try {
        console.log("=======>",state.userAuth.uid);
        // Add share to user share collection
        //         var cityRef = db.collection('cities').doc('BJ');

        // var setWithMerge = cityRef.set({
        //     capital: true
        // }, { merge: true });
        shareData.users.map(async (item)=>{
          var userRef = fb.db
          .collection('users')
          .doc(item.id)
          .collection('shares')
          .doc(shareData.shareModel.ticker);

          
          let NewData = await userRef.get();
          if (NewData.exists) {
             if (shareData.activeDilog == 'AddAnnouncement') {
              await userRef
              .update({
                announcement: shareData.shareModel.announcement
               })
             } else {
              await userRef
              .update({
                future: shareData.shareModel.announcement
               })
             }
          }
          console.log('New Share Added', shareData)
          // Update share with latest ScrapeDocRef
          dispatch('updateUserShareDataRef', shareData.shareModel.ticker)
        });
        commit('SET_SNACKBAR_STATUS', true)
        commit(
          'SET_SNACKBAR_MESSAGE',
          `Successfully added`
        )
      } catch (error) {
        console.error('addShare', error)
      } finally {
        // console.log('New Share Added', shareData)
      }
    },


    // add the new share to the user on firebase
    async getSpecialAnnouncement({commit}, shareData) {
      try {

       // var hasValueLessThanTen = false;
       let getData;
        for (var i = 0; i < shareData.users.length; i++) {
          var userRef = fb.db
          .collection('users')
          .doc(shareData.users[i].id)
          .collection('shares')
          .doc(shareData.shareModel.ticker);

          let NewData = await userRef.get();
          console.log("00000000=====>", NewData.data());
          if (NewData.exists) {
             getData = NewData.data();
             break;
          } 
        }
        commit('SET_SNACKBAR_MESSAGE', '')
        return getData;
      } catch (error) {
        console.error('addShare', error)
      }
    },

    // Delete the empty transaction shares
    async deleteShare({ state,dispatch,commit }, shareData) {
      try {
        // Add share to user share collection
        await fb.db
          .collection('users')
          .doc(state.userAuth.uid)
          .collection('shares')
          .doc(shareData.ticker)
          .delete()

        //console.log('New Share Added', shareData)
        // Update share with latest ScrapeDocRef
        dispatch('updateUserShareDataRefAfterDelete', shareData.ticker)

        commit('SET_SNACKBAR_STATUS', true)
        commit(
          'SET_SNACKBAR_MESSAGE',
          `Successfully Delete`
        )
      } catch (error) {
        console.error('deleteShare', error)
      } finally {
        // console.log('New Share Added', shareData)
      }
    },


    /**
     * addTransaction
     * Add the transaction data into firebase transaction collection
     * @param {object} state
     * @param {object} transactionData
     * model: {
        ticker: ""
        transactionType: "",
        transactionPricePerShare: 0,
        quantity: 0,
        dateTime: new Date().toISOString().substr(0, 10)
      }
     */
      async addTransaction({ state, commit }, transactionData) {
        // Format transaction data to be stored
        let data = {
          userRef: fb.db.collection('users').doc(state.userAuth.uid),
          shareRef: fb.db
            .collection('users')
            .doc(state.userAuth.uid)
            .collection('shares')
            .doc(transactionData.ticker),
          timestamp: fb.Timestamp.fromDate(new Date()),
          ...transactionData
        }
  
        // update transaction dateTime to proper timestamp
        let tempTime = fb.Timestamp.fromDate(new Date(data.dateTime))
        data.dateTime = tempTime
  
        // Strip doc References to avoid deep loading loops
        let postData = {
          ticker: data.ticker,
          transactionType: data.transactionType,
          quantity: data.quantity,
          transactionPricePerShare: data.transactionPricePerShare,
          transactionValue: data.transactionValue,
          dateTime: data.dateTime,
          timestamp: data.timestamp,
          isWantDelete:true
        }
        console.log('postData', postData)
  
        /**
         * Create new document in share/transactions
         * On success, add the transaction to the share object
         * with reference to original transaction
         */
        try {
          // Add the transaction to the transactions collection
          const addedTransaction = await data.shareRef
            .collection('transactions')
            .add(data)
          console.log("AddTra===>",addedTransaction);
          let getDocData = await data.shareRef.get();
          let transactions = getDocData.data().transactions;
          let newTransactions =[];
          if(transactions){
            newTransactions = transactions.map((item)=>{
              return {...item, isWantDelete:false}
            });
          }
          newTransactions.push({
            transactionRef: addedTransaction.path,
            ...postData
          });

          // Add transaction to user doc > transactions array
          // console.log("DDDDDDDD=============>",newTransactions);
          await data.shareRef
            .update({
              transactions: newTransactions
            })
            .then(() => {
              commit('SET_UPDATE_TRADEADVICE', 'addTransaction')
            })
        } catch (error) {
          console.error(error)
        }
      },

      /**
     * addTransaction
     * Add the transaction data into firebase transaction collection
     * @param {object} state
     * @param {object} transactionData
     * model: {
        ticker: ""
        transactionType: "",
        transactionPricePerShare: 0,
        quantity: 0,
        dateTime: new Date().toISOString().substr(0, 10)
      }
     */
    async deleteTransaction({ state, commit }, transactionData) {
      // Format transaction data to be stored
      let data = {
        userRef: fb.db.collection('users').doc(state.userAuth.uid),
        shareRef: fb.db
          .collection('users')
          .doc(state.userAuth.uid)
          .collection('shares')
          .doc(transactionData.transaction.ticker),
        timestamp: fb.Timestamp.fromDate(new Date()),
        ...transactionData.transaction
      }

      console.log("Data=======>", data);

      // update transaction dateTime to proper timestamp
      // let tempTime = fb.Timestamp.fromDate(new Date(data.dateTime))
      // data.dateTime = tempTime

      // // Strip doc References to avoid deep loading loops
      // let postData = {
      //   ticker: transactionData.ticker,
      //   transactionType: transactionData.transactionType,
      //   quantity: transactionData.quantity,
      //   transactionPricePerShare: transactionData.transactionPricePerShare,
      //   transactionValue: transactionData.transactionValue,
      //   dateTime: transactionData.dateTime,
      //   timestamp: transactionData.timestamp,
      //   isWantDelete:transactionData.isWantDelete
      // }
      // console.log('postData', postData)

      /**
       * Create new document in share/transactions
       * On success, add the transaction to the share object
       * with reference to original transaction
       */
      try {
        // Add the transaction to the transactions collection
          await data.shareRef
          .collection('transactions').doc(transactionData.transaction.transactionRef.split("/")[5])
          .delete()

          // var jobskill_query = data.shareRef
          // .collection('transactions').doc(transactionData.transaction.transactionRef);
          console.log("========>", transactionData.transaction.transactionRef.split("/")[5]);
          // jobskill_query.get().then(function(querySnapshot) {
          //   querySnapshot.forEach(function(doc) {
          //     doc.ref.delete();
          //   });
          // });

        // commit('SET_UPDATE_TRADEADVICE', 'deleteTransaction')
        // console.log("=====>",transactionData.updatedTrans)
        // Add transaction to user doc > transactions array
        await data.shareRef
          .update({
            transactions: transactionData.updatedTrans.filter(trans=>trans.transactionRef !== transactionData.transaction.transactionRef)
          })
          .then(() => {
            commit('SET_SELECTED_SHARE', data.shareRef.id);
            commit('SET_UPDATE_TRADEADVICE', 'deleteTransaction')
          })
      } catch (error) {
        console.error(error)
      }
    },

    /**
     * #################################################
     * ########### AUTHENTICATION ######################
     * #################################################
     */

    /**
     * Login User through firebase Auth
     * @param {object} context
     * @param {object} loginData form email and password
     * loginData = {
        email: this.email,
        password: this.password
      }
     */
    async loginUser({ commit, dispatch }, loginData) {
     
      let route = ''
      try {
        // Firebase Authenticate with email and password
        const authenticateUser = await fb.auth.signInWithEmailAndPassword(
          loginData.email,
          loginData.password
        )
        let convertString = `${loginData.email}-${loginData.password}`;
        let auth_token = encrypt(convertString);
        localStorage.setItem('auth_token', auth_token);
        // console.log("User Details=========>", authenticateUser);
        // localStorage.setItem("auth_token",authenticateUser.user.l);
        // localStorage.setItem("auth_user", JSON.stringify(authenticateUser));
        // Commit user object to the state.userAuth
        console.log("Autheeeeeeeeeeeeeeeeee", authenticateUser.user.uid);
        commit('SET_USER_AUTH', authenticateUser.user)

        // Binds AppData to state.appData
        dispatch('bindAppData')
        
        // Binds user profile to state.userProfile
        let loginUserProfile = await dispatch('bindUser', authenticateUser.user.uid);
        if (!loginUserProfile.isApproved && loginUserProfile.role != 'Admin') {
          localStorage.clear();
          dispatch('logOut');
          alert("You are not approved by admin");
        } 
        //console.log("CurrentUserProfile=========>",loginUserProfile);
        //dispatch('bindUserSingle', authenticateUser.user.uid)
        dispatch('bindAllUser')        
        dispatch('bindRoute')        


        // Bind the user shares to the state.userShares
        // Await this to happen before updating share data
        await dispatch('bindUserShares', authenticateUser.user.uid)
        // Loop through all userShares and update ShareDataRef
        // for each share with latest scraper data
        this.state.userShares.forEach((share) => {
          // console.log(share)
          dispatch('updateUserShareDataRef', share.ticker)
        })
        // Bind userShares to the state.user again to make sure
        // the latest tradeAdvice is updated
        dispatch('bindUserShares', authenticateUser.user.uid)
        // Update the route
        route = 'Home'
      } catch (error) {
        // Update route in case of error
        route = ''
        Vue.$toast.error(error.message, {
          // override the global option
          position: 'top'
        });
        // console.error('There was an error while signing in', error.code)
        // console.error(error.message)
      } finally {
        // console.info("Finally, we'll send you to: /", route)
        route != '' ? router.push({ name: route }) : null
        commit('SET_UPDATE_TRADEADVICE', true)
        // check if tradeAdvice is outdated and run Scraper if needed
        this.scrapeOutdated ? dispatch('getMarketData') : false
      }
    },


    async getUserData({ commit, dispatch }, id) {
     
      let route = ''
      try {
        // Binds AppData to state.appData
        dispatch('bindAppData')

        // Binds user profile to state.userProfile
        // dispatch('bindUser', id)
        dispatch('bindUserSingle', id)
        //dispatch('bindAllUser')
        dispatch('bindRoute')

        // Bind the user shares to the state.userShares
        // Await this to happen before updating share data
        await dispatch('bindUserShares', id)
        // Loop through all userShares and update ShareDataRef
        // for each share with latest scraper data
        this.state.userShares.forEach((share) => {
          console.log(share)
          dispatch('updateUserShareDataByUserIdRef', {shareTicker:share.ticker, userId:id})
        })
        // Bind userShares to the state.user again to make sure
        // the latest tradeAdvice is updated
        // dispatch('bindUserShares', id)
        // Update the route
        route = 'Userportfolio'
      } catch (error) {
        // Update route in case of error
        route = ''
        Vue.$toast.error(error.message, {
          // override the global option
          position: 'top'
        });
        // console.error('There was an error while signing in', error.code)
        // console.error(error.message)
      } finally {
        // console.info("Finally, we'll send you to: /", route)
        commit('SET_UPDATE_TRADEADVICE', true)
        this.scrapeOutdated ? dispatch('getMarketData') : false
        route != '' ? router.push({ name: route }) : null
        // check if tradeAdvice is outdated and run Scraper if needed
      }
    },

    async getUserDataForFilter({ commit, dispatch }, id) {
     
      // let route = ''
      try {
        // Binds AppData to state.appData
        dispatch('bindAppData')

        // Binds user profile to state.userProfile
        // dispatch('bindUser', id)
        dispatch('bindUserSingle', id)
        //dispatch('bindAllUser')
        dispatch('bindRoute')

        // Bind the user shares to the state.userShares
        // Await this to happen before updating share data
        let shareData = await dispatch('bindUserShares', id)
        console.log("========>", shareData);
        // Loop through all userShares and update ShareDataRef
        // for each share with latest scraper data
        this.state.userShares.forEach((share) => {
          //console.log(share)
          dispatch('updateUserShareDataByUserIdRef', {shareTicker:share.ticker, userId:id})
        })
        // Bind userShares to the state.user again to make sure
        // the latest tradeAdvice is updated
        // dispatch('bindUserShares', id)
        // Update the route
        // route = 'Userportfolio'
      } catch (error) {
        // Update route in case of error
        // route = ''
        Vue.$toast.error(error.message, {
          // override the global option
          position: 'top'
        });
        // return -1;
        // console.error('There was an error while signing in', error.code)
        // console.error(error.message)
      } finally {
        // console.info("Finally, we'll send you to: /", route)
        commit('SET_UPDATE_TRADEADVICE', true)
        this.scrapeOutdated ? dispatch('getMarketData') : false;
        // return userProfileData;
        // route != '' ? router.push({ name: route }) : null
        // check if tradeAdvice is outdated and run Scraper if needed
      }
    },

    /**
     * logOut
     */
    async logOut({ commit }) {
      await fb.auth.signOut()
      commit('SET_USER_AUTH', {})
      router.push({ name: 'Login' })
    },
    /**
     * updateUser
     * @param {*} context
     * @param {*} data
     */
    updateUser({ commit }, data) {
      data.user
        .updateProfile({
          displayName: 'My Display Name'
        })
        .then(() => {
          console.log('updated User Profile successfully')
        })
        .catch((err) => {
          console.log('error: ', err.message)
          this.error = err.message
        })
      commit('USER_IS_CREATED', true)
    },

    /**
     * createUser
     * @param {*} context
     * @param {*} formData
     */
    async createUser({ dispatch }, formData) {
      try {
        // create new email user in firebase
        const user = await fb.auth.createUserWithEmailAndPassword(
          formData.email,
          formData.password
        )
        dispatch('createUserProfile', { user, formData })
      } catch (error) {
        console.error(
          '🚀 ~ file: index.js ~ line 141 ~ createUser ~ error',
          error.message
        )
      }
    },

    /**
     * createUserProfile
     * @param {*} context
     * @param {*} data
     */
    async createUserProfile(context, data) {
      const { name, email, mobile, dmat } = data.formData
      try {
        await fb.db.collection('users').doc(data.user.user.uid).set({
          name,
          email,
          mobile,
          dmat,
          greedPercentage: 0,
          role:'User',
          isApproved:false
        })
        router.push({ name: 'Login' })
        // dispatch('getUserProfile', data.user.user)
      } catch (error) {
        console.log(error)
      }
    },

     /**
     * changeUser
     * @param {*} context
     * @param {*} formData
     */
     async changeUser({ dispatch }, formData) {
      try {
        fb.db.collection("users").where("email", "==", formData.email)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                console.log(doc.id, " => ", doc.data());
                fb.db.collection('users').doc(doc.id).update({
                  isApproved:formData.isApproved
                }).then().catch();
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
        // const user = await fb.auth.createUserWithEmailAndPassword(
        //   formData.email,
        //   formData.password
        // )
        // dispatch('approvedDisapprovedUser', { user, formData });
        dispatch('bindAllUser');

      } catch (error) {
        console.error(
          '🚀 ~ file: index.js ~ line 141 ~ createUser ~ error',
          error.message
        )
      }
    },
    /**
    * change user status
    */
    async approvedDisapprovedUser(context, data) {
      const { isApproved } = data.formData
      try {
        await fb.db.collection('users').doc().update({
          isApproved
        })
        // router.push({ name: 'Login' })
        //dispatch('bindAllUser')
      } catch (error) {
        console.log(error)
      }
    },
  },

  
  getters: {
    scrapeOutdated: (state) => {
      if (state.appData && state.appData.lastScrapedAt) {
        console.log(state.appData + " && "+ state.appData.lastScrapedAt);
        let lastScrapeDate = state.appData.lastScrapedAt.toDate()
        let todaysDate = new Date()
        // Set time to midnight to compare
        todaysDate.setHours(0, 0, 0, 0)
        console.log("Last Scraped Date======>",lastScrapeDate);
        return lastScrapeDate <= todaysDate
      } else {
        return 'waiting for state'
      }
    }
  },
  modules: {}
})
