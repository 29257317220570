<template>
    <v-app>
    <!-- <trade-advice /> -->
    <div class="main-cover">
	  <div class="sidebar-wrap">

		<div class="side-details">
			<router-link to="/" exact class="logo" style="text-decoration:none;">
        <div class="logo"> Perquity </div>
        </router-link>
			<ul>
        <li class="dropMenuPdiv"> 
					<div class="dropMenu sub-btn">
						<span> <img src="images/sb1.png"> </span>
						<a href="#"> Theme 1 </a>
					</div>
					<div class="dropMenuHover sub-menu">
						<ul>
              <li v-for="navItem in navItemsTheme1"
              :key="navItem.title" @click="navItem.onClick">
              <div :class="{'selectRow': showTable == navItem.title}">
                <span> <img :src="navItem.icon"> </span>
                <span> {{ navItem.title }}</span>
              </div> 
				      </li>
						</ul>
					</div>
				</li>
        <li class="dropMenuPdiv"> 
					<div class="dropMenu sub-btn">
						<span> <img src="images/sb1.png"> </span>
						<a href="#"> Theme 2 </a>
					</div>
					<div class="dropMenuHover sub-menu">
						<ul>
              <li v-for="navItem in navItemsTheme2"
              :key="navItem.title" @click="navItem.onClick"> 
              <div :class="{'selectRow': showTable == navItem.title}">
                <span> <img :src="navItem.icon"> </span>
                <span> {{ navItem.title }}</span>
              </div>
				      </li>
						</ul>
					</div>
				</li>
        <li class="dropMenuPdiv"> 
					<div class="dropMenu sub-btn">
						<span> <img src="images/sb1.png"> </span>
						<a href="#"> Theme 3 </a>
					</div>
					<div class="dropMenuHover sub-menu">
						<ul>
              <li v-for="navItem in navItemsTheme3"
              :key="navItem.title" @click="navItem.onClick"> 
              <div :class="{'selectRow': showTable == navItem.title}">
                <span> <img :src="navItem.icon"> </span>
                <span> {{ navItem.title }}</span>
              </div>
				      </li>
						</ul>
					</div>
				</li>
				<li v-for="navItem in navItems"
        :key="navItem.title" @click="navItem.onClick"> 
					<span> <img :src="navItem.icon"> </span>
					<span> {{ navItem.title }}</span>
				</li>
			</ul>
		</div>
	</div>
	<div class="main-content">
		<div class="top-header-section">
            <div class="th-left">
				
			</div>
       
			<div class="th-right"> 
				<div class="profile-menu">
					<div class="dropdown-container">
						<div class="dropdown-toggle click-dropdown">
						 <img src="images/profile.png">
						 
          <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <img src="images/arrow.svg" style="width:15px">
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(menuItem, i) in menuItems"
              :key="i"
              @click="menuItem.onClick"
            >
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
						</div>
						<!-- <div class="dropdown-menu">
							<ul>
								<li><a href="#">DropDown Menu Item 1</a></li>
								<li><a href="#">DropDown Menu Item 2</a></li>
								<li><a href="#">DropDown Menu Item 3</a></li>
								<li><a href="#">DropDown Menu Item 4</a></li>
							</ul>
						</div> -->
					</div>
				</div>
			</div>
		</div>


         <div class="th-left">
          <div class="thead"> 
            Portfolio Overview for 
            {{userProfileSingle.name}} 
          </div>
          <div class="thead" style="float:right;"> 
            <span>{{ text }}</span><b>(Step {{showTable}})</b>
          </div>
          <div class="tsubhead"> (Value in INR)</div>
                  
          </div>
      <div class="graphical-reper-wrap" style="display:none;">
              <div class="grw-1 cr-wrap">
                  <div class="chart-detail-cover">
                      <div class="cdc-left">
                          <div class="cdc-head"> current PORTFOLIO </div>
                          <div class="cdc-pie-chart">
                <apexchart
                :width="chartWidth"
                type="donut"
                :options="chartOptions"
                :series="chartOptions.series"
              ></apexchart> </div>
                      </div>
                      <div class="cdc-right">
                          <div class="right-small-head"> portfolio category </div>
                          <div class="chart-detail-grid-main">
                              <div class="chart-grid-five">
                                  <ul>
                                      <li v-for="(labels, i) in chartOptions.labels" :key="labels" :title="compositeValue[i]?compositeValue[i].avg:0"> 
                                      <span class="small-box pink-box" v-if="labels=='A'"> </span> 
                                      <span class="small-box light-red-box" v-if="labels=='B'"> </span> 
                                      <span class="small-box yellow-box" v-if="labels=='C'"> </span> 
                                      <span class="small-box blue-box" v-if="labels=='D'"> </span> 
                                      <span class="small-box green-box" v-if="labels=='E'"> </span> 
                                      {{labels}} NAME </li>
                                  </ul>
                              </div>
                              <div class="chart-grid-three">
                                  <div class="table-row1 chart-table">
                                      <h1> portfolio Quantification </h1>
                                      <ul>
                                          <li class="text-color-p" :class="{'text-color-p': labels == 'A', 'text-color-lr':labels == 'B', 'text-color-y':labels == 'C', 'text-color-b':labels == 'D', 'text-color-g':labels == 'E'}" v-for="labels in chartOptions.labels" :key="labels" > {{labels}} </li>
                                      </ul>
                                  </div>
                                  <div class="table-row2 chart-table">
                                      <h1>portfolio VALUE </h1>
                    
                                      <ul>
                                          <li :class="{'text-color-p': labels == 'A', 'text-color-lr':labels == 'B', 'text-color-y':labels == 'C', 'text-color-b':labels == 'D', 'text-color-g':labels == 'E'}" v-for="(labels, i) in chartOptions.labels" :key="labels"> {{compositeValue[i]?compositeValue[i].composite.toFixed(2):0}} </li>
                                      </ul>								
                                  </div>
                                  <div class="table-row3 chart-table">
                                      <h1> portfolio strength</h1>
                                      <ul>
                                          <li :class="{'text-color-p': labels == 'A', 'text-color-lr':labels == 'B', 'text-color-y':labels == 'C', 'text-color-b':labels == 'D', 'text-color-g':labels == 'E'}" v-for="(labels, i) in chartOptions.labels" :key="labels"> {{parseInt(chartOptions.series[i])}}%</li>
                                      </ul>								
                                  </div>
                              </div>
                          </div>
                      </div>				
                  </div>
              </div>
              <div class="flex-col3box">
                  <div class="clt-wrap grw-1" v-for="card in cards"
            v-bind:key="card.title">
                      <div class="clt-head-price">
                          <div class="clt-head">
                              <div class="clt-light-head"> {{ card.overtitle }} </div>
                              <div class="clt-bold-head"> {{ card.title }} </div>
                          </div>
                          <div class="clt-price">
                              {{ card.value }}
                          </div>
                      </div>
                  </div>
              </div>
              <div class="grw-1 roi-wrap">
                  <div class="roi-head-num-wrap">
                      <div class="roi-head">
                          PORTFOLIO ROI %
                      </div>
                      <div class="roi-num">
                          {{
                  tradeAdvice.avg_profit_loss_percentage.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
              }),
                }}
                      </div>
                  </div>
              </div>
          </div>
          
          <div class="list-high-roi" style="display:none">
              <p> List of your shares on the basis of highest ROI </p>
          </div>
          
  
        <v-row style="display:none">
          <v-col class="d-flex" cols="12" md="2">
            <v-select :items="select" label="Filter" class="ds-input"></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" md="2">
            <v-text-field
              label="Enter text here"
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              class="ds-input"
              v-model="searchInput"
            ></v-text-field>
          </v-col>
        </v-row>
       
       
        <!-- Point 1 start here -->
        <template v-if="showTable==1">
        <v-row v-for="(labels, i) in chartOptions.labels" :key="labels">
          <table class="TradeData" v-if="labels=='A'">
            <thead class="table-light-Pink">
              <tr>
                <th>Sr No</th>
                <th>Category {{labels}} Shares</th>
                <th>Less than</th>
                <th>Advice flag</th>
                <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesByCategory.allAShares" v-bind:key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>---</td>
                <td>{{compositeValue[i].avg.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">1</td>
                <td v-else>---</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='B'">
            <thead class="tablePink">
              <tr>
                <th>Sr No</th>
                <th>Category {{labels}} Shares</th>
                <th>Less than</th>
                <th>Advice flag</th>
                <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesByCategory.allBShares" :key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>---</td>
                <td>{{compositeValue[i].avg.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">1</td>
                <td v-else>---</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='C'">
            <thead class="table-light-yellow">
              <tr>
                <th>Sr No</th>
                <th>Category {{labels}} Shares</th>
                <th>Less than</th>
                <th>Advice flag</th>
                <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesByCategory.allCShares" v-bind:key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>---</td>
                <td>{{compositeValue[i].avg.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">1</td>
                <td v-else>---</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='D'">
            <thead class="table-light-blue">
              <tr>
                <th>Sr No</th>
                <th>Category {{labels}} Shares</th>
                <th>Less than</th>
                <th>Advice flag</th>
                <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesByCategory.allDShares" v-bind:key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>---</td>
                <td>{{compositeValue[i].avg.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">1</td>
                <td v-else>---</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='E'">
            <thead class="table-light-green">
              <tr>
                <th>Sr No</th>
                <th>Category {{labels}} Shares</th>
                <th>Less than</th>
                <th>Advice flag</th>
                <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesByCategory.allEShares" v-bind:key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td v-if="share.composit_purchase_value < compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value < compositeValue[i].avg">2</td>
                <td v-else>---</td>
                <td>{{compositeValue[i].avg.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">{{share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )}}</td>
                <td v-else>---</td>
                <td v-if="share.composit_purchase_value > compositeValue[i].avg">1</td>
                <td v-else>---</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 1 end here -->




        <!-- Point 2 start here -->
        <template v-if="showTable==2">
        <v-row v-for="labels in chartOptions.labels" :key="labels">
          <table class="TradeData" v-if="labels=='A'">
            <thead class="table-light-Pink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>ROI</th>
                <th>Numerical assigned</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in allSharesByCategory.allAShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  parseFloat(share.profit_loss_percentage).toFixed(2)
                }} </td>
              <td>{{share.profit_order}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='B'">
            <thead class="tablePink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>ROI</th>
                <th>Numerical assigned</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in allSharesByCategory.allBShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  parseFloat(share.profit_loss_percentage).toFixed(2) 
                }} </td>
                <td>{{share.profit_order}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='C'">
            <thead class="table-light-yellow">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>ROI</th>
                <th>Numerical assigned</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in allSharesByCategory.allCShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  parseFloat(share.profit_loss_percentage).toFixed(2)
                }} </td>
                <td>{{share.profit_order}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='D'">
            <thead class="table-light-blue">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>ROI</th>
                <th>Numerical assigned</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in allSharesByCategory.allDShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  parseFloat(share.profit_loss_percentage).toFixed(2)
                }} </td>
                <td>{{share.profit_order}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='E'">
            <thead class="table-light-green">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>ROI</th>
                <th>Numerical assigned</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in allSharesByCategory.allEShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  parseFloat(share.profit_loss_percentage).toFixed(2)
                }} </td>
                <td>{{share.profit_order}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 2 End Here -->



        <!-- Point 3 start here -->
        <template v-if="showTable==3">
        <v-row v-for="(labels, i) in chartOptions.labels" :key="labels">
          <table class="TradeData" v-if="labels=='A'">
            <thead class="table-light-Pink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Serial number</th>
                <th>Advice Flag</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allAShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{j+1}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='B'">
            <thead class="tablePink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Serial number</th>
                <th>Advice Flag</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allBShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{j+1}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='C'">
            <thead class="table-light-yellow">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Serial number</th>
                <th>Advice Flag</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allCShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{j+1}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='D'">
            <thead class="table-light-blue">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Serial number</th>
                <th>Advice Flag</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allDShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{j+1}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='E'">
            <thead class="table-light-green">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Serial number</th>
                <th>Advice Flag</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allEShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{j+1}}</td>
                <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 3 End Here -->


        <!-- Point 4 start here -->
        <template v-if="showTable==4">
        <v-row v-for="labels in chartOptions.labels" :key="labels">
          <table class="TradeData" v-if="labels=='A' && allSharesBy3Category.allA4Shares.length > 0">
            <thead class="table-light-Pink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Serial number</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allA4Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                
                <td>{{j+1}}</td>
                
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='B' && allSharesBy3Category.allB4Shares.length > 0">
            <thead class="tablePink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Serial number</th>
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allB4Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>{{j+1}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='C' && allSharesBy3Category.allC4Shares.length > 0">
            <thead class="table-light-yellow">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Serial number</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allC4Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>{{j+1}}</td>
                
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='D' && allSharesBy3Category.allD4Shares.length > 0">
            <thead class="table-light-blue">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Serial number</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allD4Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>{{j+1}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='E' && allSharesBy3Category.allE4Shares.length > 0">
            <thead class="table-light-green">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Serial number</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allE4Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>{{j+1}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 4 End Here -->


        <!-- Point 5 start here -->
        <template v-if="showTable==5">
        <v-row v-for="labels in chartOptions.labels" :key="labels">
          <table class="TradeData" v-if="labels=='A' && allSharesBy3Category.allA5Shares.length > 0">
            <thead class="table-light-Pink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Numerical Value</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allA5Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>50{{j+1}}</td>
                <!-- <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td> -->
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='B' && allSharesBy3Category.allB5Shares.length > 0">
            <thead class="tablePink">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Numerical Value</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allB5Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>40{{j+1}}</td>
                
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='C' && allSharesBy3Category.allC5Shares.length > 0">
            <thead class="table-light-yellow">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Numerical Value</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allC5Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>30{{j+1}}</td>
                <!-- <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td> -->
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='D' && allSharesBy3Category.allD5Shares.length > 0">
            <thead class="table-light-blue">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Numerical Value</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allD5Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>20{{j+1}}</td>
                <!-- <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td> -->
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='E' && allSharesBy3Category.allE5Shares.length > 0">
            <thead class="table-light-green">
              <tr>
                <th>Category {{labels}} Shares</th>
                <th>Composite purchase value</th>
                <th>Advice Flag</th>
                <th>Numerical Value</th>
                
                <!-- <th>Avg inv for Cat {{labels}}</th>
                <th>Greater than</th>
                <th>Advice flag</th> -->
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allE5Shares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{
                  share.composit_purchase_value.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }} </td>
                <td>{{share.trade_advice}}</td>
                <td>10{{j+1}}</td>
                <!-- <td v-if="share.composit_purchase_value <= compositeValue[i].avg">2</td>
                <td v-else>1</td> -->
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 5 End Here -->


        <!-- Point 6 start here -->
        <template v-if="showTable==6">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Sr No</th>
                <th>Share(Sample set)</th>
                <th>Year High</th>
                <th>Year Low</th>
                <th>Year Avg</th>
                <th>Market Rate</th>
                <th>Median Mode</th>
                <th>Avg Risk Factor</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in filteredShares" :key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td>{{
                  share.marketDataRef.year_high.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>
                <td>{{share.marketDataRef.year_low.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>


                <td>{{share.yearly_average.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                <td>{{
                  share.marketDataRef.last_traded_price.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }}</td>
                <td>{{share.mean_mode.toFixed(2)}}</td>
                <td>{{share.avg_distance}}</td>
               </tr>
            </tbody>
          </table>
          <!-- <table class="TradeData" v-if="labels=='B'">
            <thead class="tablePink">
              <tr>
                <th>Sr No</th>
                <th>Share(Sample set)</th>
                <th>Year High</th>
                <th>Year Low</th>
                <th>Year Avg</th>
                <th>Market Rate</th>
                <th>Median Mode</th>
                <th>Avg Risk Factor</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allB5Shares" :key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td>{{
                  share.marketDataRef.year_high.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>
                <td>{{share.marketDataRef.year_low.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>


                <td>{{share.yearly_average.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                <td>{{
                  share.marketDataRef.last_traded_price.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }}</td>
                <td>{{share.mean_mode.toFixed(2)}}</td>
                <td>{{share.avg_distance}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='C'">
            <thead class="table-light-yellow">
              <tr>
                <th>Sr No</th>
                <th>Share(Sample set)</th>
                <th>Year High</th>
                <th>Year Low</th>
                <th>Year Avg</th>
                <th>Market Rate</th>
                <th>Median Mode</th>
                <th>Avg Risk Factor</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allC5Shares" :key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td>{{
                  share.marketDataRef.year_high.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>
                <td>{{share.marketDataRef.year_low.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>


                <td>{{share.yearly_average.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                <td>{{
                  share.marketDataRef.last_traded_price.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }}</td>
                <td>{{share.mean_mode.toFixed(2)}}</td>
                <td>{{share.avg_distance}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='D'">
            <thead class="table-light-blue">
              <tr>
                <th>Sr No</th>
                <th>Share(Sample set)</th>
                <th>Year High</th>
                <th>Year Low</th>
                <th>Year Avg</th>
                <th>Market Rate</th>
                <th>Median Mode</th>
                <th>Avg Risk Factor</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allD5Shares" :key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td>{{
                  share.marketDataRef.year_high.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>
                <td>{{share.marketDataRef.year_low.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>


                <td>{{share.yearly_average.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                <td>{{
                  share.marketDataRef.last_traded_price.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }}</td>
                <td>{{share.mean_mode.toFixed(2)}}</td>
                <td>{{share.avg_distance}}</td>
               </tr>
            </tbody>
          </table>
          <table class="TradeData" v-if="labels=='E'">
            <thead class="table-light-green">
              <tr>
                <th>Sr No</th>
                <th>Share(Sample set)</th>
                <th>Year High</th>
                <th>Year Low</th>
                <th>Year Avg</th>
                <th>Market Rate</th>
                <th>Median Mode</th>
                <th>Avg Risk Factor</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="(share, j) in allSharesBy3Category.allE5Shares" :key="share.shareId">
                <td>{{j+1}}</td>
                <td>{{share.company_name}}</td>
                <td>{{
                  share.marketDataRef.year_high.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>
                <td>{{share.marketDataRef.year_low.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>


                <td>{{share.yearly_average.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                <td>{{
                  share.marketDataRef.last_traded_price.toLocaleString(
                    'en-IN',
                    {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }
                  )
                }}</td>
                <td>{{share.mean_mode.toFixed(2)}}</td>
                <td>{{share.avg_distance}}</td>
               </tr>
            </tbody>
          </table> -->
        </v-row>
        </template>
        <!-- Point 6 End Here -->

        <!-- Point 7 start here -->
        <template v-if="showTable==7">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Name of script</th>
                <th>S1</th>
                <th>S5</th>
                <th>C3</th>
                <th>S2</th>
                <th>S6</th>
                <th>C1</th>
                <th>Numerical</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.s1.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                
                <td>{{share.s5.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                <td>{{share.c3.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>

                  <td>{{share.s2.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                
                <td>{{share.s6.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>

                <td>{{
                  share.c1.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>
                <td>{{share.numerical}}</td>
                
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 7 End Here -->

        
        <!-- Point 8 start here -->
        <template v-if="showTable==8">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Name of script</th>
                <th>N1</th>
                <th>N5</th>
                <th>C4</th>
                <th>N2</th>
                <th>N6</th>
                <th>C2</th>
                <th>Numerical</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.n1.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                  <td>{{share.n5.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                
                <td>{{share.c4.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>

                <td>{{share.n2.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'INR'
                })}}</td>
                
                <td>{{share.n6.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })}}</td>
                  <td>{{
                  share.c2.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }} </td>


                <td>{{share.numericalTrade}}</td>
                
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 8 End Here -->

        <!-- Point 9 start here -->
        <template v-if="showTable==9">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Special Announcement</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.special_announcement}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 9 End Here -->

        <!-- Point 10 start here -->
        <template v-if="showTable==10">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Future</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.future}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 10 End Here -->

        <!-- Point 11 start here -->
        <template v-if="showTable==11">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Final Numerical Value</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.final_numerical_value}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 11 End Here -->

        <!-- Point 12 start here -->
        <template v-if="showTable==12">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Last Purchase Value (LPV)</th>
                <th>Last Sold Value (LSV)</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.last_transaction_type=="Buy"?share.last_transaction_trade_price:"---"}}</td>
                <td>{{share.last_transaction_type=="Sell"?share.last_transaction_trade_price:"---"}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 12 End Here -->

        <!-- Point 13 start here -->
        <template v-if="showTable==13">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Lower Band</th>
                <th>Upper Band</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.lower_band ? share.lower_band.toFixed(2) : share.trading_rates.buy.min.toFixed(2)}}</td>
                <td>{{share.upper_band.toFixed(2)}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 13 End Here -->

        <!-- Point 14 start here -->
        <template v-if="showTable==14">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Final Numerical Value</th>
                <th>Sell Candidates</th>
                <th>Priority</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.final_numerical_value}}</td>
                <td>{{share.trade_recommendation == "Sell"? share.final_numerical_value:"--"}}</td>
                <td>{{share.trade_recommendation == "Sell"? share.priority_order_sell:""}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 14 End Here -->

        <!-- Point 15 start here -->
        <template v-if="showTable==15">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Final Numerical Value</th>
                <th>Purchase Candidates</th>
                <th>Priority</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.final_numerical_value}}</td>
                <td>{{share.trade_recommendation == "Buy"? share.final_numerical_value:"--"}}</td>
                <td>{{share.trade_recommendation == "Buy"? share.priority_order_buy:""}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 15 End Here -->

        <!-- Point 16 start here -->
        <template v-if="showTable==16">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Purchase candidates</th>
                <th>Lower Band</th>
                <th>Upper Band</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.trade_recommendation == "Buy"? share.final_numerical_value:"--"}}</td>
                <td>{{share.trade_recommendation == "Buy"? share.lower_band ? share.lower_band.toFixed(2) : share.trading_rates.buy.min.toFixed(2):"--"}}</td>
                <td>{{share.trade_recommendation == "Buy"? share.upper_band.toFixed(2):"--"}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 16 End Here -->

        <!-- Point 17 start here -->
        <template v-if="showTable==17">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Sell candidates</th>
                <th>Upper Band</th>
                <th>Year High</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.trade_recommendation == "Sell"? share.final_numerical_value:"--"}}</td>
                <td>{{share.trade_recommendation == "Sell"? share.upper_band.toFixed(2):"--"}}</td>
                <td>{{share.trade_recommendation == "Sell"? share.marketDataRef?share.marketDataRef.year_high.toFixed(2):0:"--"}}</td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 17 End Here -->

        <!-- Point 18 start here -->
        <template v-if="showTable==18">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share</th>
                <th>Priority</th>
                <th>Purchase list</th>
                <th>Last buy</th>
                <th>Last Sold</th>
                <th>Lower Band</th>
                <th>Upper Band</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.trade_recommendation == "Buy"? share.priority_order_buy:"---"}}</td>
                <td>
                  {{share.trade_recommendation == "Buy"? share.final_numerical_value:"---"}}
                </td>
                <td>
                  {{share.last_transaction_type=="Buy" && share.trade_recommendation == "Buy"?share.last_transaction_trade_price:"---"}}
                </td>
                <td>
                  {{share.last_transaction_type=="Sell" && share.trade_recommendation == "Buy"?share.last_transaction_trade_price:"---"}}
                </td>
                <td>

                  <span v-if="share.trade_recommendation == 'Buy'" v-bind:class="{ highlight: share.last_transaction_type=='Sell' }">
                    {{share.lower_band.toFixed(2)}}
                  </span>
                  <span v-else>
                    ---
                  </span>
                </td>
                <td>
                  <span v-if="share.trade_recommendation == 'Buy'" v-bind:class="{ highlight: share.last_transaction_type=='Buy' }">
                    {{share.upper_band.toFixed(2)}}
                  </span>
                  <span v-else>
                    ---
                  </span>
                  
                </td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 18 End Here -->

        <!-- Point 19 start here -->
        <template v-if="showTable==19">
        <v-row>
          <table class="TradeData">
            <thead class="table-light-Pink">
              <tr>
                <th>Share name on sell list</th>
                <th>Priority</th>
                <th>Target</th>
                <th>Year High</th>
              </tr>
            </thead>
            <tbody>
               <tr v-for="share in filteredShares" :key="share.shareId">
                <td>{{share.company_name}}</td>
                <td>{{share.trade_recommendation == "Sell"? share.priority_order_sell:"---"}}</td>
                <td>
                  <span v-if="share.trade_recommendation == 'Sell'" v-bind:class="{ highlight: share.upper_band > share.marketDataRef.year_high }">
                  {{ share.upper_band.toFixed(2)}}
                </span>
                <span v-else>
                  --
                </span>
              </td>
                <td>
                  <span v-if="share.trade_recommendation == 'Sell'" v-bind:class="{ highlight: share.upper_band < share.marketDataRef.year_high }">
                  {{share.marketDataRef.year_high.toFixed(2)}}
                  </span>
                  <span v-else>
                    --
                  </span>
                </td>
               </tr>
            </tbody>
          </table>
        </v-row>
        </template>
        <!-- Point 19 End Here -->



    
          <template>
          <share-card
          v-for="share in filteredShares"
          v-bind:key="share.shareId"
          :share="share"
          style="display:none"
          />
        </template>
		
	</div>
  </div>   

  
    </v-app>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex'
  import { auth } from '@/db'
  // import AddTransaction from '../components/AddTransaction.vue'
  // import AddShare from '../components/AddShare.vue'
  import ShareCard from '../components/ShareCard'
  // import DashboardCard from '../components/DashboardCard'
  // import AddProfitRatio from '../components/AddProfitRatio'
  // import TradeAdvice from '../components/TradeAdvice.vue'
  import VueApexCharts from 'vue-apexcharts'
  import Vue from 'vue'
  import router from '../router'
  // import TradeAdvice from '../components/TradeAdvice.vue'
  // import func from 'vue-editor-bridge'
  // import _ from "lodash";
  Vue.use(VueApexCharts)
  
  Vue.component('apexchart', VueApexCharts)
  // import { get } from "vuex-pathify";
  // import Vue from "vue";
  export default {
    components: {
      // AddTransaction,
      // AddShare,
      ShareCard,
      // DashboardCard,
      // AddProfitRatio
      // TradeAdvice
    },
    name: 'Usertables',
    data() {
      return {
        searchInput: '',
  
        select: ['Item1', 'Item2', 'Item3'],
        // selectedShareRowTransactions: [],
        selectedShareObj: {}, // share data from click event
  
        currentUser:auth.currentUser,
        menuItems: [{ title: 'Logout', onClick: this.logOut }],
        navItems: [
        {
          title: 'Exit',
          icon: 'images/sb4.png',
          onClick: () => {
            router.push({ name: 'Userportfolio' })
          }
          // img: './assets/Plus.svg'
        },
      ],

      navItemsTheme1: [
        {
          title: '1',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            this.showTable=1;
            this.text="Categorization and advice flag allotment ";
          }
        },
        {
          title: '2',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=2;
            this.text="Intra-category number assignment based on ROI ";
          }
        },
        {
          title: '3',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=3;
            this.text="Intra-category serial number based on total Investment ";
          }
        },
        {
          title: '4',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=4;
            this.text="Serialize Advice Flag 1 ";
          }
          // img: './assets/Plus.svg'
        },
        {
          title: '5',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=5;
            this.text="Number allotment for Advice Flag 2 ";
          }
        },
      ],

      navItemsTheme2: [
        {
          title: '6',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=6;
            this.text="Calculate average risk factor ";
          }
         
        },
        {
          title: '7',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            this.showTable=7;
            this.text="Number assignment based on Quarterly Sales results ";
          }
          // img: './assets/logo.png'
        },
        {
          title: '8',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            this.showTable=8;
            this.text="Number assignment based on Quarterly Net Profit ";
          }
          // img: './assets/logo.png'
        },
        {
          title: '9',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            this.showTable=9;
            this.text="Special announcement checklist ";
          }
          // img: './assets/logo.png'
        },
        {
          title: '10',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            this.showTable=10;
            this.text="Sectorial future checklist ";
          }
          // img: './assets/logo.png'
        },
      ],
      navItemsTheme3: [
        {
          title: '11',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=11;
            this.text="Summation of all numerical values ";
          }
         
        },
        {
          title: '12',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=12;
            this.text="Last Transaction Details ";
          }
         
        },
        {
          title: '13',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=13;
            this.text="Lower and upper band based on profit ratio ";
          }
         
        },
        {
          title: '14',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=14;
            this.text="Prioritized Sell list ";
          }
         
        },
        {
          title: '15',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=15;
            this.text="Prioritized Buy list ";
          }
         
        },
        {
          title: '16',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=16;
            this.text="Buy list with lower and upper band ";
          }
         
        },
        {
          title: '17',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=17;
            this.text="Sell list with Upper Band (Target) and year high ";
          }
         
        },
        {
          title: '18',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=18;
            this.text="Highlight Buy candidates ";
          }
         
        },
        {
          title: '19',
          icon: 'images/sb4.png',
          onClick: () => {
            this.showTable=19;
            this.text="Step 19 Highlight Sell candidates ";
          }
         
        },
      ],
      showTable:1,
      text:"Categorization and advice flag allotment "
      }
    },
    methods: {
      logOut() {
        localStorage.clear();
        this.$store.dispatch('logOut')
       },
      // handles click:row events for User Shares data table
      // Automatically receives the event as a param
      rowSelected(event) {
        // console.log(
        //   "🚀 ~ file: Home.vue ~ line 95 ~ rowSelected ~ event",
        //   event.item
        // );
        this.selectedShareObj = event.item
        // on click of share row, load trasactions based on share id
        // shareId is received through the event.
        this.selectedShareRow(event.item.ticker)
        // this.getTransactions(event.item.shareId);
        // event.item.transactions
        //   ? (this.selectedShareRowTransactions = event.item.transactions)
        //   : [];
      },
      // handles submit for add transaction form
      // validate() {
      //   this.$refs.form.validate
      //     ? this.addTransaction()
      //     : console.log("validation error");
      // },
      // // send the add transaction form data to store
      // addTransaction() {
      //   let transactionData = this.form.model;
      //   this.$store.dispatch("addTransaction", this.form.model);
      //   console.log(
      //     "🚀 ~ file: Home.vue ~ line 136 ~ addTransaction ~ transactionData",
      //     transactionData
      //   );
      // },
      ...mapActions([
        'getTransactions',
        'selectedShareRow',
        'toggleAddTransactionForm',
        'toggleAddShareForm'
      ]),
  
      findOcc(arr, key){
        //let arr2 = [];
        let sum=0;
        let i=0;
        if(arr) {
          arr.forEach((x)=>{
           if (x.share_category == key) {
            sum = sum + x.composit_purchase_value;
            i++;
           }
          })
        } 
        return {key:key, composite:sum, avg:sum/i}
      },
      // showOne() {
        
      // },
      // showtwo() {
      //   this.one=false;
      //   this.two=true;
      // }
    },
    computed: {
      ...mapState([
        'userShares',
        'shareTransactions',
        'selectedShare',
        'transactionFormIsVisible',
        'shareFormIsVisible',
        'tradeAdvice',
        'activeDialog',
        'tradeAdviceGenerated',
        'userProfileSingle',
        'userProfile',
        'allUsers',
      ]),
      cards: function () {
        return [
          {
            overtitle: 'COMPOSITE',
            title: 'PURCHASE VALUE',
            value:
              this.tradeAdvice.all_shares_composit_purchase_value.toLocaleString(
                'en-IN',
                {
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'INR'
                }
              ),
            // Math.round(
            //   this.tradeAdvice.all_shares_composit_purchase_value * 100
            // ) / 100,
            icon: 'mdi-info',
            text: 'VALUE IN INR',
            info: "Composite cost of capital represents a company's cost to finance its business as determined by its weighted average cost of capital (WACC)."
          },
          {
            overtitle: 'LAST',
            title: 'MARKET VALUE',
            value:
              // Math.round(this.tradeAdvice.all_shares_market_value * 100) / 100,
              this.tradeAdvice.all_shares_market_value.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }),
            icon: 'mdi-info',
            text: 'VALUE IN INR',
            info: 'Info'
          },
          {
            overtitle: 'TOTAL',
            title: 'GAIN / LOSS',
            value:
              // Math.round(this.tradeAdvice.total_profit_loss * 100) / 100,
              this.tradeAdvice.total_profit_loss.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }),
            icon: 'mdi-info',
            text: 'VALUE IN INR',
            info: 'Info'
          }
        ]
      },
      chartOptions: function () {
        let graphData = {
          series: [],
          labels: [],
          colors: [],
          compositeValues:[],
          legend: {
            show: false
          },
          dataLabels: {
            enabled: false
          }
        }
       // '#FF70A0', '#EA6206', '#FCB008', '#6798FC', '#418B7F'
        for (const cat in this.tradeAdvice.category_values) {
          // console.log("cattt=======>",String(cat));
          // console.log("Percentage=======>",this.tradeAdvice.category_values[cat].percentage);
          graphData.labels.push(String(cat))
          if (String(cat)=='A' && !graphData.colors.includes('#FF70A0')) {
            graphData.colors.push('#FF70A0');
          }
          if (String(cat)=='B' && !graphData.colors.includes('#EA6206')) {
            graphData.colors.push('#EA6206');
          }
          if (String(cat)=='C' && !graphData.colors.includes('#FCB008')) {
            graphData.colors.push('#FCB008');
          }
          if (String(cat)=='D' && !graphData.colors.includes('#6798FC')) {
            graphData.colors.push('#6798FC');
          }
          if (String(cat)=='E' && !graphData.colors.includes('#418B7F')) {
            graphData.colors.push('#418B7F');
          }
          graphData.series.push(this.tradeAdvice.category_values[cat].percentage)
        }
        console.log("GraphData=========>",graphData)
        return graphData
      },
      computedTradeAdvice: function () {
        if (this.tradeAdvice != '') {
          return this.tradeAdvice
        } else {
          return []
        }
      },
      filteredShares: function () {
        console.log('Trade Advice Shares: ', this.tradeAdvice?.shares)
        console.log('User Profileeeeeeeee: ', this.userProfile)
        console.log('All Users===========>: ', this.allUsers)
        //this.findOcc(this.filteredShares, 'A')
        if (this.tradeAdvice.shares) {
          let shares = this.tradeAdvice.shares.filter((share) => {
            return (
              share.company_name
                .toUpperCase()
                .includes(this.searchInput.toUpperCase()) ||
              share.ticker.toUpperCase().includes(this.searchInput.toUpperCase())
            )
          })
          console.log(shares)
          shares.sort((a, b) => {
            if (a.profit_loss_percentage < b.profit_loss_percentage) {
              return 1
            }
            if (a.profit_loss_percentage > b.profit_loss_percentage) {
              return -1
            } else {
              return 0
            }
          })
          // shares.map((singleShare)=>{
          //   if(singleShare.composit_purchase_value <= this.findOcc(shares, singleShare.share_category).avg) {
          //     singleShare.trade_advice = 2;
          //   } else {
          //     singleShare.trade_advice = 1;
          //   }
          // })
          console.log('Filtered Shares: ', shares);
          return shares
        } else {
          return null
        }
      },
      allSharesByCategory: function() {
        let allAShares=[];
        let allBShares=[];
        let allCShares=[];
        let allDShares=[];
        let allEShares=[];
        
        if (this.tradeAdvice.shares) {
          let shares = this.tradeAdvice.shares.filter((share) => {
            return (
              share.company_name
                .toUpperCase()
                .includes(this.searchInput.toUpperCase()) ||
              share.ticker.toUpperCase().includes(this.searchInput.toUpperCase())
            )
          })
          console.log(shares)
          shares.sort((a, b) => {
            if (a.profit_loss_percentage < b.profit_loss_percentage) {
              return 1
            }
            if (a.profit_loss_percentage > b.profit_loss_percentage) {
              return -1
            } else {
              return 0
            }
          })
          console.log('Filtered Shares: ', shares);
          for (let i=0;i<shares.length;i++) {
            if (shares[i].share_category == 'A') {
              allAShares.push(shares[i]);
            } 
            else if (shares[i].share_category == 'B') {
              allBShares.push(shares[i]);
            }
            else if (shares[i].share_category == 'C') {
              allCShares.push(shares[i]);
            }
            else if (shares[i].share_category == 'D') {
              allDShares.push(shares[i]);
            }
            else if (shares[i].share_category == 'E') {
              allEShares.push(shares[i]);
            }
          }
          return {allAShares:allAShares, allBShares:allBShares, allCShares: allCShares, allDShares:allDShares, allEShares: allEShares}
        } else {
          return null
        }
      },
      allSharesBy3Category: function() {
        let allAShares=[];
        let allBShares=[];
        let allCShares=[];
        let allDShares=[];
        let allEShares=[];

        //for point 4
        let allA4Shares=[];
        let allB4Shares=[];
        let allC4Shares=[];
        let allD4Shares=[];
        let allE4Shares=[];

        //for point 5
        let allA5Shares=[];
        let allB5Shares=[];
        let allC5Shares=[];
        let allD5Shares=[];
        let allE5Shares=[];
        
        //console.log("Hiiiiiiiiiiiii=====>",this.findOcc(this.filteredShares, 'C').composite);

        if (this.tradeAdvice.shares) {
          let shares = this.tradeAdvice.shares.filter((share) => {
            return (
              share.company_name
                .toUpperCase()
                .includes(this.searchInput.toUpperCase()) ||
              share.ticker.toUpperCase().includes(this.searchInput.toUpperCase())
            )
          })
          console.log(shares)
          shares.sort((a, b) => {
            if (a.composit_purchase_value < b.composit_purchase_value) {
              return 1
            }
            if (a.composit_purchase_value > b.composit_purchase_value) {
              return -1
            } else {
              return 0
            }
          })
         // console.log('Filtered Shares: ', shares);
          for (let i=0;i<shares.length;i++) {
            if (shares[i].share_category == 'A') {
              allAShares.push(shares[i]);
              if (this.findOcc(this.filteredShares, 'A').avg < shares[i].composit_purchase_value){
                allA4Shares.push(shares[i]);
              } else {
                allA5Shares.push(shares[i]);
              }
            } 
            else if (shares[i].share_category == 'B') {
              allBShares.push(shares[i]);
              if (this.findOcc(this.filteredShares, 'B').avg < shares[i].composit_purchase_value){
                allB4Shares.push(shares[i]);
              } else {
                allB5Shares.push(shares[i]);
              }
            }
            else if (shares[i].share_category == 'C') {
              allCShares.push(shares[i]);
              if (this.findOcc(this.filteredShares, 'C').avg < shares[i].composit_purchase_value){
                allC4Shares.push(shares[i]);
              } else {
                allC5Shares.push(shares[i]);
              }
            }
            else if (shares[i].share_category == 'D') {
              allDShares.push(shares[i]);
              if (this.findOcc(this.filteredShares, 'D').avg < shares[i].composit_purchase_value){
                allD4Shares.push(shares[i]);
              } else {
                allD5Shares.push(shares[i]);
              }
            }
            else if (shares[i].share_category == 'E') {
              allEShares.push(shares[i]);
              if (this.findOcc(this.filteredShares, 'E').avg < shares[i].composit_purchase_value){
                allE4Shares.push(shares[i]);
              } else {
                allE5Shares.push(shares[i]);
              }
            }
          }
          return {
            allAShares:allAShares, 
            allBShares:allBShares, 
            allCShares: allCShares, 
            allDShares:allDShares, 
            allEShares: allEShares,
            allA4Shares:allA4Shares, 
            allB4Shares:allB4Shares, 
            allC4Shares: allC4Shares, 
            allD4Shares:allD4Shares, 
            allE4Shares: allE4Shares,
            allA5Shares:allA5Shares, 
            allB5Shares:allB5Shares, 
            allC5Shares: allC5Shares, 
            allD5Shares:allD5Shares, 
            allE5Shares: allE5Shares,
          }
        } else {
          return null
        }
      },
      compositeValue:function() {
        let compositeValues = [];
        // if (this.findOcc(this.filteredShares, 'A').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'A'));
        // }
        // if (this.findOcc(this.filteredShares, 'B').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'B'));
        // }
        // if (this.findOcc(this.filteredShares, 'C').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'C'));
        // }
        // if (this.findOcc(this.filteredShares, 'D').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'D'));
        // }
        // if (this.findOcc(this.filteredShares, 'E').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'E'));
        // }
        if (!isNaN(this.findOcc(this.filteredShares, 'A').avg)) {
          compositeValues.push(this.findOcc(this.filteredShares, 'A'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'B').avg)) {
          compositeValues.push(this.findOcc(this.filteredShares, 'B'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'C').avg)) {
          compositeValues.push(this.findOcc(this.filteredShares, 'C'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'D').avg)) {
          compositeValues.push(this.findOcc(this.filteredShares, 'D'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'E').avg)) {
          compositeValues.push(this.findOcc(this.filteredShares, 'E'));
        }
        // compositeValues.push(this.findOcc(this.filteredShares, 'A'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'B'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'C'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'D'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'E'));
        console.log("ADDDDDDEEEEEEEEEEEE============>", compositeValues);
        return compositeValues;
      },
  
      chartWidth() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 380
          case 'sm':
            return 380
          case 'md':
            return 240
          case 'lg':
            return 240
          case 'xl':
            return 380
          default:
            return 380
        }
      }
    },
    mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "/js/script.js"
    );
    document.body.appendChild(recaptchaScript);
   },
  }
  </script>
  <style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .side-details {
    overflow-y: scroll;
    height: 700px;
  }
  .side-details ul li.dropMenuPdiv 
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	
	.dropMenuHover.openMenu ul li
	{
		border-bottom: unset !important;
	}
	
	.dropMenuHover.openMenu ul li a 
	{
		color: #fff;
		text-decoration: none;
	}
	
	.dropMenu
	{
		display: flex;
		align-items: center;
		grid-column-gap: 10px;	
	}
	
	.dropMenu a
	{
		color:#fff;
		text-decoration:none;
	}
	
	.sub-menu
	{
		display: none;
	}
	
	.dropMenuHover.sub-menu ul
	{
		margin-left: 50px;
		margin-top: 10px;
	}
	
	.dropMenuHover.sub-menu ul li
	{
	    border-bottom: unset;
	}
	
	.dropMenuHover.sub-menu ul li a
	{
		color:#fff;
		text-decoration:none;
	}
  .ds-card-dashboard {
    @include ds-elevation_2;
    border-radius: 8px !important;
    padding: 1rem;
    &.ds-roi {
      background-color: $colors-brand-primary;
      color: $colors-brand-neutral_light-7;
      .ds-card-title {
        @include secondary_paragraph_1_regular;
        color: $colors-brand-neutral_light-7;
        text-align: center;
        margin-top: 25%;
      }
      .ds-card-value {
        @include num_display_large_bold;
        justify-content: center !important;
        color: $colors-brand-neutral_light-7;
        padding: 0.5rem !important;
      }
    }
  }
  .ds-header {
    margin-top: 4.68rem;
  }
  .heading-2 {
    @include heading_2_regular;
    line-height: 2.5rem !important;
  }
  .ds-input::v-deep .v-input__slot::before {
    border-color: $colors-brand-primary !important;
  }
  .ds-input::v-deep label {
    color: $colors-brand-primary !important;
    @include body_4;
  }
  .ds-input::v-deep .v-icon {
    color: $colors-brand-primary !important;
  }
  .ds-card-text {
    @include small-caps_3_regular;
  }
  .ds-card-subtitle {
    @include small-caps_2_medium;
  }
  .ds-card-value {
    @include num_display_small_bold;
    justify-content: center;
    color: $colors-brand-primary;
    padding: 1rem 0rem !important;
    font-size: 1.0625rem;
    line-height: 1.5rem;
  }
  .ds-card-icon {
    color: $colors-brand-primary;
  }
  .ds-card-dashboard::v-deep .v-divider--inset {
    margin-left: 40% !important;
  }
  .v-divider {
    border-bottom: solid 0.0625rem $colors-brand-primary;
    max-width: 1.75rem;
  }
  .apexcharts-legend .apexcharts-align-center .position-right {
    display: none !important;
  }
  .side-details ul li {
    cursor: pointer;
  }


.TradeData {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 40px;
}

.TradeData td, .TradeData th {
  border: 1px solid #ddd;
  padding: 8px;
}

.TradeData tr:nth-child(even){background-color: #f2f2f2;}

.TradeData tr:hover {background-color: #ddd;}

.TradeData th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  //background-color: #04AA6D;
  //color: white;
}
.TradeData td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

.selectRow {
  background: #000000;
  width:100%;
}

.highlight {
  background-color: yellow;
}

  </style>
  