<template>
  <svg
    width="48"
    height="44"
    viewBox="0 0 48 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9775 43H19.0141C17.5695 43 16.3937 41.8242 16.3937 40.3797V27.5049C16.3937 27.001 15.9906 26.5979 15.4867 26.5979H2.62031C1.17578 26.5979 0 25.4221 0 23.9775V19.0141C0 17.5695 1.17578 16.3937 2.62031 16.3937H15.4951C15.999 16.3937 16.4021 15.9906 16.4021 15.4867V2.62031C16.4021 1.17578 17.5779 0 19.0225 0H23.9859C25.4305 0 26.6062 1.17578 26.6062 2.62031V15.4951C26.6062 15.999 27.0094 16.4021 27.5133 16.4021H40.3881C41.8326 16.4021 43.0084 17.5779 43.0084 19.0225V23.9859C43.0084 25.4305 41.8326 26.6062 40.3881 26.6062H27.5049C27.001 26.6062 26.5979 27.0094 26.5979 27.5133V40.3881C26.5979 41.8242 25.4221 43 23.9775 43ZM2.62031 18.1154C2.1248 18.1154 1.71328 18.5186 1.71328 19.0225V23.9859C1.71328 24.4898 2.11641 24.893 2.62031 24.893H15.4951C16.9396 24.893 18.1154 26.0688 18.1154 27.5133V40.3881C18.1154 40.892 18.5186 41.2951 19.0225 41.2951H23.9859C24.4898 41.2951 24.893 40.892 24.893 40.3881V27.5049C24.893 26.0604 26.0688 24.8846 27.5133 24.8846H40.3881C40.892 24.8846 41.2951 24.4814 41.2951 23.9775V19.0141C41.2951 18.5186 40.892 18.107 40.3881 18.107H27.5049C26.0604 18.107 24.8846 16.9312 24.8846 15.4867V2.62031C24.8846 2.1248 24.4814 1.71328 23.9775 1.71328H19.0141C18.5186 1.71328 18.107 2.11641 18.107 2.62031V15.4951C18.107 16.9396 16.9312 18.1154 15.4867 18.1154H2.62031Z"
      fill="#765AC8"
    />
    <path
      d="M39.6573 20.6435H31.6032C31.1329 20.6435 30.7466 20.2571 30.7466 19.7868C30.7466 19.3165 31.1329 18.9302 31.6032 18.9302H39.6573C40.1276 18.9302 40.514 19.3165 40.514 19.7868C40.514 20.2571 40.136 20.6435 39.6573 20.6435Z"
      fill="#765AC8"
    />
    <path
      d="M29.0417 20.6435H28.3698C27.8995 20.6435 27.5132 20.2571 27.5132 19.7868C27.5132 19.3165 27.8995 18.9302 28.3698 18.9302H29.0417C29.512 18.9302 29.8983 19.3165 29.8983 19.7868C29.8983 20.2571 29.512 20.6435 29.0417 20.6435Z"
      fill="#765AC8"
    />
  </svg>
</template>
<script>
export default {
  name: 'plusIcon'
}
</script>
