<template>
  <v-card class="ds-popup" style="width:1000px !important">
    <v-row>
      <v-spacer></v-spacer>

      <v-col class="justify-center">
        <!-- <v-icon class="ds-icon"> mdi-plus </v-icon> -->
		
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <!-- <v-btn icon large @click="updateActiveDialog('')" class="ds-btn-close">
          <v-icon> mdi-close </v-icon>
        </v-btn> -->
      </v-col>
    </v-row>
    <v-card-title class="ds-card-title justify-center">
      <h1>Hi {{userProfile.name}}</h1>
      <!-- <h2> Welcome to your Perquity Dashboard. </h2> -->
    </v-card-title>

    <v-card-text>
      <!-- <v-form ref="addShareForm" v-model="addShareForm.valid">
        <v-autocomplete
          v-if="shareCodeOptions != null"
          :items="shareCodeOptions"
          v-model="addShareForm.model"
          :rules="addShareForm.rules.shareCode"
          label="Select Share Code"
          class="ds-input"
        >
        </v-autocomplete>
        <p v-else>sorry you got it all!</p>
      </v-form> -->
	  <div class="custom-subhead-column">
					<div class="csc_colLeft">
						Welcome to your PERQUITY dashboard. This quick tutorial provides you with the information you need to start your Equity management journey. To begin with there are seven questions that arise when you become a Long Term Investor: 
					</div>
					<div class="csc_colRight">
						<p>1)	What to buy?</p>
            <p>2)	When to buy?</p>
            <p>3)	How much to buy?</p>
            <p>4)	What to sell?</p>
            <p>5)	When to sell?</p>
            <p>6)	How much to sell?</p>
            <p>7)	What is the yardstick by which we can judge the success of our portfolio management?</p>
					</div>
				</div>
        <div class="custom-bottom-para">
            PERQUITY will guide through all seven aspects with logical reasoning so you can become the best Manager of your Equity Portfolio.
          </div>
				<div class="customLeftRightStepWrap">
					<div class="custom-leftStep">
						<div class="setpHeadWrap">
							<!-- <div class="setpHead"> Step 1: Enter Existing Scrips Data </div> -->
							<div class="setpSubHead"> 
								<p>1. PERQUITY is designed for those who have progressive investment ability of funds to invest steadily via the “SIP” method. You will be guided wisely through the above decisions through our platform. However for “CHUNK INVESTORS” the platform can also be used for comparing the different shares of your portfolio to see how they are performing relative to each other. This ensures that  you can always get rid of low performing shares on a long-term basis and shift your investments to better performing shares.
*** SIP meaning explained in “FAQ” No.2. </p>
							</div>
						</div>
						<div class="setpHeadWrap">
							<!-- <div class="setpHead"> Step 2: Set a Profit Ratio </div> -->
							<div class="setpSubHead">   
								<p>
									2. Once your account has been created in PERQUITY you need to go to the menu and click the SET PROFIT RATIO button. Enter the value such that the percentage profit ratio is the minimum profit you expect to generate for any single transaction of SIP buying or selling.
   *** Explained in “FAQ” Nos. 3 & 4 </p>
 
<p>(i)          	If you already have an existing portfolio when adopting PERQUITY, you need to add all the shares of your existing portfolio by going to the menu and clicking on ADD NEW SCRIP. Choose your share in the populated list of shares displayed in our program and add all of them to your PERQUITY portfolio.</p>
<p>(ii)        	For  users who already have an existing portfolio, this step is required only once to start you journey. Go to the share which is now displayed with all zero  values on the “PORTFOLIO OVERVIEW” page and click on the 3 dots in the top right corner. The first option takes you to “ADD NEW TRANSACTION”, which is to be filled by you from the information found on your DEMAT account for the corresponding share. For your first entry, you will need to mention the number of shares purchased and the consolidated rate. </p>
***Explained in “FAQ” No.6.
<p>(iii)       	Now PERQUITY is ready to use, and in a similar fashion go to “ADD NEW TRANSACTION” and enter the details each and every entry you Purchase/Sell shares from that scrip, keeping it in tune with your DEMAT account.
***Explained in “FAQ” No. 11    
								</p>
								<!-- <p>
									Once you have entered all your data, follow the instructions in TRADE ADVICE to start your journey to smarter investments.
								</p> -->
							</div>
						</div>
						<div class="setpHeadWrap">
							<!-- <div class="setpHead"> Step 2: Set a Profit Ratio </div> -->
							<div class="setpSubHead">   
								<p>
									3. 	 PERQUITY will automatically segregate your total investment into five categories (Perfect to Perishable) coded by 5 colours in the “PORTFOLIO OVERVIEW” page. “TRADE ADVICE'' is now available, which will answer the questions discussed in the very first paragraph above. From now on, you can depend on PERQUITY rather than outsiders for advice on your Investment decisions.
   *** Categorisation is explained in “FAQ” No.8. </p>
							</div>
						</div>
					</div>
					<div class="custom-RightStep">
            <p>
									4. 	Let us now understand what “THE YARDSTICK OF OUR PORTFOLIO” will be. As an investor, your objective should always be to maximise the Return of Investment (ROI) on the portfolio. This will fluctuate daily, depending on market trends and your involvement in keeping the transactions for each script up-to-date.
                 ***Explained in FAQ No. 7</p>
<p>
The secondary objective should be that our “AVERAGE CATEGORY INVESTMENT” in the five categories should be in descending value from Perfect to Perishable. This means that ideally we should have maximum shares in Perfect and then Pergrowth and so forth. This will boost your Portfolio’s ROI.</p>
						<p>
							5. 	When creating your portfolio, it is always best to diversify your shares in fast moving sectors. You can choose the best performing company within each sector by consulting with Financial News sources, DEMAT recommendations, Trade Magazines, Brokers or other trusted sources. Our FAQ page has all these sectors graded into 3 categories, based on our research – and modified every quarter to ensure you get the best advice. PERQUITY, using our own algorithm, market trends and your own Profit Ratio, will also guide you through the TRADE ADVICE section. This will help you decide What to Buy.
                 ***List of sectors from ‘Best’ to ‘Fair’ can be found in “FAQ” No. 16.
						</p>
						<p>
							6. 	When looking at When To Buy, the TRADE ADVICE page will provide you with 2 prerequisites and both should be satisfied. You can use the priority of purchase list and decide whether you would like to go for the recommended buy or “UPPER BAND” or the speculative buy or “LOWER BAND”.
***Full explanation of the bands can be found in FAQ Nos. 12 & 13.
						</p>
						<p>
							7. 	When looking at What and When to Sell, follow the priority listing that PERQUITY provides and sell when it is more than the PURPLE HIGHLIGHTED band price, depending on your fund requirements.
						</p>
						<p>
							8. 	Finally, when looking at How Much To Buy or Sell there are 3 suggested methods of disbursing your investments within your existing portfolio. For convenience we call them ‘X Distribution’, ‘2X distribution’ and ‘3X distribution’ of investments.
       ***Explained in detail on “FAQ” No. 17
						</p>
					</div>				
				</div>
				<div class="custom-bottom-para">
					<p>
						Now you have everything you need to begin your investment journey.
					</p>				
				</div>
				<div class="custom-bottom-para">
					<h4> Best of luck. </h4>
				</div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <!-- <v-btn class="ds-button" @click="updateActiveDialog('')">Skip for now</v-btn> -->
      <v-btn
        class="ds-button"
        @click="updateActiveDialog('')">Continue</v-btn
      >
    </v-card-actions>
	<!-- <div class="custom-bottom-btn-wrap">
					<a href="#"> Skip for now </a>
					<a href="#"> Continue </a>
				</div> -->
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'WelcomeDialog',
  data() {
    return {
      addShareForm: {
        valid: true,
        model: {},
        rules: {
          shareCode: [(v) => !!v || 'Share Code is required']
        }
      }
    }
  },
  methods: {
    //   validate the form and pass the addShareForm data if valid
    validate() {
      console.log(
        '🚀 ~ file: AddShare.vue ~ line 60 ~ validate ~ this.addShareForm.model',
        this.addShareForm.model
      )
      this.$refs.addShareForm.validate
        ? this.addShare(this.addShareForm.model)
        : console.log('Add Share Form validation error')
      this.$refs.addShareForm.reset()
      this.updateActiveDialog('')
    },
    reset() {
      // reset form
      this.$refs.form.reset()
    },
    ...mapActions(['addShare', 'updateActiveDialog'])
  },
  computed: {
    shareCodeOptions() {
      if (this.userShares) {
        /**
         * get the shares existing with user
         */
        let userShares = []
        this.userShares.forEach((element) => {
          userShares.push(element.ticker)
        })

        /**
         * compare the share code values existing with user and master shares
         * returns the uncommon shares
         */

        let shareCodeOptions = this.appData.strippedSharesArray.filter(
          (val) => {
            return !userShares.includes(val.ticker)
          }
        )

        let shareCodeOptions1 = []
        shareCodeOptions.forEach((el) => {
          shareCodeOptions1.push({
            text: `${el.ticker} - ${el.company_name}`,
            value: el
          })
        })

        return shareCodeOptions1
      } else {
        return null
      }
    },

    ...mapState(['userShares', 'appData', 'userProfile'])
  },
  created() {}
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.v-dialog .v-dialog--active .v-dialog--persistent {
	width:1000px !important;
}
.ds-popup {
  padding-top: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 3.5rem;
  padding-left: 0.75rem;
  border-radius: 0.5rem !important;
  .ds-btn-close {
    background-color: $colors-brand-primary;
    color: $colors-brand-neutral_light-7;
    margin: 0.75rem;
  }
  .ds-icon {
    color: $colors-brand-secondary-yellow;
    background-color: $colors-brand-secondary-yellow_light-6;
    border-radius: 6rem;
    width: 6rem;
    height: 6rem;
    font-size: 4rem;
    margin: 0.75rem;
    left: 2.5rem;
  }
}
.ds-card-title {
  @include heading_1_bold;
  line-height: 3rem !important;
  justify-content: center;
}
.ds-input::v-deep label {
  color: $colors-brand-primary !important;
  @include secondary_paragraph_3_regular;
}
.ds-input::v-deep .v-input__slot::before {
  border-color: $colors-brand-primary !important;
}
.ds-button {
  border: 1px $colors-brand-primary solid;
  color: $colors-brand-primary !important;
  border-radius: 4px;
  padding: 20px !important;
}

.modal-body.custom-modal-body {
		height: 650px;
		overflow-y: scroll;
	}

	.custom-modal-body h2
	{
		font-size: 36px;
		text-align: center;
		font-weight: bold;
	}
	
	.custom-subhead-column 
	{
		font-size: 18px;
		display: grid;
		grid-template-columns: 52% 42%;
		grid-column-gap: 6%;
		margin-top: 20px;
		padding: 0 20px;
		color: #696969;
		font-weight: 500;
		margin-bottom: 40px;
	}
	
	.customLeftRightStepWrap {
		display: grid;
		grid-template-columns: 55% 45%;
		grid-column-gap: 30px;
		padding: 0px 20px;
	}	

	.customLeftRightStepWrap .custom-leftStep .setpHeadWrap .setpHead {
		font-size: 16px;
		font-weight: 600;
		color: #696969;
	}

	.customLeftRightStepWrap .custom-leftStep .setpHeadWrap
	{
		margin-bottom:20px;
	}
	
	.custom-bottom-para {
		padding: 0 20px;
		margin-top: 60px;
		margin-bottom: 50px;
	}

	.bottomStartedWrap h4 {
		text-align: center;
	}

	.custom-bottom-btn-wrap 
	{
		margin-top: 40px;
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
		grid-column-gap: 20px;
	}

	.custom-bottom-btn-wrap a {
		border: 2px solid #5D5FEF;
		border-radius: 4px;
		padding: 10px;
		color: #5D5FEF;
	}
</style>
