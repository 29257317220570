<template>
  <div>
    <!-- <button @click="runAdvice">Trade Advice</button> -->
    <!-- <pre>{{ logdata }}</pre> -->
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      user: {
        all_shares_composit_purchase_value: 0, // sum of all shares.composit_purchase_value
        total_profit_loss: 0,
        avg_profit_loss_percentage: 0,
        greed_percentage: 0.07,
        category_values: {
          A: 0,
          B: 0,
          C: 0,
          D: 0,
          E: 0
        },
        shares: []
      },
      logdata: ''
    }
  },
  methods: {
    ...mapActions(['updateTradeAdvice']),
    log(newLine) {
      console.log(newLine)
      this.logdata += `${newLine} \n`
    },
    findOcc(arr, key){
        //let arr2 = [];
        let sum=0;
        let i=0;
        if(arr) {
          arr.forEach((x)=>{
           if (x.share_category == key) {
            sum = sum + x.composit_purchase_value;
            i++;
           }
          })
        } 
        return {key:key, composite:sum, avg:sum/i}
      },
    compute_share_values(tempUserShares) {
      // Computing the quarter year for calculating Sales Growth and Profit Growth
      // function quarter_of_the_year(date) {
      //   // console.log('now', date)
      //   let month = date.getMonth() + 1
      //   // console.log('month', month)
      //   return Math.ceil(month / 3) - 1 == 0 ? 4 : Math.ceil(month / 3) - 1
      // }
      // let current_date = new Date(Date.now())
      // let current_quarter = quarter_of_the_year(current_date)
      // console.log('current quarter', current_quarter)
      let months = ['Mar', 'Jun', 'Sep', 'Dec']
      // let latest_quarter = months[current_quarter - 1]
      // let previous_quarter = months[current_quarter - 2]
      // let current_year = current_date.getFullYear()
      // let previous_year = current_year - 1

      // let previous_quarter_current_year = previous_quarter + ' ' + current_year
      // let previous_quarter_previous_year =
      //   previous_quarter + ' ' + previous_year
      // let last_quarter_previous_year = latest_quarter + ' ' + previous_year
      // let latest_quarter_previous_year = lastest_quarter + ' ' + previous_year
      // sales, previous_quarter cuurent year, previous quarter previous year, latest quarter previous year,

      // console.log('Getting the month for calculation', latest_quarter)
      // console.log('Getting the preious month for calculation', previous_quarter)
      // console.log('Getting the year for calculation', current_year)
      // console.log('Getting the previous year for calculation', previous_year)


      const sorter = (a, b) => {  
      if(a.year !== b.year){
          return b.year - a.year;
      }else{
          return months.indexOf(b.month) - months.indexOf(a.month);
      };
      };
      tempUserShares.forEach((share, index) => {
        this.log(
          `##### SHARE ${index + 1} ${
            share.company_name
          } _________________________________________________`
        )
        
        let previousSale = share.marketDataRef?.past_data?.sales;
        let previousTrade = share.marketDataRef?.past_data?.net_profits;
        let allPreviosData = [];
        let allPreviosDataTrad = [];
        for (const key in previousSale) {
          allPreviosData.push({month:key.split(" ")[0],year:key.split(" ")[1],value:previousSale[key]});
        }
        for (const key in previousTrade) {
          allPreviosDataTrad.push({month:key.split(" ")[0],year:key.split(" ")[1],value:previousTrade[key]});
        }
        
        let sortedSale = allPreviosData.sort(sorter);
        let sortedTrade = allPreviosDataTrad.sort(sorter);
        console.log("SortedDataSale==========>",sortedSale);
        let s1 = sortedSale?sortedSale[0]?.value:0;
        let s2 = sortedSale?sortedSale[1]?.value:0;
        let s5 = sortedSale?sortedSale[4]?.value:0;
        let s6 = sortedSale?sortedSale[5]?.value:0;

        let n1 = sortedTrade?sortedTrade[0]?.value:0;
        let n2 = sortedTrade?sortedTrade[1]?.value:0;
        let n5 = sortedTrade?sortedTrade[4]?.value:0;
        let n6 = sortedTrade?sortedTrade[5]?.value:0;

        let c1 = (s2-s6)*100/s6;
        let c2 = (n2-n6)*100/n6;
        let c3 = (s1-s5)*100/s5;
        let c4 = (n1-n5)*100/n5;
        let numerical = 0;
        let numericalTrade = 0;
        if(c3 > c1) {
          numerical = 10;
        } else if(c3 <= c1) {
          numerical = 5;
        }
        share.c1 = c1?c1:0;
        share.c3 = c3?c3:0;
        share.s1 = s1?s1:0;
        share.s2 = s2?s2:0;
        share.s5 = s5?s5:0;
        share.s6 = s6?s6:0;
        share.numerical = numerical;


        if(c4 > c2) {
          numericalTrade = 10;
        } else if(c4 <= c2) {
          numericalTrade = 5;
        }
        share.c2 = c2?c2:0;
        share.c4 = c4?c4:0;
        share.n1 = n1?n1:0;
        share.n2 = n2?n2:0;
        share.n5 = n5?n5:0;
        share.n6 = n6?n6:0;
        share.numericalTrade = numericalTrade;
        // compute market value
        share.market_value =
          share.share_count * share.marketDataRef?.last_traded_price
        console.log("Check Market Reff============>", share.marketDataRef?.last_traded_price);  
        this.log(
          `updated share.market_value: ${Math.round(share.market_value)}`
        )

        // compute profit loss value
        share.profit_loss_value =
          share.market_value - share.composit_purchase_value // (share.market_value – share.composit_purchase_value)
        console.log("Check my new done", share.market_value, share.composit_purchase_value);
          this.log(
          `updated share.profit_loss_value: ${Math.round(
            share.profit_loss_value
          )}`
        )

        // compute profit/loss percentage(
        share.profit_loss_percentage =
          (share.profit_loss_value / share.composit_purchase_value) * 100 // (profit_loss_value / composit_purchase_value)
        this.log(
          `updated share.profit_loss_percentage: ${Math.round(
            share.profit_loss_percentage
          )}`
        )
        // this.log(`Check Shareeee=============> ${share.profit_loss_value} " " ${share.composit_purchase_value}`);

        // compute yearly average
        share.yearly_average =
          (share.marketDataRef?.year_high + share.marketDataRef?.year_low) / 2 // (year_high + year_low)/2
        this.log(
          `updated share.yearly_average: ${Math.round(share?.yearly_average)}`
        )
        // compute mean mode
        share.mean_mode =
          share.marketDataRef?.last_traded_price / share?.yearly_average // (last_traded_price / yearly_average)
        this.log(`updated share.mean_mode: ${Math.round(share?.mean_mode)}`)

        // compute distance from average
        let avg_distance = 0
        if (parseFloat(share.mean_mode) >= parseFloat(1.25)) {
          console.log("condFirst===>", share.mean_mode);
          avg_distance = 2
        } else if (parseFloat(1) < parseFloat(share.mean_mode) && parseFloat(share.mean_mode) < parseFloat(1.25)) {
          console.log(parseFloat(1) + "<" + parseFloat(share.mean_mode) +"<"+ parseFloat(1.25));
          avg_distance = 5
        } else if (parseFloat(share.mean_mode) < parseFloat(1)) {
          console.log("condFirst===>", share.mean_mode);
          avg_distance = 10
        }
        share.avg_distance = avg_distance
        this.log(
          `updated share.avg_distance: ${Math.round(share.avg_distance)}`
        )
        // compute special announcement
        if (!share.announcement) {
          let special_announcement = 1
          share.special_announcement = special_announcement
        } else {
          share.special_announcement = share.announcement;
        }
        
        this.log(
          `Special Anouncement value: ${Math.round(share.special_announcement)}`
        )

        // compute sales growth
        console.log("Check Market Data=====>", share.marketDataRef);
        // let a = 0
        // let b = 0
        let sales_growth = 0
        
        // let prev_quarter_cur_year = share.marketDataRef?.past_data?.sales[
        //   previous_quarter_current_year
        // ]
        //   ? share.marketDataRef?.past_data?.sales[previous_quarter_current_year]
        //   : 'fail'
        // let prev_quarter_prev_year = share.marketDataRef?.past_data?.sales[
        //   previous_quarter_previous_year
        // ]
        //   ? share.marketDataRef.past_data.sales[previous_quarter_previous_year]
        //   : 'fail'
        // let last_quarter_prev_year = share.marketDataRef?.past_data?.sales[
        //   last_quarter_previous_year
        // ]
        //   ? share.marketDataRef?.past_data.sales[last_quarter_previous_year]
        //   : 'fail'
        // let last_quarter_cur_year = share.marketDataRef?.sales
        //   ? share.marketDataRef?.sales
        //   : 'fail'
        // a =
        //   (prev_quarter_cur_year - prev_quarter_prev_year) /
        //   prev_quarter_prev_year
        // b =
        //   (last_quarter_cur_year - last_quarter_prev_year) /
        //   last_quarter_prev_year

        // if (a >= b && a != 'fail' && b != 'fail') {
        //   sales_growth = 5
        // } else if (a < b && a != 'fail' && b != 'fail') {
        //   sales_growth = 10
        // } else {
        //   sales_growth = 0
        // }
        // share.sales_growth = sales_growth
        // this.log(`sales_growth value: ${Math.round(share.sales_growth)}`)

        // console.log(
        //   'share.marketDataRef.past_data.sales[previous_quarter_current_year]',
        //   prev_quarter_cur_year
        // )
        // console.log(
        //   'share.marketDataRef.past_data.sales[previous_quarter_previous_year]',
        //   prev_quarter_prev_year
        // )
        // console.log(
        //   'share.marketDataRef.past_data.sales[last_quarter_cur_year]',
        //   last_quarter_cur_year
        // )
        // console.log(
        //   'share.marketDataRef.past_data.sales[last_quarter_cur_year]',
        //   last_quarter_cur_year
        // )
        // console.log('a', a)
        // console.log('b', b)
        // console.log('sales growth', sales_growth)

        // compute profit loss growth
        // let temp_last_quarter = 0
        // let temp_prev_quarter = 0
        let pl_growth = 0

        //let pl_prev_quarter_cur_year = 0
        // let pl_prev_quarter_cur_year = share.marketDataRef?.past_data
        //   ?.net_profits[previous_quarter_current_year]
        //   ? share.marketDataRef?.past_data.net_profits[
        //       previous_quarter_current_year
        //     ]
        //   : 0
        // let pl_prev_quarter_prev_year = share.marketDataRef?.past_data
        //   .net_profits[previous_quarter_previous_year]
        //   ? share.marketDataRef?.past_data.net_profits[
        //       previous_quarter_previous_year
        //     ]
        //   : 0
        // let pl_last_quarter_prev_year = share.marketDataRef?.past_data
        //   .net_profits[last_quarter_previous_year]
        //   ? share.marketDataRef?.past_data.net_profits[
        //       last_quarter_previous_year
        //     ]
        //   : 0
        // let pl_last_quarter_cur_year = share.marketDataRef?.net_profit
        //   ? share.marketDataRef?.net_profit
        //   : 0
        // temp_last_quarter =
        //   (pl_last_quarter_cur_year - pl_last_quarter_prev_year) /
        //   pl_last_quarter_prev_year
        // temp_prev_quarter =
        //   (pl_prev_quarter_cur_year - pl_prev_quarter_prev_year) /
        //   pl_prev_quarter_prev_year

        // if (
        //   (pl_last_quarter_cur_year > 0 && pl_last_quarter_prev_year < 0) ||
        //   (pl_last_quarter_cur_year < 0 && pl_last_quarter_prev_year < 0)
        // ) {
        //   pl_growth = 0
        // } else if (
        //   pl_last_quarter_cur_year > 0 &&
        //   pl_last_quarter_prev_year < 0
        // ) {
        //   pl_growth = 2
        // } else {
        //   if (temp_prev_quarter > temp_last_quarter) {
        //     pl_growth = 5
        //   } else if (temp_prev_quarter < temp_last_quarter) {
        //     pl_growth = 10
        //   }
        // }
        // share.pl_growth = pl_growth
        // this.log(`pl_growth value: ${Math.round(share.pl_growth)}`)
        // compute future
        let future = 2
        if (!share.future) {
          share.future = future;
        }
        
        this.log(`share future value: ${Math.round(share.future)}`)

        // compute growth future
        let growth_future = 0
        growth_future = sales_growth + pl_growth + future
        share.growth_future = growth_future
        this.log(`sgrowth_future value: ${Math.round(share.growth_future)}`)

        console.log("This User Trade Price=======>", this.userProfile.greedPercentage);
        // compute trade bands MIN/MAX
        share.trading_rates = {
          buy: {
            min:
              share.last_transaction_trade_price -
              share.last_transaction_trade_price *
                this.userProfile.greedPercentage,
            max:
              share.last_transaction_trade_price +
              share.last_transaction_trade_price *
                this.userProfile.greedPercentage
          },
          sell: {
            min:
              share.last_transaction_trade_price +
              share.last_transaction_trade_price *
                this.userProfile.greedPercentage,
            max: share?.year_high
          }
        }
        this.log('trading_rates')
        this.log(share.trading_rates)

        // compute share category
        this.computeShareCategory(share)

        // if(share.composit_purchase_value < this.findOcc(tempUserShares, share.share_category).avg) {
        //   share.trade_advice = 2;
        // } else {
        //   share.trade_advice = 1;
        // }

        // console.log(+share.trade_advice + +share.profit_order + +share.investment_order + +share.avg_distance + +share.numerical + +share.numericalTrade + +share.special_announcement + +share.future);

        //share.final_numerical_value = +share.trade_advice;

        this.log(`...`)
      })
    },

    compute_user_values() {
      //   composit purchase value of all shares held by user
      // this.log(
      //   `##### CALCULATING USER SHARE COMPOSIT VALUE, AVG P/L%, CAT_____`
      // )
      let all_shares_composit_purchase_value = 0
      let profit_loss_value = 0
      let all_shares_market_value = 0
      let all_shares_count = 0
      let count = 1

      /**
       * Loop through all user shares and calculate
       */
      this.user.shares.forEach((share) => {
        this.log(
          `    adding share value:                ${share.composit_purchase_value}`
        )
        this.log(
          `    adding profit_loss_value:          ${share.profit_loss_value}`
        )
        
        all_shares_composit_purchase_value += isNaN(share.composit_purchase_value)?0:share.composit_purchase_value
        profit_loss_value += isNaN(share.profit_loss_value)?0:share.profit_loss_value
        all_shares_market_value += isNaN(share.market_value)?0:share.market_value
        all_shares_count = count++
        this.log(
          `    _________________________________________________________`
        )
      })

      this.log(`\n##### RESULTS: COMPOSIT VALUE, AVG P/L%, CAT_____________`)
      this.user.all_shares_composit_purchase_value =
        all_shares_composit_purchase_value
      this.log(
        `updated user.all_shares_composit_purchase_value:   ${this.user.all_shares_composit_purchase_value}`
      )
      if(isNaN(profit_loss_value)) {
        profit_loss_value = 0;
      }
      //   Total profit & loss
      this.user.total_profit_loss = profit_loss_value
      this.log(
        `updated user.total_profit_loss:                    ${Math.round(
          this.user.total_profit_loss
        )}`
      )

      // Total shares count
      this.user.all_shares_count = all_shares_count
      // console.log('this.user.all_shares_count', this.user.all_shares_count)

      // average profit loss percentage
      this.user.avg_profit_loss_percentage =
        (profit_loss_value / all_shares_composit_purchase_value) * 100
        console.log("Check My Number======>", profit_loss_value, all_shares_composit_purchase_value);
      this.log(
        `updated user.avg_profit_loss_percentage:           ${this.user.avg_profit_loss_percentage}`
      )

      // all shares market value
      this.user.all_shares_market_value = all_shares_market_value
      this.log(
        `updated all_shares_market_value:           ${Math.round(
          this.user.all_shares_market_value
        )}`
      )

      // share.share_category = 0; // (refer column s)
      // share.category_rating = 0; // (order desc index)
      // share.invest_value_serial = 0; // (column m (comp_purch_val) desc index)
      // share.trade_recommendation = 0; //(1=sell; 2=purchase) (comp_purchase < avg_cat_inv => purchase)
      // share.trade_min_price = 0; // (last_transaction_trade_price - (last_transaction_trade_price * greed_percentage)
      // share.trade_max_price = 0; // (last_transaction_trade_price + (last_transaction_trade_price * greed_percentage)
    },

    /**
     * This function evaluates the profit/loss percentage
     * assigns a category A, B, C, D depending on percentage
     * A – > 1000%
     * B – < 1000 > AVG profit/loss %
     * C – < AVG profit/loss % >0
     * D – < 0
     * E – < -1
     */
    computeShareCategory(share) {
      //  console.log("KKKKKKKKkkkkkKKKKK=====>",share.profit_loss_percentage, "KKKKKKKKK3333333======>", this.user.avg_profit_loss_percentage);
       if (isNaN(this.user.avg_profit_loss_percentage)) {
        this.user.avg_profit_loss_percentage = 0;
       }
       if (isNaN(share.profit_loss_percentage)) {
        share.profit_loss_percentage = 0;
       }
      if (share.profit_loss_percentage > 1000) {
        share.share_category = 'A'
        this.log(
          `Assigned category: A — P/L %:          ${Math.round(
            share.profit_loss_percentage
          )}%`
        )
      } else if (
        share.profit_loss_percentage > this.user.avg_profit_loss_percentage && share.profit_loss_percentage > 10
      ) {
        share.share_category = 'B'
        this.log(
          `Assigned category: B — P/L %:          ${Math.round(
            share.profit_loss_percentage
          )}%`
        )
      } else if (share.profit_loss_percentage <= this.user.avg_profit_loss_percentage && share.profit_loss_percentage >= 10) {
        share.share_category = 'C'
        this.log(
          `Assigned category: C — P/L %:          ${Math.round(
            share.profit_loss_percentage
          )}%`
        )
      } 
      else if (share.profit_loss_percentage <= 10 && share.profit_loss_percentage >= 0) {
        share.share_category = 'D'
        this.log(
          `Assigned category: D — P/L %:          ${Math.round(
            share.profit_loss_percentage
          )}%`
        )
      } 
      else if (+share.profit_loss_percentage < 0) {
        console.log("Check profit lossss======>",share.profit_loss_percentage);
        share.share_category = 'E'
        this.log(
          `Assigned category: E — P/L %:          ${Math.round(
            share.profit_loss_percentage
          )}%`
        )
      }
    },

    computeShareCategoryRating(shares) {
      /**
       * Sorting the user shares using lodash orderBy
       * First group by share category ASC
       * Sort by profit_loss_percentage DESC
       * */
      const shares_sorted_by_cat_pl = _.orderBy(
        shares,
        ['share_category', 'profit_loss_percentage'],
        ['asc', 'asc']
      )
      // Grouping the shares by category and storing in a temporary array grouped
      // let grouped = _.mapValues(
      //   _.groupBy(shares_sorted_by_cat_pl, 'share_category')
      // )
      // console.log('grouped')
      // console.table(grouped)

      /**
       * Calculate category_rating and assign to each share
       *
       */

      // Define counters
      let ca = 0
      let cb = 0
      let cc = 0
      let cd = 0
      let ce = 0
      let next_count_value = 0
      let pl = 0

      // Loop through each share and increment the value depending on category
      shares_sorted_by_cat_pl.forEach((share) => {
        if (share.share_category == 'A') {
          pl = 5000
          ca++
          next_count_value = ca
        }
        if (share.share_category == 'B') {
          pl = 4000
          cb++
          next_count_value = cb
        }
        if (share.share_category == 'C') {
          pl = 3000
          cc++
          next_count_value = cc
        }
        if (share.share_category == 'D') {
          pl = 2000
          cd++
          next_count_value = cd
        }
        if (share.share_category == 'E') {
          pl = 1000
          ce++
          next_count_value = ce
        }
        // if (share.share_category == 'E') {
        //   pl = 1000
        //   ce++
        //   next_count_value = ce
        // }
        // Assign category_rating to share
        share.category_rating = `${share.share_category}${next_count_value}`
        share.profit_order = pl + next_count_value
      })

      // Assign sorted array back to user shares
      this.user.shares = shares_sorted_by_cat_pl
      // console.log('this.user.shares _____________')
      // console.table(this.user.shares)
      console.table(shares_sorted_by_cat_pl)
    },
    computeShareInvestmentRating(shares) {
      /**
       * Sorting the user shares using lodash orderBy
       * First group by share category ASC
       * Sort by composit_purchase_value DESC
       * */
      const shares_sorted_by_comp_pv = _.orderBy(
        shares,
        ['share_category', 'composit_purchase_value'],
        ['asc', 'desc']
      )

      // Grouping the shares by category and storing in a temporary array grouped
      // let grouped = _.mapValues(
      //   _.groupBy(shares_sorted_by_cat_pl, 'share_category')
      // )
      // console.log('grouped')
      // console.table(grouped)

      /**
       * Calculate category_rating and assign to each share
       *
       */

      // Define counters
      // let priority_order_sell = 0
      // let priority_order_buy = 0
      let ca = 0
      let ca1 = 0
      let cb = 0
      let cb1 = 0
      let cc = 0
      let cc1 = 0
      let cd = 0
      let cd1 = 0
      let ce = 0
      let ce1 = 0
      let next_count_value = 0
      let pl = 0
      let pos = shares_sorted_by_comp_pv.length
      let pob = 0

      // Loop through each share and increment the value depending on category
      shares_sorted_by_comp_pv.forEach((share) => {
        // pob++;
        // pos++;
        if (share.share_category == 'A') {
          pl = 500
          if (share.trade_recommendation == 'Buy') {
            ca++
            next_count_value = ca
          } else {
            ca1++
            next_count_value = ca1
          }
          
        }
        if (share.share_category == 'B') {
          pl = 400
          if (share.trade_recommendation == 'Buy') {
            cb++
            next_count_value = cb
          } else {
            cb1++
            next_count_value = cb1
          }
        }
        if (share.share_category == 'C') {
          pl = 300
          if (share.trade_recommendation == 'Buy') {
            cc++
            next_count_value = cc
          } else {
            cc1++
            next_count_value = cc1
          }
        }
        if (share.share_category == 'D') {
          pl = 200
          if (share.trade_recommendation == 'Buy') {
            cd++
            next_count_value = cd
          } else {
            cd1++
            next_count_value = cd1
          }
        }
        if (share.share_category == 'E') {
          pl = 100
          if (share.trade_recommendation == 'Buy') {
            ce++
            next_count_value = ce
          } else {
            ce1++
            next_count_value = ce1
          }
        }
        
        // Assign category_rating to share
        share.investment_rating = `${share.share_category}${next_count_value}`
        if (share.trade_recommendation == 'Buy') {
          share.trade_advice = 2;
          share.investment_order = pl + next_count_value;
          pob++;
          share.pob = pob;
        } else {
          share.trade_advice = 1;
          share.investment_order = next_count_value;
          pos--;
          share.pos = shares_sorted_by_comp_pv.length - pos;
        }
        
        
       // share.investment_order = pl + next_count_value-1
      })
      console.table(shares_sorted_by_comp_pv)

      // Assign sorted array back to user shares
      this.user.shares = shares_sorted_by_comp_pv
      // console.log('this.user.shares _____________')
      // console.table(this.user.shares)
      // console.table(shares_sorted_by_cat_pl)
    },

    computeTotalPrediction(shares) {
      // compute total prediction
      let total_prediction = 0
      shares.forEach((share) => {
        if (share.trade_recommendation == 'Buy') {
          total_prediction =
            share.growth_future +
            2 +
            share.profit_order +
            share.investment_order +
            share.avg_distance +
            share.special_announcement
          console.log(
            `${share.ticker} / BUY: ${share.growth_future} + 2 + ${share.profit_order} + ${share.investment_order} + ${share.avg_distance} + ${share.special_announcement}`
          )
        } else {
          total_prediction =
            share.growth_future +
            1 +
            share.profit_order +
            share.investment_order +
            share.avg_distance +
            share.special_announcement
          console.log(
            `${share.ticker} / BUY: ${share.growth_future} + 1 + ${share.profit_order} + ${share.investment_order} + ${share.avg_distance} + ${share.special_announcement}`
          )
        }
        console.log(`${total_prediction}`)
        share.total_prediction = total_prediction
        console.log(
            share.profit_order + "-" +
            share.trade_advice + "-" +
            share.investment_order + "-" +
            share.avg_distance + "-" +
            +share.special_announcement + "-" +
            +share.future + "-" +
            share.numerical + "-" +
            share.numericalTrade);
        let final_numerical_value = +share.future +
            share.trade_advice +
            share.profit_order +
            share.investment_order +
            share.avg_distance +
            +share.special_announcement +
            share.numerical +
            share.numericalTrade
        share.final_numerical_value = final_numerical_value;
        share.final_numerical_value_test = +share.future + "-" +
            share.trade_advice + "-" +
            share.profit_order + "-" +
            share.investment_order + "-" +
            share.avg_distance + "-" +
            +share.special_announcement + "-" +
            share.numerical + "-" +
            share.numericalTrade;
      })
      const shares_sorted_by_total_prediction = _.orderBy(
        shares,
        ['trade_recommendation', 'total_prediction'],
        ['asc', 'desc']
      )
      const shares_sorted_by_total_numerical_sell = _.orderBy(
        shares,
        ['trade_recommendation', 'final_numerical_value'],
        ['asc', 'asc']
      )
      const shares_sorted_by_total_numerical_buy = _.orderBy(
        shares,
        ['trade_recommendation', 'final_numerical_value'],
        ['desc', 'desc']
      )
      let a = 0
      let b = 0;
      let next_count_value = 0
      let next_count_value_sell = 0;

      shares_sorted_by_total_prediction.forEach((share) => {
        if (share.trade_recommendation == 'Buy') {
          a++
          next_count_value = a
        } else {
          next_count_value = 0
        }
        share.purchase_serial = next_count_value
      })

      shares_sorted_by_total_numerical_sell.forEach((share) => {
        if (share.trade_recommendation == 'Sell') {
          b++
          next_count_value_sell = b
        }
        share.priority_order_sell = next_count_value_sell
        
      })
      let c=0;
      shares_sorted_by_total_numerical_buy.forEach((share) => {
        if (share.trade_recommendation == 'Buy') {
          c++
          next_count_value_sell = c
        }
        share.priority_order_buy = next_count_value_sell
        
      })

    },

    computeSharesCategoryAvg(shares) {
      // Empty array to store composit_purchase_value and share count per category
      let catSum = {}
      // Loop through each share to calculate composit_purchase_value and share count per category
      shares.forEach((share) => {
        !catSum[share.share_category]
          ? (catSum[share.share_category] = { sum: 0, count: 0 })
          : false

        catSum[share.share_category].sum += share.composit_purchase_value
        catSum[share.share_category].count++
        // catCount[share.share_category]++;
      })
      console.table("Cat Summmmmm", catSum);
      // Calculate the category average
      for (const [, value] of Object.entries(catSum)) {
        value.avg = value.sum / value.count
       // value.percentage = (value.count / this.user.all_shares_count) * 100
        value.percentage = (value.sum / this.user.all_shares_composit_purchase_value) * 100
      }
      console.table("Cat Sum==========>",catSum)
      this.user.category_values = catSum
    },

    computeTradeRecommendation(user) {
      let recommendation = []
      user.shares.forEach((share) => {
        // console.log(share.composit_purchase_value)
        // console.log(user.category_values[share.share_category].avg)
        // console.log(share)
        recommendation.push([
          share.company_name,
          share.composit_purchase_value <=
          user.category_values[share.share_category].avg
            ? 'Buy'
            : 'Sell'
        ])
        share.trade_recommendation =
          share.composit_purchase_value <=
          user.category_values[share.share_category].avg
            ? 'Buy'
            : 'Sell'
      })
      console.table(recommendation)
    },
    duplicateUserShares() {
      // reset user shares array
      this.user.shares = []
      // deep clone userShares from state by value
      let userShares = JSON.parse(JSON.stringify(this.userShares))
      // iterate over shares and add to user.shares
      if(userShares.length) {
        userShares.forEach((share) => {
        // define initial values
        share.share_count = 0
        share.composit_purchase_value = 0
        share.last_transaction_trade_price = 0
        share.cost_per_share = 0
        share.last_transaction_type = 'Sell';
        share.upper_band = 0;
        share.lower_band = 0;
        // console.log(share)

        // Run only if share has transactions
        if (share.transactions) {
          share.transactions.forEach((t, index, array) => {
            // Set last_transaction_trade_price based on
            // transaction price of last transaction
            index == array.length - 1
              ? (share.last_transaction_trade_price =
                  t.transactionPricePerShare)
              : false

            index == array.length - 1
              ? (share.last_transaction_type =
                  t.transactionType)
              : false

            // Increment composit purchzse value with each transaction value
            share.composit_purchase_value += t.transactionValue
            // Increment share_count based on transaction qty
            share.share_count += t.quantity
            share.cost_per_share =
              share.composit_purchase_value / share.share_count
          })
          console.log("Single<==================>User", this.userProfileSingle);
          if (this.userProfileSingle) {
            let greedPercentage = this.userProfileSingle.greedPercentage;
            share.upper_band = share.last_transaction_trade_price + share.last_transaction_trade_price * greedPercentage;
            share.lower_band = share.last_transaction_trade_price - share.last_transaction_trade_price * greedPercentage;
            console.log("UpperBandGreed======>", greedPercentage);
          }
          console.log("UpperBand======>", share.upper_band);
          console.log("UpperBand======>", share.lower_band);
          // greedPercentage
          // else keep values 0
        }
        if (share.cost_per_share < 0) {
          share.cost_per_share = 0;
          share.composit_purchase_value = 0;
        }
        // Push the updated share into the this.user.shares array
        this.user.shares.push(share)
      })
      }
      
      this.log('User shares as received from the Database: ')
      this.log(this.user.shares)
    },
    runAdvice() {
      // Run Trade Advice step by step
      //console.warn('START duplicate User Shares ============================')
      this.duplicateUserShares()
      //console.warn('START Compute Share Values ============================', this.user.shares)
      this.compute_share_values(this.user.shares)
      //console.warn('START Compute User Values ============================')
      this.compute_user_values()
      // console.warn(
      //   'START Compute Category Rating Values ============================'
      // )
      this.computeShareCategoryRating(this.user.shares)
      // console.warn(
      //   'START Compute Category Rating Average Values ============================'
      // )
      this.computeSharesCategoryAvg(this.user.shares)
      // console.warn(
      //   'START Compute Trade Recommendation ============================'
      // )
      this.computeTradeRecommendation(this.user)
      // console.warn(
      //   'START Compute Investment Rating ============================'
      // )
      this.computeShareInvestmentRating(this.user.shares)
      // console.warn(
      //   'START Compute Total Prediction ============================'
      // )
      this.computeTotalPrediction(this.user.shares)
      // Update state with Trade Advice
      this.$store.dispatch('updateTradeAdvice', { ...this.user })
      // console.log(
      //   '-- Completed running TradeAdvice ------------------------------'
      // )
    }
  },
  computed: {
    ...mapState(['userShares', 'userProfile', 'appData', 'userProfileSingle'])
    // function that computes the value of userShares.length
  },
  created() {
    this.$store.subscribe((mutation) => {
      // console.log(this.userProfile.role != 'Admin');
      if (mutation.type === 'SET_UPDATE_TRADEADVICE') {
        // console.log('updateTradeAdvice called')
        // this.$store.commit('SET_LOADING_STATUS', true)
        // this.$store.commit('SET_LOADING_MESSAGE', 'Updating trade advice...')
        // this.runAdvice()

        setTimeout(() => {
          this.runAdvice()
          console.log('updateTradeAdvice called')
        }, 1500)

        setTimeout(() => {
          this.runAdvice()

          console.log('updateTradeAdvice called')
        }, 2000)
        // setTimeout(this.$store.commit('SET_LOADING_STATUS', false), 1500)
      }
    })
  }
}
</script>

<style lang="scss" scoped></style>
