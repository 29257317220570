<template>
  <v-card class="ds-popup">
    <v-row>
      <v-spacer></v-spacer>
      <v-icon class="justify-center ds-icon"> mdi-plus </v-icon>
      <v-spacer></v-spacer>
      <v-btn icon large @click="updateActiveDialog('')" class="ds-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-row>
    <v-card-title class="ds-card-title justify-center">
      Add Transaction
    </v-card-title>

    <v-card-text>
      <div>
        <!-- Add transaction form which displays on clicking a share row -->
        <v-form v-if="selectedShare" ref="form" v-model="form.valid">
          <v-row>
            <v-col>
              <v-select
                :items="form.items"
                v-model="form.model.transactionType"
                :rules="form.transactionTypeRules"
                label="Transaction Type"
                required
                class="ds-input"
              ></v-select>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.model.dateTime"
                    label="Select the transaction date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="ds-input"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  label="Select the transaction date"
                  v-model="form.model.dateTime"
                  required
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.model.transactionPricePerShare"
                :rules="form.transactionPricePerShareRules"
                label="Price per Share"
                required
                class="ds-input"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.model.quantity"
                :rules="form.quantityRules"
                label="Quantity"
                required
                class="ds-input"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <!-- <v-spacer></v-spacer> -->
      <v-btn outlined text @click="reset" class="ds-button"> Clear </v-btn>
      <v-btn
        text
        :disabled="!form.valid"
        outlined
        class="mr-4 ds-button"
        @click="validate"
      >
        Add Transaction
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AddTransaction',
  props: {
    share: Object,
    shouldRender: Boolean
  },
  data() {
    return {
      form: {
        valid: false,
        model: {
          transactionType: '',
          transactionPricePerShare: 0,
          quantity: 0,
          isWantDelete:false,
          dateTime: new Date().toISOString().substr(0, 10)
        },
        items: ['Buy', 'Sell'],
        transactionTypeRules: [(v) => !!v || 'Transaction Type is required'],
        transactionPricePerShareRules: [(v) => !!v || 'Price is required'],
        quantityRules: [(v) => !!v || 'Quantity is required']
      }
    }
  },
  methods: {
    // handles submit for add transaction form
    validate() {
      console.log(
        '🚀 ~ file: AddTransaction.vue ~ line 92 ~ validate ~ this.$refs.form.validate',
        this.$refs.form.validate()
      )
      this.$refs.form.validate
        ? this.addTransaction()
        : console.log('validation error')
      //   this.$refs.form.resetValidation();
    },
    // send the add transaction form data to store
    addTransaction() {
      console.log("==========>",this.share);
      //   create a temporary var to add computed data
      let transactionData = { ...this.form.model }
      console.log('🚀transaction start', transactionData)

      // Add the transactionValue to the data
      transactionData.transactionValue =
        // Convert the sell amount in a negative value
        this.form.model.transactionType == 'Sell'
          ? (transactionData.transactionValue = -Math.abs(
              transactionData.transactionPricePerShare *
                transactionData.quantity
            ))
          : transactionData.transactionPricePerShare * transactionData.quantity

      // convert transactionPricePerShare to Int
      transactionData.transactionPricePerShare = parseFloat(
        transactionData.transactionPricePerShare
      )

      transactionData.quantity = parseInt(transactionData.quantity)
      this.form.model.transactionType == 'Sell'
        ? (transactionData.quantity = -Math.abs(transactionData.quantity))
        : transactionData.quantity

      transactionData.ticker = this.selectedShare.ticker

      console.log(
        '🚀 ~ file: AddTransaction.vue ~ line 99 ~ addTransaction ~ transactionData',
        transactionData
      )

      this.$store.dispatch('addTransaction', transactionData)
      console.log(
        '🚀 ~ file: Home.vue ~ line 136 ~ addTransaction ~ transactionData',
        transactionData
      )

      //   hide dialog window
      this.$refs.form.reset()
      this.updateActiveDialog('')
    },
    reset() {
      // reset form
      this.$refs.form.reset()
    },
    ...mapActions(['updateActiveDialog'])
  },
  computed: {
    ...mapState(['selectedShare']),
    ...mapState(['user', 'userShares'])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.ds-popup {
  padding-top: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 3.5rem;
  padding-left: 0.75rem;
  border-radius: 0.5rem !important;
  .ds-btn-close {
    background-color: $colors-brand-primary;
    color: $colors-brand-neutral_light-7;
    margin: 0.75rem;
  }
  .ds-icon {
    color: $colors-brand-secondary-yellow;
    background-color: $colors-brand-secondary-yellow_light-6;
    border-radius: 6rem;
    width: 6rem;
    height: 6rem;
    font-size: 4rem;
    margin: 0.75rem;
    left: 2.5rem;
  }
}
.ds-card-title {
  @include heading_1_bold;
  line-height: 3rem !important;
  justify-content: center;
}
.ds-input::v-deep label {
  color: $colors-brand-primary !important;
  @include secondary_paragraph_3_regular;
}
.ds-input::v-deep .v-input__slot::before {
  border-color: $colors-brand-primary !important;
}
.ds-button {
  border: 1px $colors-brand-primary solid;
  color: $colors-brand-primary !important;
  border-radius: 4px;
  padding: 20px !important;
}
</style>
