const CryptoJS = require('crypto-js');

// export const encrypt = (text) => {
//   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
// };

// export const decrypt = (data) => {
//   return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
// };

export const encrypt = (text) => {
  const passphrase = 'XOXXXZ';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decrypt = (ciphertext) => {
  const passphrase = 'XOXXXZ';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};