<template>
  <v-card class='ds-share-card'>

    <div v-bind:class="['colored-table-box-cover', share.share_category]">
		
      <div class="accordion">

        <div class="accordion__header_home" :class="{'is_active':shouldRender}">
          <div class="color-table color-t-gridFive" :class="{'table-light-a': share.share_category == 'A', 'table-light-b':share.share_category == 'B', 'table-light-c':share.share_category == 'C', 'table-light-d':share.share_category == 'D', 'table-light-e':share.share_category == 'E'}">
            <ul class="colorGridUl">
              <li class="th-detail1">{{
                    share.company_name
                  }}-{{ share.share_category }}</li>
              <li class="th-detail2">
                Year High/Low {{
                      share.marketDataRef && share.marketDataRef.year_high ?share.marketDataRef.year_high.toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                      }):""
                    }}/{{
                      share.marketDataRef && share.marketDataRef.year_low?share.marketDataRef.year_low.toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                      }):""
                    }}</li>
              <li class="th-detail3">Last Closing Rate  <span :class="{'table-li-span-price-a': share.share_category == 'A', 'table-li-span-price-light-b':share.share_category == 'B', 'table-li-span-price-c':share.share_category == 'C', 'table-li-span-price-d':share.share_category == 'D', 'table-li-span-price-e':share.share_category == 'E'}"> {{
                      share.marketDataRef && share.marketDataRef.last_traded_price ? share.marketDataRef.last_traded_price.toLocaleString(
                        'en-IN',
                        {
                          maximumFractionDigits: 2,
                          style: 'currency',
                          currency: 'INR'
                        }
                      ):0
                    }} </span> </li>
              <li class="th-detail4">
                <div class="table-roi-price-cover">
                  <span class="tableRoiText"> ROI </span> 
                  <span class="tableRoiPrice"> {{
                      share.profit_loss_percentage?share.profit_loss_percentage.toLocaleString('en-IN', {
                        maximumFractionDigits: 2
                      }):0
                    }} </span>
                </div>
              </li>
              <li class="th-detail5" v-if = "userProfile.role != 'Admin'">
                <div class="action-btn" v-if="!shouldRender">
                  <div class="dropdown-container">
                    <div class="dropdown-toggle click-dropdown">
                      <!-- <img src="images/dots.svg" /> -->
                    <v-menu bottom left style="width:0px !important">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn light icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="dropdown-menu">
                      <v-list-item
                        v-for="(menuItem, i) in menuItems"
                        :key="i"
                        @click="menuItem.onClick(share)"
                      >
                        <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="!share.share_count"
                        @click="deleteShares()"
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu> 
                    </div>
                    <div class="dropdown-menu">
                      <ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="white-table-grid">
              <li class="tr-detail1">
                <div class="flex-jc-wrap">
                  <div class="table-detail-left">
                    <div class="table-detail-top"> Total Shares Purchased  </div>
                    <span class="mid-small-sep"> </span>
                    <div class="table-detail-bottom"> {{
                    share.share_count
                  }}  </div>
                  </div>
                  <div class="table-detail-right">
                    <div class="table-detail-top"> Average Cost Per Share  </div>
                    <span class="mid-small-sep"> </span>
                    <div class="table-detail-bottom"> {{
                    share.cost_per_share?share.cost_per_share.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }):0
                  }}  </div>
                  </div>
                </div>
              </li>
              <li class="tr-detail2">
                <div class="table-detail-left">
                  <div class="table-detail-top"> Composite Purchase Value  </div>
                  <span class="mid-small-sep"> </span>
                  <div class="table-detail-bottom"> {{
                    share.composit_purchase_value?share.composit_purchase_value.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }):0
                  }}  </div>
                </div>					
              </li>
              <li class="tr-detail3">
                <div class="table-detail-left">
                  <div class="table-detail-top"> Last Market Value </div>
                  <span class="mid-small-sep"> </span>
                  <div class="table-detail-bottom"> {{
                    share.market_value?share.market_value.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }):0
                  }}  </div>
                </div>						
              </li>
              <li class="tr-detail4">
                <div class="table-detail-left">
                  <div class="table-detail-top"> Total Gain/ Loss </div>
                  <span class="mid-small-sep"> </span>
                  <div class="table-detail-bottom"> {{
                      share.profit_loss_value?share.profit_loss_value.toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                      }):0
                    }}  </div>
                </div>
              </li>				
            </ul>
          </div>
          <span class="accordion__toggle_front"></span>
        </div>

        <div class="accordion__body_" :class="{'accordion__body':!shouldRender}">
          <v-row class="d-flex ds-card-stats">
        <!-- <v-col>
          <h1 class="ds-transaction-heading">Purchase History</h1>
        </v-col>
        <v-col>
          <h1 class="ds-transaction-heading">Sale History</h1>
        </v-col> -->
      </v-row>
      <v-row class="d-flex ds-card-stats">
        <v-col>
          <table :class="`customers${share.share_category}`">
            <thead>
              <tr>
                <th colspan="4">
                  <h1>Purchase History</h1>
                </th>
              </tr>
            <tr>
              <th>DATE OF PURCHASE </th>
              <th>TOTAL SHARES PURCHASED </th>
              <th>COST PER SHARE</th>
              <th>TOTAL VALUE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="transaction in filteredTransactions.buy"
            v-bind:key="transaction.transactionRef"
            elevation="0">
              <td>{{
                  formatDateTime(transaction.dateTime.seconds)
                }}</td>
              <td>{{
                  Math.abs(transaction.quantity)
                }}</td>
              <td>{{
                  transaction.transactionPricePerShare?transaction.transactionPricePerShare.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  }):0
                }}</td>
              <td>{{
                    transaction.transactionValue?transaction.transactionValue.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }).replace("-",""):0
                  }} <v-btn light icon v-if="transaction.isWantDelete" @click="deleteTransaction(transaction)">
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn></td>
            </tr>
          </tbody>
          </table>
          <!-- <v-card
            v-for="transaction in filteredTransactions.buy"
            v-bind:key="transaction.transactionRef"
            elevation="0"
          >
            <v-btn light icon v-if="transaction.isWantDelete" @click="deleteTransaction(transaction)">
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
            <v-row>
              <v-col>
                <v-card-text class="ds-card-subtitle"
                  >date of purchase
                </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">{{
                  formatDateTime(transaction.dateTime.seconds)
                }}</v-card-subtitle>
              </v-col>
              <v-col>
                <v-card-text class="ds-card-subtitle"
                  >total shares purchased
                </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">{{
                  transaction.quantity
                }}</v-card-subtitle>
              </v-col>
              <v-col>
                <v-card-text class="ds-card-subtitle"
                  >cost per share
                </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">{{
                  transaction.transactionPricePerShare.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  })
                }}</v-card-subtitle>
              </v-col>
              <v-col>
                <v-card-text class="ds-card-subtitle">total value </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">
                  {{
                    transaction.transactionValue.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    })
                  }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card> -->
        </v-col>
        <v-col>
          <table :class="`customers${share.share_category}`">
            <thead>
              <tr>
                <th colspan="4">
                  <h1>Sale History</h1>
                </th>
              </tr>
            <tr>
              <th>DATE OF SOLD </th>
              <th>TOTAL SHARES SOLD </th>
              <th>COST PER SHARE</th>
              <th>TOTAL VALUE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="transaction in filteredTransactions.sell"
            v-bind:key="transaction.transactionRef"
            elevation="0">
              <td>{{
                  formatDateTime(transaction.dateTime.seconds)
                }}</td>
              <td>{{
                  Math.abs(transaction.quantity)
                }}</td>
              <td>{{
                  transaction.transactionPricePerShare?transaction.transactionPricePerShare.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  }):0
                }}</td>
              <td>{{
                    transaction.transactionValue?transaction.transactionValue.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    }).replace("-",""):0
                  }}
                  
            <v-btn light icon v-if="transaction.isWantDelete" @click="deleteTransaction(transaction)">
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
              </td>
            </tr>
          </tbody>
          </table>
          <!-- <v-card
            v-for="transaction in filteredTransactions.sell"
            v-bind:key="transaction.transactionRef"
            elevation="0"
          >
             <v-btn light icon v-if="transaction.isWantDelete" @click="deleteTransaction(transaction)">
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
            <v-row>
              <v-col>
                <v-card-text class="ds-card-subtitle"
                  >date of sale
                </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">{{
                  formatDateTime(transaction.dateTime.seconds)
                }}</v-card-subtitle>
              </v-col>
              <v-col>
                <v-card-text class="ds-card-subtitle"
                  >total shares sold
                </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">{{
                  transaction.quantity
                }}</v-card-subtitle>
              </v-col>
              <v-col>
                <v-card-text class="ds-card-subtitle"
                  >cost per share
                </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">{{
                  transaction.transactionPricePerShare
                }}</v-card-subtitle>
              </v-col>
              <v-col>
                <v-card-text class="ds-card-subtitle">total value </v-card-text>
                <v-divider></v-divider>
                <v-card-subtitle class="ds-card-value">
                  {{
                    Math.abs(transaction.transactionValue).toLocaleString(
                      'en-IN',
                      {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                      }
                    )
                  }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card> -->
        </v-col>
      </v-row>
			</div>

      </div>
		</div>  

  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import router from '../router'
// import _ from "lodash";
// import Vue from "vue";

// const lineSmooth = Vue.chartist.Interpolation.cardinal({
//   tension: 0
// });
export default {
  name: 'ShareCard',
  // components: { MaterialChartCard },
  props: {
    share: Object,
    shouldRender: Boolean
  },
  data() {
    return {
      // show: false
      // shares: {
      //   search = "",
      //   [
      //   {
      //     id: "userShares.shareId",
      //     title: "share.company_name",
      //     BSE: "-2.5 (-0.26%)",
      //     volume: "49714",
      //     closing: "238",
      //     purchased: "440",
      //     averageCost: "6.89",
      //     compositeValue: "3031",
      //     marketValue: "104720",
      //     gainLoss: "101689",
      //     roi: "3355.25"
      //   }
      // ]
      // }
      menuItems: [
        {
          title: 'Add New Transaction',
          onClick: (id) => {
            this.rowSelected(id)
            this.updateActiveDialog('addTransaction')
          }
        },
        {
          title: 'View Transaction History',
          onClick: (id) => {  
            //let classAdd = document.getElementsByClassName('accordion__header_home');
            //document.getElementById(id?.final_numerical_value_test).classList.add('is_active');
            // classAdd.addClass('is_active');
            this.rowSelected(id)
            router.push({ name: 'Transactions' })
          }
        },
        {
          title: 'Trade Advice',
          onClick: () => {
            router.push({ name: 'TradeAdvice' })
          }
        }
      ]
    }
  },
  computed: {
    ...mapState(['user', 'userShares', 'userProfile']),
    filteredTransactions: function () {
      console.log("+++++++++++++++====>>", this.share);
      let filteredTransactionsObject = {
        buy: [],
        sell: [],
      }
      this.share?.transactions?.forEach((element) => {
        if (element.transactionType == 'Buy') {
          filteredTransactionsObject.buy.push(element)
        } else {
          filteredTransactionsObject.sell.push(element)
        }
      })
      return filteredTransactionsObject
    }
  },
  methods: {
    formatDateTime(seconds) {
      const date = new Date(seconds * 1000)
      console.log("DDDDDDDDDDDDx===========>", date);
      let month =
        String(date.getMonth()).length < 2 && String(date.getMonth()) != '9'
          ? '0' + (date.getMonth() + 1)
          : (date.getMonth()+1)
      let day =
        String(date.getDate()).length < 2 && String(date.getDate()) != '9'
          ? '0' + date.getDate()
          : date.getDate()
      return `${date.getFullYear()}-${month}-${day}`
    },
    rowSelected(shareId) {
      console.log(
        '🚀 ~ file: Home.vue ~ line 95 ~ rowSelected ~ event',
        shareId
      )
      // on click of share row, load trasactions based on share id
      // shareId is received through the event.
      this.selectedShareRow(shareId)
      // this.getTransactions(shareId);
    },
    ...mapActions(['deleteShare','updateActiveDialog', 'selectedShareRow', 'getTransactions']),
    deleteShares() {
      this.deleteShare(this.share);
    },
    deleteTransaction(transaction) {
      console.log(transaction);
      let updatedTrans=[];
      this.share.transactions.forEach((element) => {
        updatedTrans.push(element);
      })
      this.$store.dispatch('deleteTransaction', {"transaction":transaction, "updatedTrans":updatedTrans});
      
      this.$router.push("/");
    },
    ///...mapState(['selectedShare'])
  },
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "/js/script2.js"
    );
    document.head.appendChild(recaptchaScript);
  },
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';

.v-btn--icon.v-size--default {
  height: auto !important;
  width: auto !important;
}
.ds-card-title {
  @include heading_3_bold;
}
.ds-card-overtitle {
  @include secondary_paragraph_3_regular;
  color: $colors-brand-ui_6;
  line-height: 1.2rem !important;
}
.ds-card-subtitle {
  @include secondary_small-cap_2_medium;
  padding: 0 0 0.625rem 0;
  line-height: 1rem !important;
}
.ds-share-card {
  @include ds-elevation_1;
  margin-bottom: 1.25rem;
}
.card-top {
  margin-left: 0px;
}
.A {
  border-left: solid 0.25rem $color-dark-a !important;
  .card-top {
    background-color: $color-light-a;
  }
  .ds-card-roi {
    background-color: $color-light-a2;
    .ds-roi_card-value {
      color: $color-dark-a;
      @include num_display_large_bold;
      justify-content: center;
    }
    .ds-card-title_1 {
      // border-bottom: solid 0.0625rem $colors-brand-secondary-pink;
      text-align: center;
    }
  }
  .v-divider {
    border-bottom: solid 0.0625rem $color-dark-a;
    max-width: 1.75rem;
    margin-bottom: 0.625rem;
  }
  .ds-transaction-heading {
    @include secondary_paragraph_2_regular;
    color:$color-dark-a;
  }
}
.B {
  border-left: solid 0.25rem $color-dark-b !important;
  .card-top {
    background-color: $color-light-b;
  }
  .ds-card-roi {
    background-color: $color-light-b2;
    .ds-roi_card-value {
      color: $color-dark-b;
      @include num_display_large_bold;
      justify-content: center;
    }
    .ds-card-title_1 {
      // border-bottom: solid 0.0625rem $colors-brand-secondary-green;
      text-align: center;
    }
  }
  .v-divider {
    border-bottom: solid 0.0625rem $color-dark-b;
    max-width: 1.75rem;
    margin-bottom: 0.625rem;
  }
  .ds-transaction-heading {
    @include secondary_paragraph_2_regular;
    color: $color-dark-b;
  }
}
.C {
  border-left: solid 0.25rem $color-dark-c !important;
  .card-top {
    background-color: $color-light-c;
  }
  .ds-card-roi {
    background-color: $color-light-c2;
    .ds-roi_card-value {
      color: $color-dark-c;
      @include num_display_large_bold;
      justify-content: center;
    }
    .ds-card-title_1 {
      // border-bottom: solid 0.0625rem $colors-brand-secondary-yellow;
      text-align: center;
    }
  }
  .v-divider {
    border-bottom: solid 0.0625rem $color-dark-c;
    max-width: 1.75rem;
    margin-bottom: 0.625rem;
  }
  .ds-transaction-heading {
    @include secondary_paragraph_2_regular;
    color: $color-dark-c;
  }
}
.D {
  border-left: solid 0.25rem $color-dark-d !important;
  .card-top {
    background-color: $color-light-d;
  }
  .ds-card-roi {
    background-color: $color-light-d2;
    .ds-roi_card-value {
      color: $color-dark-d;
      @include num_display_large_bold;
      justify-content: center;
    }
    .ds-card-title_1 {
      // border-bottom: solid 0.0625rem $colors-brand-secondary-blue;
      text-align: center;
    }
  }
  .v-divider {
    border-bottom: solid 0.0625rem $color-dark-d;
    max-width: 1.75rem;
    margin-bottom: 0.625rem;
  }
  .ds-transaction-heading {
    @include secondary_paragraph_2_regular;
    color: $color-dark-d;
  }
}
.E {
  border-left: solid 0.25rem $color-dark-e !important;
  .card-top {
    background-color: $color-light-e;
  }
  .ds-card-roi {
    background-color: $color-light-e2;
    .ds-roi_card-value {
      color: $color-dark-e;
      @include num_display_large_bold;
      justify-content: center;
    }
    .ds-card-title_1 {
      // border-bottom: solid 0.0625rem $colors-brand-secondary-orange;
      text-align: center;
    }
  }
  .v-divider {
    border-bottom: solid 0.0625rem $color-dark-e;
    max-width: 1.75rem;
    margin-bottom: 0.625rem;
  }
  .ds-transaction-heading {
    @include secondary_paragraph_2_regular;
    color: $color-dark-e;
  }
}
.ds-card-value {
  @include secondary_paragraph_1_bold;
  padding: 0;
}
.ds-card-stats {
  .col {
    padding-left: 2.5rem;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    padding-right: 2.5rem;
  }
}
.ds-card-title_1 {
  @include secondary_heading_1_medium;
  color: $colors-brand-neutral;
}
.col-xs-12 {
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.ds-roi_card-value {
  margin-bottom: 2.8rem;
}
.ds-card-roi::v-deep .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 47% !important;
}

// Start A category transaction table css
.customersA {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customersA td, .customersA th {
  border: 1px solid #abe0c0;
  padding: 8px;
  font-size:10px;
}

.customersA tr:nth-child(even){background-color: #abe0c0;}

//#customers tr:hover {background-color: #ddd;}

.customersA th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #ADF2B3;
  // color: white;
}

// End A category transaction table css


// Start B category transaction table css
.customersB {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customersB td, .customersB th {
  border: 1px solid $color-light-b;
  padding: 8px;
  font-size:10px;
}

.customersB tr:nth-child(even){background-color: $color-light-b2;}

//#customers tr:hover {background-color: #ddd;}

.customersB th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: $color-light-b;
  // color: white;
}

// End B category transaction table css

// Start C category transaction table css
.customersC {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customersC td, .customersC th {
  border: 1px solid $color-light-c2;
  padding: 8px;
  font-size:10px;
}

.customersC tr:nth-child(even){background-color: $color-light-c2;}

//#customers tr:hover {background-color: #ddd;}

.customersC th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: $color-light-c;
  // color: white;
}

// End C category transaction table css

// Start D category transaction table css
.customersD {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customersD td, .customersD th {
  border: 1px solid $color-light-d2;
  padding: 8px;
  font-size:10px;
}

.customersD tr:nth-child(even){background-color: $color-light-d2;}

//#customers tr:hover {background-color: #ddd;}

.customersD th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: $color-light-d;
  // color: white;
}

// End D category transaction table css

// Start E category transaction table css
.customersE {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customersE td, .customersE th {
  border: 1px solid $color-light-e2;
  padding: 8px;
  font-size:10px;
}

.customersE tr:nth-child(even){background-color: $color-light-e2;}

//#customers tr:hover {background-color: #ddd;}

.customersE th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: $color-light-e;
  // color: white;
}

// End E category transaction table css

.ds-card-stats .col[data-v-620a5fe2] {
    padding-left: 5px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 5px !important;
}
</style>
