<template>
    <v-app>
    <!-- <trade-advice /> -->
    <div class="main-cover">
	 <div class="sidebar-wrap">

		<div class="side-details">
			<router-link to="/" exact class="logo" style="text-decoration:none;">
        <div class="logo"> Perquity </div>
        </router-link>
			<ul>
				<li v-for="navItem in navItems"
        :key="navItem.title" @click="navItem.onClick"> 
					<span> <img :src="navItem.icon"> </span>
					<span> {{ navItem.title }}</span>
				</li>
			</ul>
		</div>
	</div>
	<div class="main-content">
		<div class="top-header-section">
            <div class="th-left">
				
			</div>
       
			<div class="th-right"> 
				<div class="profile-menu">
					<div class="dropdown-container">
						<div class="dropdown-toggle click-dropdown">
						 <img src="images/profile.png">
						 
             <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <img src="images/arrow.svg" style="width:15px">
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(menuItem, i) in menuItems"
              :key="i"
              @click="menuItem.onClick"
            >
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
						</div>
						<!-- <div class="dropdown-menu">
							<ul>
								<li><a href="#">DropDown Menu Item 1</a></li>
								<li><a href="#">DropDown Menu Item 2</a></li>
								<li><a href="#">DropDown Menu Item 3</a></li>
								<li><a href="#">DropDown Menu Item 4</a></li>
							</ul>
						</div> -->
					</div>
				</div>
			</div>
		</div>


         <div class="th-left">
                  <div class="thead"> 
            Portfolio Overview for 
            {{userProfileSingle.name}} 
          </div>
          <div class="tsubhead"> (Value in INR)</div>
                  
          </div>
      <div class="graphical-reper-wrap" >
        <div class="grw-1 cr-wrap">
				<div class="chart-detail-cover">
					<div class="cdc-left">
						<!-- <div class="right-small-head"> portfolio category </div> -->
						<div class="chart-detail-grid-main">
							<div class="chart-grid-five">
								<ul>
                  <template>
                  </template>
								</ul>
							</div>
							<div class="chart-grid-three">
								<div class="table-row1 chart-table">
									<h1> Category </h1>
									<ul>
										<li class="main-text-color" :class="{'bg-a': labels == 'A', 'bg-b':labels == 'B', 'bg-c':labels == 'C', 'bg-d':labels == 'D', 'bg-e':labels == 'E'}" v-for="labels in chartOptions.labels" :key="labels" > 
                      <span v-if="labels=='A'"> Perfect (A)</span> 
                      <span v-if="labels=='B'"> Pergrowth (B)</span> 
                      <span v-if="labels=='C'"> Perchance (C)</span> 
                      <span v-if="labels=='D'"> Perneed (D)</span> 
                      <span v-if="labels=='E'"> Perishable (E)</span> 
                    </li>
									</ul>
								</div>
								<div class="table-row2 chart-table">
									<h1>AVERAGE VALUE</h1>
                  
									<ul>
										<li class="main-text-color" :class="{'bg-a': labels == 'A', 'bg-b':labels == 'B', 'bg-c':labels == 'C', 'bg-d':labels == 'D', 'bg-e':labels == 'E'}" v-for="(labels, i) in chartOptions.labels" :key="labels"> {{compositeValue[i]?compositeValue[i].avg.toFixed(2):0}} </li>
										<!-- <li class="text-color-lr"> 32,870 </li>
										<li class="text-color-y"> 22,635 </li>
										<li class="text-color-b"> 9,990 </li>
										<li class="text-color-g"> 9,990 </li> -->
									</ul>								
								</div>
								<div class="table-row3 chart-table">
									<h1> spread in %</h1>
									<ul>
										<li class="main-text-color" :class="{'bg-a': labels == 'A', 'bg-b':labels == 'B', 'bg-c':labels == 'C', 'bg-d':labels == 'D', 'bg-e':labels == 'E'}" v-for="(labels, i) in chartOptions.labels" :key="labels"> {{chartOptions.series[i].toFixed(1)}}%</li>
										<!-- <li class="text-color-lr"> 32% </li>
										<li class="text-color-y"> 27% </li>
										<li class="text-color-b"> 06% </li>
										<li class="text-color-g"> 06% </li> -->
									</ul>								
								</div>
							</div>
						</div>
					</div>		
          <div class="cdc-right">
						<!-- <div class="cdc-head"> current PORTFOLIO </div> -->
						<div class="cdc-pie-chart" style="margin-top:20px;">
              <apexchart
              :width="chartWidth"
              type="donut"
              :options="chartOptions"
              :series="chartOptions.series"
            ></apexchart> </div>
					</div>		
				</div>
			</div>
              <div class="flex-col3box">
                  <div class="clt-wrap grw-1"  v-for="card in cards"
            v-bind:key="card.title">
                      <div class="clt-head-price">
                          <div class="clt-head">
                              <div class="clt-light-head"> {{ card.overtitle }} </div>
                              <div class="clt-bold-head"> {{ card.title }} </div>
                          </div>
                          <div class="clt-price">
                              {{ card.value }}
                          </div>
                      </div>
                  </div>
              </div>
              <div class="grw-1 roi-wrap">
                  <div class="roi-head-num-wrap">
                      <div class="roi-head">
                          PORTFOLIO ROI %
                      </div>
                      <div class="roi-num">
                          {{
                  tradeAdvice.avg_profit_loss_percentage.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
              }),
                }}
                      </div>
                  </div>
              </div>
          </div>
          
          <div class="list-high-roi">
              <p> List of your shares on the basis of highest ROI ({{filteredShares.length}})</p>
          </div>
          
  
      <v-row>
          <v-col class="d-flex" cols="12" md="2">
            <v-select :items="select" label="Filter" class="ds-input" v-on:change="filterByCategory()" v-model="selectValue"></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" md="2">
            <v-text-field
              label="Enter text here"
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              class="ds-input"
              v-model="searchInput"
            ></v-text-field>
          </v-col>
        </v-row>
        <template>
          <share-card
          v-for="share in filteredShares"
          v-bind:key="share.shareId"
          :share="share"
        />
        </template>
		
	</div>
  </div>   

  
    </v-app>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex'
  import { auth } from '@/db'
  // import AddTransaction from '../components/AddTransaction.vue'
  // import AddShare from '../components/AddShare.vue'
  import ShareCard from '../components/ShareCard'
  // import DashboardCard from '../components/DashboardCard'
  // import AddProfitRatio from '../components/AddProfitRatio'
  // import TradeAdvice from '../components/TradeAdvice.vue'
  import VueApexCharts from 'vue-apexcharts'
  import Vue from 'vue'
  import router from '../router'
  // import TradeAdvice from '../components/TradeAdvice.vue'
  // import func from 'vue-editor-bridge'
  // import _ from "lodash";
  Vue.use(VueApexCharts)
  
  Vue.component('apexchart', VueApexCharts)
  // import { get } from "vuex-pathify";
  // import Vue from "vue";
  export default {
    components: {
      // AddTransaction,
      // AddShare,
      ShareCard,
      // DashboardCard,
      // AddProfitRatio
      // TradeAdvice
    },
    name: 'Userportfolio',
    data() {
      return {
        searchInput: '',
        select: ['Please Select','Perfect (A)', 'Pergrowth (B)', 'Perchance (C)', 'Perneed (D)', 'Perishable (E)'],
        selectValue: "",
        // selectedShareRowTransactions: [],
        selectedShareObj: {}, // share data from click event
  
        currentUser:auth.currentUser,
        menuItems: [{ title: 'Logout', onClick: this.logOut }],
        navItems: [
        {
          title: 'Portfolio',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            router.push({ name: 'Userportfolio' })
            //this.updateActiveDialog('addShare')
          }
          // img: './assets/logo.png'
        },
        {
          title: 'Buy / Sell Advice',
          icon: 'images/sb4.png',
          onClick: () => {
            router.push({ name: 'TradeAdvice' })
          }
          // img: './assets/Plus.svg'
        },
        {
          title: 'Exit',
          icon: 'images/sb4.png',
          onClick: () => {
            router.push({ name: 'Authguard' })
          }
          // img: './assets/Plus.svg'
        },
        {
          title: 'Execute',
          icon: 'images/sb4.png',
          onClick: () => {
            console.log("Need to set");
            router.push({ name: 'Usertables' })
          }
          // img: './assets/Plus.svg'
        },
      ],
      }
    },
    methods: {
      logOut() {
        localStorage.clear();
        this.$store.dispatch('logOut')
       },
      // handles click:row events for User Shares data table
      // Automatically receives the event as a param
      rowSelected(event) {
        // console.log(
        //   "🚀 ~ file: Home.vue ~ line 95 ~ rowSelected ~ event",
        //   event.item
        // );
        this.selectedShareObj = event.item
        // on click of share row, load trasactions based on share id
        // shareId is received through the event.
        this.selectedShareRow(event.item.ticker)
        // this.getTransactions(event.item.shareId);
        // event.item.transactions
        //   ? (this.selectedShareRowTransactions = event.item.transactions)
        //   : [];
      },
      // handles submit for add transaction form
      // validate() {
      //   this.$refs.form.validate
      //     ? this.addTransaction()
      //     : console.log("validation error");
      // },
      // // send the add transaction form data to store
      // addTransaction() {
      //   let transactionData = this.form.model;
      //   this.$store.dispatch("addTransaction", this.form.model);
      //   console.log(
      //     "🚀 ~ file: Home.vue ~ line 136 ~ addTransaction ~ transactionData",
      //     transactionData
      //   );
      // },
      ...mapActions([
        'getTransactions',
        'selectedShareRow',
        'toggleAddTransactionForm',
        'toggleAddShareForm'
      ]),

      filterByCategory() {
        if (this.selectValue == "Perfect (A)") {
          this.selectValue = "A";
        } else if (this.selectValue == "Pergrowth (B)") {
          this.selectValue = "B";
        } else if (this.selectValue == "Perchance (C)") {
          this.selectValue = "C";
        } else if (this.selectValue == "Perneed (D)") {
          this.selectValue = "D";
        } else if (this.selectValue == "Perishable (E)") {
          this.selectValue = "E";
        } else {
          this.selectValue = "";
        }
        // console.log("============>>>>>>>>",this.selectValue);
      },
  
      findOcc(arr, key){
        //let arr2 = [];
        let sum=0;
        let i=0;
        if(arr) {
          arr.forEach((x)=>{
           if (x.share_category == key) {
            sum = sum + x.composit_purchase_value;
            i++;
           }
          })
        } 
        console.log("<<<<<<<======>>>>>>>", {key:key, composite:sum, avg:sum/i});
        return {key:key, composite:sum, avg:sum/i}
      },
    },
    computed: {
      ...mapState([
        'userShares',
        'shareTransactions',
        'selectedShare',
        'transactionFormIsVisible',
        'shareFormIsVisible',
        'tradeAdvice',
        'activeDialog',
        'tradeAdviceGenerated',
        'userProfileSingle',
        'userProfile',
        'allUsers',
      ]),
      cards: function () {
        return [
          {
            overtitle: 'COMPOSITE',
            title: 'PURCHASE VALUE',
            value:
              this.tradeAdvice.all_shares_composit_purchase_value.toLocaleString(
                'en-IN',
                {
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'INR'
                }
              ),
            // Math.round(
            //   this.tradeAdvice.all_shares_composit_purchase_value * 100
            // ) / 100,
            icon: 'mdi-info',
            text: 'VALUE IN INR',
            info: "Composite cost of capital represents a company's cost to finance its business as determined by its weighted average cost of capital (WACC)."
          },
          {
            overtitle: 'LAST',
            title: 'MARKET VALUE',
            value:
              // Math.round(this.tradeAdvice.all_shares_market_value * 100) / 100,
              this.tradeAdvice.all_shares_market_value.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }),
            icon: 'mdi-info',
            text: 'VALUE IN INR',
            info: 'Info'
          },
          {
            overtitle: 'TOTAL',
            title: 'GAIN / LOSS',
            value:
              // Math.round(this.tradeAdvice.total_profit_loss * 100) / 100,
              this.tradeAdvice.total_profit_loss.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }),
            icon: 'mdi-info',
            text: 'VALUE IN INR',
            info: 'Info'
          }
        ]
      },
      chartOptions: function () {
        let graphData = {
          series: [],
          labels: [],
          colors: [],
          compositeValues:[],
          legend: {
            show: false
          },
          dataLabels: {
            enabled: true
          }
        }
       // '#FF70A0', '#EA6206', '#FCB008', '#6798FC', '#418B7F'
        for (const cat in this.tradeAdvice.category_values) {
          // console.log("cattt=======>",String(cat));
          // console.log("Percentage=======>",this.tradeAdvice.category_values[cat].percentage);
          if (this.tradeAdvice.category_values[cat].percentage) {
            if(cat != "undefined") {
              graphData.labels.push(String(cat));
            }
            if (String(cat)=='A' && !graphData.colors.includes('#03AC13')) {
              graphData.colors.push('#03AC13');
            }
            if (String(cat)=='B' && !graphData.colors.includes('#4169E2')) {
              graphData.colors.push('#4169E2');
            }
            if (String(cat)=='C' && !graphData.colors.includes('#F2BB05')) {
              graphData.colors.push('#F2BB05');
            }
            if (String(cat)=='D' && !graphData.colors.includes('#D74E09')) {
              graphData.colors.push('#D74E09');
            }
            if (String(cat)=='E' && !graphData.colors.includes('#6E0E0A')) {
              graphData.colors.push('#6E0E0A');
            }
            graphData.series.push(this.tradeAdvice.category_values[cat].percentage)
          }
        }
        console.log("GraphData=========>",graphData)
        return graphData
      },
      computedTradeAdvice: function () {
        if (this.tradeAdvice != '') {
          return this.tradeAdvice
        } else {
          return []
        }
      },
      filteredShares: function () {
        console.log('Trade Advice Shares: ', this.tradeAdvice?.shares)
        console.log('User Profileeeeeeeee: ', this.userProfile)
        console.log('All Users===========>: ', this.allUsers)
        if (this.tradeAdvice.shares) {
          let shares = this.selectValue ? this.tradeAdvice.shares.filter((share) => {
              return (
                share.share_category == this.selectValue
              )
              }):this.tradeAdvice.shares.filter((share) => {
              return (
                share.company_name
                  .toUpperCase()
                  .includes(this.searchInput.toUpperCase()) ||
                share.ticker.toUpperCase().includes(this.searchInput.toUpperCase())
              )
          })
          console.log(shares)
          shares.sort((a, b) => {
            if (a.profit_loss_percentage < b.profit_loss_percentage) {
              return 1
            }
            if (a.profit_loss_percentage > b.profit_loss_percentage) {
              return -1
            } else {
              return 0
            }
          })
          console.log('Filtered Shares: ', shares);
          // shares = shares.map((itemShares)=> {
          //   if (itemShares.cost_per_share < 0) {
          //     itemShares.cost_per_share = 0;
          //   }
          //   return itemShares;
          // });
          //console.log("This Shares Is Now ==================>",this.findOcc(shares, 'A'));
          return shares
        } else {
          return null
        }
      },
      compositeValue:function() {
        let compositeValues = [];
        if (!isNaN(this.findOcc(this.filteredShares, 'A').avg) && parseInt(this.findOcc(this.filteredShares, 'A').avg) != 0) {
          compositeValues.push(this.findOcc(this.filteredShares, 'A'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'B').avg) && parseInt(this.findOcc(this.filteredShares, 'B').avg) != 0) {
          compositeValues.push(this.findOcc(this.filteredShares, 'B'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'C').avg) && parseInt(this.findOcc(this.filteredShares, 'C').avg) != 0) {
          compositeValues.push(this.findOcc(this.filteredShares, 'C'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'D').avg) && parseInt(this.findOcc(this.filteredShares, 'D').avg) != 0) {
          compositeValues.push(this.findOcc(this.filteredShares, 'D'));
        }
        if (!isNaN(this.findOcc(this.filteredShares, 'E').avg) && parseInt(this.findOcc(this.filteredShares, 'E').avg) != 0) {
          compositeValues.push(this.findOcc(this.filteredShares, 'E'));
        }
        // if (this.findOcc(this.filteredShares, 'A').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'A'));
        // }
        // if (this.findOcc(this.filteredShares, 'B').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'B'));
        // }
        // if (this.findOcc(this.filteredShares, 'C').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'C'));
        // }
        // if (this.findOcc(this.filteredShares, 'D').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'D'));
        // }
        // if (this.findOcc(this.filteredShares, 'E').composite !=0 ) {
        //   compositeValues.push(this.findOcc(this.filteredShares, 'E'));
        // }
        // compositeValues.push(this.findOcc(this.filteredShares, 'A'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'B'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'C'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'D'));
        // compositeValues.push(this.findOcc(this.filteredShares, 'E'));
        console.log("ADDDDDDEEEEEEEEEEEE============>", compositeValues);
        return compositeValues;
      },
  
      chartWidth() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 380
          case 'sm':
            return 380
          case 'md':
            return 240
          case 'lg':
            return 240
          case 'xl':
            return 380
          default:
            return 380
        }
      }
    },
    mounted() {
      console.log("This Shares Is Now ==================>",this.findOcc(this.shares, 'A'));
    }
  }
  </script>
  <style lang="scss" scoped>
  @import '~@/scss/variables.scss';
  .ds-card-dashboard {
    @include ds-elevation_2;
    border-radius: 8px !important;
    padding: 1rem;
    &.ds-roi {
      background-color: $colors-brand-primary;
      color: $colors-brand-neutral_light-7;
      .ds-card-title {
        @include secondary_paragraph_1_regular;
        color: $colors-brand-neutral_light-7;
        text-align: center;
        margin-top: 25%;
      }
      .ds-card-value {
        @include num_display_large_bold;
        justify-content: center !important;
        color: $colors-brand-neutral_light-7;
        padding: 0.5rem !important;
      }
    }
  }
  .ds-header {
    margin-top: 4.68rem;
  }
  .heading-2 {
    @include heading_2_regular;
    line-height: 2.5rem !important;
  }
  .ds-input::v-deep .v-input__slot::before {
    border-color: $colors-brand-primary !important;
  }
  .ds-input::v-deep label {
    color: $colors-brand-primary !important;
    @include body_4;
  }
  .ds-input::v-deep .v-icon {
    color: $colors-brand-primary !important;
  }
  .ds-card-text {
    @include small-caps_3_regular;
  }
  .ds-card-subtitle {
    @include small-caps_2_medium;
  }
  .ds-card-value {
    @include num_display_small_bold;
    justify-content: center;
    color: $colors-brand-primary;
    padding: 1rem 0rem !important;
    font-size: 1.0625rem;
    line-height: 1.5rem;
  }
  .ds-card-icon {
    color: $colors-brand-primary;
  }
  .ds-card-dashboard::v-deep .v-divider--inset {
    margin-left: 40% !important;
  }
  .v-divider {
    border-bottom: solid 0.0625rem $colors-brand-primary;
    max-width: 1.75rem;
  }
  .apexcharts-legend .apexcharts-align-center .position-right {
    display: none !important;
  }
  .side-details ul li {
    cursor: pointer;
  }
.bg-a {
  background: #03AC13;
}
.bg-b {
  background: #4169E2;
}
.bg-c {
  background: #F2BB05;
}
.bg-d {
  background: #D74E09;
}
.bg-e {
  background: #6E0E0A;
}
  </style>
  