import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import faqs from '@/components/icons/faqs.vue'
import profitratio from '@/components/icons/AddProfitRatio.vue'
import tradeadvice from '@/components/icons/TradeAdvice.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  icons: {
    values: {
      'plus-icon': {
        component: PlusIcon
      },
      'profit-ratio': {
        component: profitratio
      },
      'trade-advice': {
        component: tradeadvice
      },
      faqs: {
        component: faqs,
        props: {
          name: 'faqs'
        }
      }
    }
  }
})
