var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[(this.loading)?_c('div',[_vm._v(_vm._s(this.loadingMessage))]):_vm._e(),_c('trade-advice'),(!_vm.auth.currentUser)?_c('v-app-bar',{staticClass:"nav-bar",attrs:{"app":"","clipped-left":"","flat":""}},[(!_vm.auth.currentUser)?_c('router-link',{attrs:{"to":"/login","exact":""}},[_c('v-toolbar-title',{staticClass:"ds-logo"},[_vm._v("Perquity ")])],1):_c('router-link',{attrs:{"to":"/","exact":""}},[_c('v-toolbar-title',{staticClass:"ds-logo"},[_vm._v("Perquity ")])],1),(this.loading)?_c('div',[_vm._v(_vm._s(this.loadingMessage))]):_vm._e(),_c('v-spacer'),(this.userAuth.uid == null)?_c('div',_vm._l((_vm.links),function(link){return _c('v-btn',{key:link.title,attrs:{"text":"","rounded":"","to":link.route}},[_vm._v(_vm._s(link.title)+" ")])}),1):_vm._e(),(this.userAuth.uid)?[_c('v-spacer'),_c('v-avatar',{attrs:{"color":"primary","size":"48"}},[_c('v-img',{attrs:{"src":require("./assets/logo.png")}})],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2702128475)},[_c('v-list',_vm._l((_vm.menuItems),function(menuItem,i){return _c('v-list-item',{key:i,on:{"click":menuItem.onClick}},[_c('v-list-item-title',[_vm._v(_vm._s(menuItem.title))])],1)}),1)],1),_c('trade-advice')]:_vm._e()],2):_vm._e(),(this.userAuth.uid == null)?_c('v-main',{attrs:{"app":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1):_vm._e(),(_vm.userProfile && _vm.userProfile.role != 'Admin')?[(_vm.userAuth.uid)?_c('div',{staticClass:"main-cover"},[(_vm.userAuth.uid)?_c('div',{staticClass:"sidebar-wrap"},[_c('div',{staticClass:"side-details"},[_c('router-link',{staticClass:"logo",staticStyle:{"text-decoration":"none"},attrs:{"to":"/","exact":""}},[_c('div',{staticClass:"logo"},[_vm._v(" Perquity ")])]),_c('ul',_vm._l((_vm.navItems),function(navItem){return _c('li',{key:navItem.title,on:{"click":navItem.onClick}},[_c('span',[_c('img',{attrs:{"src":navItem.icon}})]),_c('span',[_vm._v(" "+_vm._s(navItem.title))])])}),0)],1)]):_vm._e(),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"top-header-section"},[_c('div',{staticClass:"th-left"}),(_vm.userAuth.uid)?_c('div',{staticClass:"th-right"},[_c('div',{staticClass:"profile-menu"},[_c('div',{staticClass:"dropdown-container"},[_c('div',{staticClass:"dropdown-toggle click-dropdown"},[_c('img',{attrs:{"src":"images/profile.png"}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"width":"15px"},attrs:{"src":"images/arrow.svg"}})])]}}],null,false,1619879425)},[_c('v-list',_vm._l((_vm.menuItems),function(menuItem,i){return _c('v-list-item',{key:i,on:{"click":menuItem.onClick}},[_c('v-list-item-title',[_vm._v(_vm._s(menuItem.title))])],1)}),1)],1)],1),_c('div',{staticClass:"dropdown-menu"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("DropDown Menu Item 1")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("DropDown Menu Item 2")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("DropDown Menu Item 3")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("DropDown Menu Item 4")])])])])])])]):_vm._e()]),_c('router-view')],1)]):_vm._e()]:[_c('router-view')],_c('footer',[_c('div',{staticClass:"footer-wrap"},[_vm._v(" Powered by Two Digital ")])]),(_vm.activeDialog)?[(_vm.activeDialog == 'ShowWelcome')?_c('v-dialog',{attrs:{"width":"1000","persistent":""},model:{value:(_vm.activeDialog),callback:function ($$v) {_vm.activeDialog=$$v},expression:"activeDialog"}},[(_vm.activeDialog == 'ShowWelcome')?_c('WelcomeDialog'):_vm._e()],1):_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.activeDialog),callback:function ($$v) {_vm.activeDialog=$$v},expression:"activeDialog"}},[(_vm.activeDialog == 'addTransaction')?_c('add-transaction'):_vm._e(),(_vm.activeDialog == 'addShare')?_c('add-share'):_vm._e(),(_vm.activeDialog == 'addProfitRatio')?_c('add-profit-ratio'):_vm._e(),(_vm.activeDialog == 'AddAnnouncement' || _vm.activeDialog == 'AddFuture')?_c('add-announcement'):_vm._e()],1)]:_vm._e(),_c('v-snackbar',{attrs:{"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }