<template>
    <v-card class="ds-popup">
      <v-row>
        <v-spacer></v-spacer>
  
        <v-col class="justify-center">
          <v-icon class="ds-icon"> mdi-plus </v-icon>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-btn icon large @click="updateActiveDialog('')" class="ds-btn-close">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title class="ds-card-title justify-center" v-if="activeDialog == 'AddAnnouncement'">
        Add Special Announcement
      </v-card-title>

      <v-card-title class="ds-card-title justify-center" v-else>
        Add Future
      </v-card-title>

  
      <v-card-text>
        <v-form ref="addShareForm" v-model="addShareForm.valid">
          <v-autocomplete
            @change="filledAnnouncement"
            v-if="shareCodeOptions != null"
            :items="shareCodeOptions"
            v-model="addShareForm.model"
            :rules="addShareForm.rules.shareCode"
            label="Select Share Code"
            class="ds-input"
          >
          </v-autocomplete>
          <p v-else>sorry you got it all!</p>
          <input type="number" v-model="addShareForm.announcement" placeholder="Add Special Announcement" class="ds-input-number" v-if="activeDialog == 'AddAnnouncement'"/>
          <input type="number" v-model="addShareForm.announcement" placeholder="Add Future" class="ds-input-number" v-else/>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="ds-button" outlined text @click="reset">Clear</v-btn>
        <v-btn
          v-if="activeDialog == 'AddAnnouncement'"
          class="ds-button"
          outlined
          text
          :disabled="!addShareForm.valid"
          @click="validate"
          >Add Announcement</v-btn
        >
        <v-btn
          v-else
          class="ds-button"
          outlined
          text
          :disabled="!addShareForm.valid"
          @click="validate"
          >Add Future</v-btn
        >
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'AddAnnouncement',
    data() {
      return {
        addShareForm: {
          valid: true,
          model: {},
          announcement:'',
          rules: {
            shareCode: [(v) => !!v || 'Share Code is required']
          }
        }
      }
    },
    methods: {
      //   validate the form and pass the addShareForm data if valid
      validate() {
        console.log(
          '🚀 ~ file: AddShare.vue ~ line 60 ~ validate ~ this.addShareForm.model',
          this.addShareForm.model
        )
        console.log(
          '🚀 ~ file: AddShare.vue ~ line 60 ~ validate ~ this.addShareForm.model',
          this.addShareForm.announcement
        )
        if(this.addShareForm.announcement == '') {
          alert('Special announcement field is not empty');
          return false;
        }
        this.addShareForm.model.announcement = this.addShareForm.announcement;
        this.$refs.addShareForm.validate
          ? this.updateShare({"shareModel":this.addShareForm.model, "users":this.allUsers, "activeDilog":this.activeDialog})
          : console.log('Add Share Form validation error')
        this.$refs.addShareForm.reset()
        this.updateActiveDialog('')
      },
      reset() {
        // reset form
        this.$refs.form.reset()
      },
      async filledAnnouncement() {
        let announcementData = await this.getSpecialAnnouncement({"shareModel":this.addShareForm.model, "users":this.allUsers});
        console.log("UUUUUTTTTTT=====>", announcementData);
        if (announcementData && this.activeDialog =='AddAnnouncement') {
          // console.log("Helooooooooooo=====>", announcementData.announcement);
          if(announcementData.announcement) {
            this.addShareForm.announcement = announcementData.announcement;
          } else {
            this.addShareForm.announcement = 1;
          }
        } else if(!announcementData && this.activeDialog =='AddAnnouncement') {
          this.addShareForm.announcement = 1;
        } else if(announcementData && this.activeDialog =='AddFuture'){
          if(announcementData.future) {
            this.addShareForm.announcement = announcementData.future;
          } else {
            this.addShareForm.announcement = 2;
          }
        } else if(!announcementData && this.activeDialog =='AddFuture') {
          this.addShareForm.announcement = 2;
        } else {
          this.addShareForm.announcement = '';
          this.addShareForm.future = '';
        }
      },
      ...mapActions(['updateShare', 'updateActiveDialog', 'getSpecialAnnouncement'])
    },
    computed: {
      shareCodeOptions() {
        if (this.userShares) {
          /**
           * get the shares existing with user
           */
          let userShares = []
          this.userShares.forEach((element) => {
            userShares.push(element.ticker)
          })
  
          /**
           * compare the share code values existing with user and master shares
           * returns the uncommon shares
           */
  
          let shareCodeOptions = this.appData.strippedSharesArray.filter(
            (val) => {
              return !userShares.includes(val.ticker)
            }
          )
  
          let shareCodeOptions1 = []
          shareCodeOptions.forEach((el) => {
            shareCodeOptions1.push({
              text: `${el.ticker} - ${el.company_name}`,
              value: el
            })
          })
  
          return shareCodeOptions1
        } else {
          return null
        }
      },
  
      ...mapState(['userShares', 'appData', 'allUsers','activeDialog'])
    },
    created() {}
  }
  </script>
  
  <style lang="scss" scoped>
  @import '~@/scss/variables.scss';
  .ds-popup {
    padding-top: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 3.5rem;
    padding-left: 0.75rem;
    border-radius: 0.5rem !important;
    .ds-btn-close {
      background-color: $colors-brand-primary;
      color: $colors-brand-neutral_light-7;
      margin: 0.75rem;
    }
    .ds-icon {
      color: $colors-brand-secondary-yellow;
      background-color: $colors-brand-secondary-yellow_light-6;
      border-radius: 6rem;
      width: 6rem;
      height: 6rem;
      font-size: 4rem;
      margin: 0.75rem;
      left: 2.5rem;
    }
  }
  .ds-card-title {
    @include heading_1_bold;
    line-height: 3rem !important;
    justify-content: center;
  }
  .ds-input::v-deep label {
    color: $colors-brand-primary !important;
    @include secondary_paragraph_3_regular;
  }
  .ds-input::v-deep .v-input__slot::before {
    border-color: $colors-brand-primary !important;
  }

  .ds-input-number {
    border:1px solid;
    width:100%;
    border-color: $colors-brand-primary !important;
    padding:10px;
    height:50px;
    border-radius: 10px;
  }
  .ds-button {
    border: 1px $colors-brand-primary solid;
    color: $colors-brand-primary !important;
    border-radius: 4px;
    padding: 20px !important;
  }
  </style>
  