<template>
  <div>
    <v-card class="ds-popup">
      <v-row>
        <v-spacer></v-spacer>
        <v-icon class="justify-center ds-icon"> mdi-percent </v-icon>
        <v-spacer></v-spacer>
        <v-btn icon large @click="updateActiveDialog('')" class="ds-btn-close">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-row>
      <v-card-title class="ds-card-title"> Set Profit Ratio </v-card-title>
      <v-card-subtitle class="ds-card-overtitle">
        Current profit ratio: {{ (userProfile.greedPercentage * 100).toFixed(2) }}%
      </v-card-subtitle>
      <v-form ref="form" v-model="form.valid">
        <v-text-field
          v-model="form.model.greed_percentage"
          :rules="form.rules"
          label="Input your risk appetite in percent, eg. 12.5"
          required
          class="ds-input"
        >
        </v-text-field>
      </v-form>
      <v-card-actions class="justify-center">
        <v-btn outlined @click="reset" class="ds-button"> Reset </v-btn>
        <v-btn
          outlined
          :disabled="!form.valid"
          @click="validate"
          class="ds-button"
        >
          Set Ratio
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AddProfitRatio',
  data() {
    return {
      form: {
        valid: false,
        model: {
          greed_percentage: ''
        },
        rules: [(v) => !!v || 'Risk appetite is required']
      }
    }
  },
  methods: {
    validate() {
      // console.log(this.$refs.form.validate())
      this.$refs.form.validate
        ? this.addGreedPercentage()
        : console.log('validation error')

      //   hide dialog window
      this.$refs.form.reset()
      this.updateActiveDialog('')
    },
    addGreedPercentage() {
      let data = this.form.model.greed_percentage / 100

      // console.log('greed', data.toFixed(2))
      // console.log('greed', data)
      this.$store.dispatch('updateGreedPercentage', data)
    },
    reset() {
      // reset form
      this.$refs.form.reset()
    },
    ...mapActions(['updateActiveDialog'])
  },
  computed: {
    ...mapState(['userProfile'])
  },
  mounted() {
    // this.form.model.greed_percentage = this.userProfile.greedPercentage;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.ds-popup {
  padding-top: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 3.5rem;
  padding-left: 0.75rem;
  border-radius: 0.5rem;
  .ds-btn-close {
    background-color: $colors-brand-primary;
    color: $colors-brand-neutral_light-7 !important;
    margin: 0.75rem;
  }
  .ds-icon {
    color: $colors-brand-secondary-yellow;
    background-color: $colors-brand-secondary-yellow_light-6;
    border-radius: 6rem;
    width: 6rem;
    height: 6rem;
    font-size: 3rem;
    margin: 0.75rem;
    left: 2.5rem;
  }
}
.ds-card-title {
  @include heading_1_bold;
  line-height: 3rem !important;
  justify-content: center;
}
.ds-card-overtitle {
  @include paragraph_2_light;
  text-align: center;
}
.ds-input::v-deep .v-input__slot::before {
  border-color: $colors-brand-primary !important;
}
.ds-input::v-deep label {
  color: $colors-brand-primary !important;
  @include secondary_paragraph_3_regular;
}
.ds-button {
  border: 1px $colors-brand-primary solid;
  color: $colors-brand-primary !important;
  border-radius: 4px;
  padding: 20px !important;
}
</style>
