import { auth } from '@/db'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Userportfolio from '../views/Userportfolio'
import Usertables from '../views/Usertables'
// import DashboardView from "../views/Dashboard.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-portfolio',
    name: 'Userportfolio',
    component: Userportfolio,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-tables',
    name: 'Usertables',
    component: Usertables,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/tradeAdvice',
    name: 'TradeAdvice',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "algorithm" */ '../views/TradeAdvice.vue')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "algorithm" */ '../views/Transactions.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "faqs" */ '../views/FAQs.vue')
  },
  // {
  //   path: '/fetchshares',
  //   name: 'FetchAPI',
  //   component: () =>
  //     import(/* webpackChunkName: "fetchshares" */ '../views/FetchAPI.vue')
  // },
  {
    path: '/checkAuth',
    name: 'Authguard',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Authguard.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Forgot.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  // console.log(
  //   '🚀 ~ file: index.js ~ line 44 ~ router.beforeEach ~ requiresAuth',
  //   requiresAuth
  // )
  // console.log(
  //   '🚀 ~ file: index.js ~ line 51 ~ router.beforeEach ~ auth.currentUser',
  //   auth.currentUser
  // )
  
  // if (localStorage.getItem("auth_token")) {
  //   next({name: 'Authguard'})
  // }
  //console.log("===========>",auth.currentUser);

  if (requiresAuth && !auth.currentUser) {
    next({ name: 'Authguard' })
  } else {
    next()
  }
})

export default router
